import React from "react";
import i18n from "@/i18n";
import BorderColorIcon from "@mui/icons-material/BorderColor";

interface EditButtonProps {
  onClick: () => void;
}

const EditButton = ({ onClick }: EditButtonProps) => {
  return (
    <button
      className="cursor-pointer text-blue-100"
      onClick={onClick}
      role={"btn_edit"}
    >
      <>
        <BorderColorIcon className="mr-2" fontSize="inherit" />
        {i18n.t("EDIT_DATA")}
      </>
    </button>
  );
};

export default EditButton;
