import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ReactComponent as OutsourcedIcon } from "@/assets/icons/outsourcedIcon.svg";
import { ReactComponent as FileCheckedIcon } from "@/assets/icons/fileCheckedIcon.svg";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import i18n from "../../../i18n";
import { logEvent } from "@/services/analyticsService";

interface LateralMenuItemsListProps {
  buttonPressed: "outsourced" | "requests";
}

const LateralMenuItemsList = ({ buttonPressed }: LateralMenuItemsListProps) => {
  return (
    <Box>
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>
        <Box className="justify-center text-center mt-8 mb-5">
          <span className="text-heading-bold-5 text-purple-800">
            {i18n.t("ADMINISTRATION")}
          </span>
        </Box>
        <ListItem
          disablePadding
          className={`hover:bg-purple-200 ${
            buttonPressed === "requests" ? "bg-purple-200" : "bg-white"
          }`}
        >
          <ListItemButton href={"/"}>
            <ListItemIcon>
              <FormatListBulletedIcon
                width={22}
                height={22}
                className={`${
                  buttonPressed === "requests"
                    ? "fill-purple-800"
                    : "fill-gray-700"
                } ml-6`}
              />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={i18n.t("REQUESTS")}
              className={`${
                buttonPressed === "requests"
                  ? "text-body-bold-1 text-purple-800"
                  : "text-body-regular-1 text-gray-700"
              }`}
            />
          </ListItemButton>
        </ListItem>
        <ListItem
          disablePadding
          className={`hover:bg-purple-200 ${
            buttonPressed === "outsourced" ? "bg-purple-200" : "bg-white"
          }`}
        >
          <ListItemButton href={"/outsourcedWorkers"}>
            <ListItemIcon>
              <OutsourcedIcon
                width={22}
                height={22}
                className={`${
                  buttonPressed === "outsourced"
                    ? "fill-purple-800"
                    : "fill-gray-700"
                } ml-6`}
              />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={i18n.t("OUTSOURCED_WORKERS")}
              className={` ${
                buttonPressed === "outsourced"
                  ? "text-body-bold-1 text-purple-800"
                  : "text-body-regular-1 text-gray-700"
              }`}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding className="hover:bg-purple-200 bg-white">
          <ListItemButton
            onClick={() => {
              window.open(process.env.REACT_APP_RSR_FORM_URL, "_blank");
              logEvent("BOTAO_FORMS_RSR_CLICADO");
            }}
          >
            <ListItemIcon>
              <FileCheckedIcon
                width={24}
                height={28}
                className="fill-gray-700 ml-6"
              />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={i18n.t("STOCK_AND_RSR")}
              className="text-body-regular-1 text-gray-700"
            />
          </ListItemButton>
        </ListItem>
      </Box>
    </Box>
  );
};

export default LateralMenuItemsList;
