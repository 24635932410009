import React, { ReactElement } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { ReactComponent as SuppoLogo } from "@/assets/brand/brandLogoWhite.svg";
import { ReactComponent as SuppoSimplifiedLogo } from "@/assets/brand/brandLogoSimplified.svg";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Hidden from "@mui/material/Hidden";
import CloseIcon from "@mui/icons-material/Close";
import LateralMenuItemsList from "./lateralMenuItemsList";
import LogoutButton from "@/components/DataDisplay/LogoutButton";

interface LateralMenuProps {
  children: ReactElement;
  buttonPressed: "outsourced" | "requests";
  logoutUser: () => void;
}

export default function LateralMenuContent({
  children,
  buttonPressed,
  logoutUser,
}: LateralMenuProps) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const drawerWidth = 240;

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }
  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        role="header"
        className="bg-gradient-to-r from-purple-800 via-purple-600 to-purple-500"
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            className=" sm:hidden"
          >
            {mobileOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            className="flex grow justify-end sm:justify-start"
          >
            <IconButton
              color="inherit"
              className="hover:bg-inherit smMax:hidden"
              href={"/"}
            >
              <SuppoLogo height={35} width={105} />
            </IconButton>
            <IconButton color="inherit" className="hover:bg-inherit sm:hidden">
              <SuppoSimplifiedLogo height={35} width={50} />
            </IconButton>
          </Typography>
          <IconButton
            color="inherit"
            className="flex top-0 right-0"
            size="small"
          >
            <LogoutButton logoutUser={logoutUser} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <nav className="sm:shrink sm:w-drawerWidth">
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            role="drawer"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            sx={{
              [`& .MuiDrawer-paper`]: {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <LateralMenuItemsList buttonPressed={buttonPressed} />
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            variant="permanent"
            role="drawer"
            open={true}
            anchor="left"
            className="smMax:hidden sm:shrink sm:w-drawerWidth"
            sx={{
              [`& .MuiDrawer-paper`]: {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
          >
            <LateralMenuItemsList buttonPressed={buttonPressed} />
          </Drawer>
        </Hidden>
      </nav>
      <Box component="main" className="grow p-6">
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}
