import i18n from "@/i18n";
import { ReactComponent as UploadPendingImage } from "@/assets/images/filePending.svg";
import React, { useRef } from "react";
import DataEntry from "@/components/DataEntry";

interface ContentPendingUploadProps {
  changeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ContentPendingUpload = ({ changeHandler }: ContentPendingUploadProps) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  return (
    <div className="flex flex-col md:flex-row items-center">
      <UploadPendingImage className="flex-1" />
      <div className="grow-1 flex flex-col mt-5 sm:mt-0 sm:ml-10 sm:w-48">
        <DataEntry.Button
          label={i18n.t("SELECT_FILE")}
          type="outlined"
          onClick={() => hiddenFileInput.current?.click()}
          customStyles={{ color: "#A429FF", borderColor: "#A429FF" }}
        />
      </div>
      <input
        id="HIDDEN_FILE_INPUT"
        placeholder={i18n.t("SELECT_FILE")}
        type="file"
        ref={hiddenFileInput}
        onChange={changeHandler}
        className="hidden"
      />
    </div>
  );
};

export default ContentPendingUpload;
