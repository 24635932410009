import { SessionStorageKeysEnum } from "@/utils/enums/SessionStorageKeysEnum";
import axios, { InternalAxiosRequestConfig, HttpStatusCode } from "axios";
import SessionService from "../sessionService";
import { publish } from "pubsub-js";
import { AUTHENTICATION_FAILURE_TOPIC } from "@/utils/constants";

const apiService = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

apiService.interceptors.response.use(
  (successfulReq) => {
    return successfulReq;
  },
  async (error) => {
    if (
      error.response &&
      error.response.status === HttpStatusCode.Unauthorized
    ) {
      SessionService.cleanTokenInSessionStorage();
      publish(AUTHENTICATION_FAILURE_TOPIC);
    }
    return await Promise.reject(error);
  }
);

apiService.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const accessToken = sessionStorage.getItem(
    SessionStorageKeysEnum.ACCESS_TOKEN_SESSION
  );
  const newConfig = { ...config };
  if (accessToken && newConfig.headers)
    newConfig.headers.Authorization = `Bearer ${accessToken}`;
  return newConfig;
});

export default apiService;
