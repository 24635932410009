import React from "react";

interface RadionButtonProps {
  id: string;
  label: string;
  name: string;
  value: string;
  disabled?: boolean;
  checked?: boolean;
  handleChange: (e: React.ChangeEvent<any>) => void;
}

export default function RadioButton({
  id,
  label,
  name,
  value,
  disabled = false,
  checked = false,
  handleChange,
}: RadionButtonProps) {
  return (
    <label className="flex space-x-2">
      <input
        id={id}
        data-testid={id}
        type="radio"
        className="rounded accent-purple-600"
        name={name}
        value={value}
        onChange={handleChange}
        checked={checked}
        disabled={disabled}
      />
      <span className="text-gray-700 text-sm">{label}</span>
    </label>
  );
}
