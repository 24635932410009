import React, { useCallback, useEffect, useState } from "react";
import i18n from "@/i18n";
import { ReactComponent as FilterIcon } from "@/assets/icons/filterIcon.svg";
import { ReactComponent as ImportIcon } from "@/assets/icons/importIcon.svg";
import { ReactComponent as TrashIcon } from "@/assets/icons/trashIcon.svg";
import { ReactComponent as ExportIcon } from "@/assets/icons/exportIcon.svg";
import { OrderService } from "@/typeDeclarations/Entities/OrderService";
import RequestCard from "./RequestCard";
import DataDisplay from "@/components/DataDisplay";
import Layout from "@/components/Layout";
import FilterCard from "./FilterCard";
import EmptyScheduleList from "./EmptyScheduleList";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { GetOrderServicesResponseDto } from "@/typeDeclarations/Requests/GetOrderServicesResponseDto";
import { NavigateFunction } from "react-router-dom";
import { createBrowserHistory } from "history";
import DataEntry from "@/components/DataEntry";
import UploadModal from "./uploadModal/UploadModal";
import { scheduleSnackbarMessage } from "@/typeDeclarations/Entities/ScheduleSnackbarMessage";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import AlertHtmlContent from "@/components/Layout/Alert/AlertHtmlContent";
import SessionService from "@/services/sessionService";
import { ExportModal } from "./exportModal/ExportModal";
import notifyBugsnag from "@/utils/helpers/Notification/notifyBugsnag";

interface ScheduleRequestsContentProps {
  fetchOrderServices: (
    ba: string | undefined,
    page: number,
    count: number
  ) => Promise<GetOrderServicesResponseDto>;
  uploadOrderServiceFile: (file: File) => Promise<any>;
  navigate: NavigateFunction;
  getScheduleSnackbarMessage: () => scheduleSnackbarMessage | null;
  getOrderServicesInFile: (startDate: string, endDate: string) => Promise<any>;
  isMobileOpen: boolean;
}

export default function ScheduleRequestsContent({
  fetchOrderServices,
  uploadOrderServiceFile,
  navigate,
  getScheduleSnackbarMessage,
  getOrderServicesInFile,
  isMobileOpen,
}: ScheduleRequestsContentProps) {
  const filterHistory = createBrowserHistory();
  const [isFilterCardVisible, setIsFilterCardVisible] =
    useState<boolean>(false);
  const [orderServices, setOrderServices] = useState<OrderService[]>([]);
  const [baFilter, setBaFilter] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isBaFilterChecked, setIsChecked] = useState<boolean>(false);
  const [snackbarVisibility, setSnackbarVisibility] = useState<boolean>(false);
  const [infoSnackbarVisibility, setInfoSnackbarVisibility] =
    useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSubtitle, setSnackbarSubtitle] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "error"
  );
  const [modalUploadIsVisible, setModalUploadIsVisible] = useState(false);
  const [modalExportIsVisible, setModalExportIsVisible] = useState(false);
  const COUNT_DEFAULT = 10;

  const getQueryFilter = (filter: string, clearFilter?: boolean) => {
    if (filter.length) filterHistory.push(`?filter=${filter}`);
    if (clearFilter) filterHistory.push("");

    const filterParams = filterHistory.location?.search
      ?.substring(1)
      ?.split("=");

    if (filterParams[1]?.length) return filterParams[1];

    return "";
  };

  const handleSnackbar = (title: string, subtitle: string) => {
    setSnackbarMessage(title);
    setSnackbarSubtitle(subtitle);
    setSnackbarVisibility(true);
  };

  const getListItems = useCallback(
    async (pagination: number, filter: string, clearFilter?: boolean) => {
      setIsLoading(true);
      try {
        window.scrollTo({ top: 0, left: 0 });

        const queryFilter = getQueryFilter(filter, clearFilter);

        setBaFilter(queryFilter.length ? queryFilter : filter);

        const orderServicesResponse = await fetchOrderServices(
          queryFilter,
          pagination,
          COUNT_DEFAULT
        );

        setPage(pagination);
        setTotalPages(orderServicesResponse.pages);

        if (orderServicesResponse.orderServices.length) {
          setOrderServices(orderServicesResponse.orderServices);
        } else if (filter.length) {
          handleSnackbar(i18n.t("NO_RESULT_FOUND"), i18n.t("VERIFY_TYPING"));
          await getListItems(pagination, filter, true);
        }
      } catch (error) {
        handleSnackbar(i18n.t("ERROR_ON_LOAD"), i18n.t("ERROR_FETCH_REQUESTS"));
        notifyBugsnag(error as Error);
      } finally {
        setIsLoading(false);
      }
    },
    [page]
  );

  useEffect(() => {
    const snackbarContent = getScheduleSnackbarMessage();
    if (snackbarContent?.title && snackbarContent?.message) {
      setSnackbarMessage(snackbarContent.title);
      setSnackbarSubtitle(snackbarContent.message);
      setSnackbarSeverity("success");
      setSnackbarVisibility(true);
      setInfoSnackbarVisibility(true);
    }
  }, []);

  useEffect(() => {
    const queryFilter = getQueryFilter("");
    void getListItems(page, queryFilter);
    filterHistory.push("");
  }, [page]);

  if (isLoading) {
    return <DataDisplay.LoadingPage />;
  }

  return (
    <div className="sm:px-10 relative">
      <div className="flex flex-col">
        <div
          className={`flex flex-col justify-between md:flex-row items-center ${
            snackbarVisibility ? "mt-0" : "mt-6"
          } `}
        >
          <span className="text-heading-semibold-4 fill-current text-gray-800 align-middle smMax:text-md font-bold">
            {i18n.t("SCHEDULE_REQUESTS")}
          </span>
          <div className="flex flex-row gap-8 mt-6 md:mt-0 md:gap-5">
            <DataEntry.Button
              label={i18n.t("EXPORT")}
              type="outlined"
              onClick={() => setModalExportIsVisible(true)}
              customStyles={{
                color: "#29A35E",
                borderColor: "#29A35E",
                height: 44,
                maxWidth: 120,
                "&:hover": {
                  backgroundColor: "#DEF7E9",
                  borderColor: "#29A35E",
                },
              }}
              endIcon={<ExportIcon fill="#29A35E" />}
            />
            <DataEntry.Button
              label={i18n.t("IMPORT")}
              type="outlined"
              onClick={() => setModalUploadIsVisible(true)}
              customStyles={{
                color: "#A429FF",
                borderColor: "#A429FF",
                height: 44,
                maxWidth: 120,
                "&:hover": {
                  backgroundColor: "#F6EBFF",
                  borderColor: "#A429FF",
                },
                alignSelf: "flex-end",
              }}
              endIcon={<ImportIcon fill="#A429FF" />}
            />
          </div>
        </div>
        <div className="flex flex-col self-end absolute z-50">
          <Layout.Alert
            severity={snackbarSeverity}
            visibility={snackbarVisibility}
            setVisibility={setSnackbarVisibility}
            title={snackbarMessage}
            subtitle={snackbarSubtitle}
            icon={
              snackbarSeverity === "error" ? (
                <HighlightOffRoundedIcon fontSize="inherit" />
              ) : (
                <CheckCircleOutlineRoundedIcon fontSize="inherit" />
              )
            }
            style="my-4 mt-6 relative"
          />
          <AlertHtmlContent
            severity="info"
            visibility={infoSnackbarVisibility}
            setVisibility={setInfoSnackbarVisibility}
            title={i18n.t("DID_YOU_KNOW_REQUESTS_ARE_ORDERED")}
            subtitle={i18n.t("EXPLANATION_OF_ORDER_SERVICE_DISPLAY")}
            icon={
              <ErrorOutlineRoundedIcon fontSize="inherit" color="primary" />
            }
            style="my-4 relative"
          />
        </div>
        {orderServices.length ? (
          <>
            <div className={`relative flex justify-end mt-5`} tabIndex={1}>
              <button
                className={`flex flex-row text-body-semibold-1 mb-8 ${
                  isFilterCardVisible ? "text-purple-700" : "text-gray-700"
                }`}
                onClick={() => setIsFilterCardVisible(!isFilterCardVisible)}
              >
                <span className="mr-1">{i18n.t("FILTER")}</span>
                <FilterIcon
                  className={`${
                    isFilterCardVisible ? "fill-purple-700" : "fill-gray-700"
                  } `}
                />
              </button>
              <FilterCard
                visibility={isFilterCardVisible}
                setVisibility={setIsFilterCardVisible}
                setFilter={setBaFilter}
                filter={baFilter}
                onSubmit={getListItems}
                isChecked={isBaFilterChecked}
                setIsChecked={setIsChecked}
              />
            </div>
            <div className="flex flex-col">
              {orderServices.map((item: OrderService) => {
                return (
                  <RequestCard
                    key={item.id}
                    orderService={item}
                    navigate={(route: string) => {
                      if (isBaFilterChecked)
                        filterHistory.push(`?filter=${item.ba}`);
                      SessionService.setSelectedOrderService(item);
                      navigate(route, { state: { item } });
                    }}
                  />
                );
              })}
            </div>
            {totalPages > 1 && (
              <Layout.Pagination
                page={page}
                setPage={setPage}
                totalPages={totalPages}
              />
            )}
          </>
        ) : (
          <EmptyScheduleList />
        )}
      </div>
      <ExportModal
        open={modalExportIsVisible}
        getOrderServicesInFile={getOrderServicesInFile}
        onClose={() => setModalExportIsVisible(false)}
        isMobileOpen={isMobileOpen}
      />
      <UploadModal
        open={modalUploadIsVisible}
        onClose={() => setModalUploadIsVisible(false)}
        onSelectFile={uploadOrderServiceFile}
        refreshList={() => {
          void getListItems(1, "");
        }}
      />
    </div>
  );
}
