import React from "react";
import { Dialog } from "@mui/material";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  children?: React.ReactNode;
  className?: string;
}

const Modal = ({ open, handleClose, children, className = "" }: ModalProps) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      PaperProps={{ className: `p-5 rounded-3xl m-10 ${className}` }}
      sx={{
        backdropFilter: "blur(5px)",
      }}
    >
      {children}
    </Dialog>
  );
};

export default Modal;
