import React from "react";
import DataEntry from "@/components/DataEntry";
import FileIcon from "@/assets/icons/FileIcon";
import { ReactComponent as DownloadIcon } from "@/assets/icons/downloadIcon.svg";
import i18n from "@/i18n";

interface ContentExportSuccessProps {
  onDownload: () => void;
}

function ContentExportSuccess({ onDownload }: ContentExportSuccessProps) {
  return (
    <>
      <span className="text-heading-regular-6 sm:text-heading-regular-6 text-gray-700">
        {i18n.t("ORDER_SERVICES_FILE_GENERATED_SUCCESS")}
      </span>
      <DataEntry.Button
        label={i18n.t("DOWNLOAD_SHEET")}
        customStyles={{
          alignSelf: "flex-start",
          fontSize: 16,
        }}
        onClick={onDownload}
        color="secondary"
        endIcon={
          <DownloadIcon className={`fill-blue-100 text-body-semibold-2`} />
        }
      />
      <div className="flex flex-row border-solid border border-gray-500 rounded-md p-2">
        <FileIcon text={"XLSX"} />
        <span className="inline-block text-body-regular-1 fill-current text-black text-center mt-1 ml-3 align-items: center">
          {"Agendamentos.xlsx"}
        </span>
      </div>
    </>
  );
}

export default ContentExportSuccess;
