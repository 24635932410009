import { ScheduleReport } from "@/typeDeclarations/Entities/ScheduleReport";
import { AxiosResponse } from "axios";
import apiService from "./axiosInstances/interceptConfiguration";

export const getScheduleReport = async (scheduleId: number) => {
  const response: AxiosResponse = await apiService.get(
    `/api/v1/schedules/${scheduleId}/report`
  );
  return response.data as ScheduleReport;
};
