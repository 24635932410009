export const getStateNameFromAbreviation = function (val: string) {
  switch (val.toUpperCase()) {
    case "AC":
      return "Acre";
    case "AL":
      return "Alagoas";
    case "AM":
      return "Amazonas";
    case "AP":
      return "Amapa";
    case "BA":
      return "Bahia";
    case "CE":
      return "Ceara";
    case "DF":
      return "Distrito Federal";
    case "ES":
      return "Espirito Santo";
    case "GO":
      return "Goias";
    case "MA":
      return "Maranhao";
    case "MG":
      return "Minas Gerais";
    case "MS":
      return "Mato Grosso do Sul";
    case "MT":
      return "Mato Grosso";
    case "PA":
      return "Para";
    case "PB":
      return "Paraiba";
    case "PE":
      return "Pernambuco";
    case "PI":
      return "Piaui";
    case "PR":
      return "Parana";
    case "RJ":
      return "Rio de Janeiro";
    case "RN":
      return "Rio Grande do Norte";
    case "RO":
      return "Rondonia";
    case "RR":
      return "Roraima";
    case "RS":
      return "Rio Grande do Sul";
    case "SC":
      return "Santa Catarina";
    case "SE":
      return "Sergipe";
    case "SP":
      return "São Paulo";
    case "TO":
      return "Tocantins";
  }
};
