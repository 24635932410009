import React from "react";
import DataEntry from "@/components/DataEntry";
import { ReactComponent as ProcessIcon } from "@/assets/icons/processIcon.svg";
import i18n from "@/i18n";

interface ContentExportPartialSuccessProps {
  onGenerateSheet: () => void;
}

function ContentExportPartialSuccess({
  onGenerateSheet,
}: ContentExportPartialSuccessProps) {
  return (
    <>
      <span className="text-heading-regular-6 sm:text-heading-regular-6 text-gray-700">
        {i18n.t("PERIOD_SELECTED")}
      </span>
      <DataEntry.Button
        label={i18n.t("GENERATE_SHEET")}
        color="secondary"
        customStyles={{
          alignSelf: "flex-start",
          fontSize: 16,
        }}
        onClick={onGenerateSheet}
        endIcon={
          <ProcessIcon className={`fill-blue-100 text-body-semibold-2`} />
        }
      />
    </>
  );
}

export default ContentExportPartialSuccess;
