import { GetOrderServicesResponseDto } from "@/typeDeclarations/Requests/GetOrderServicesResponseDto";
import { AxiosResponse } from "axios";
import apiService from "./axiosInstances/interceptConfiguration";

export const uploadOrderServiceFile = async (file: File) => {
  const formData = new FormData();
  formData.append("file", file, file.name);
  const response = await apiService.post(
    "/api/v1/OrderServices/Bulk",
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
      responseType: "blob",
    }
  );
  if (response.data) return response;
};

export const getOrderServices = async (
  ba: string | undefined,
  page: number,
  count: number
) => {
  const url =
    "/api/v1/orderservices?" +
    `${ba ? `ba=${ba}` : ""}` +
    `&page=${page}&count=${count}`;
  const response: AxiosResponse = await apiService.get(url);
  return response.data as GetOrderServicesResponseDto;
};

export const getOrderServicesInFile = async (
  startDate: string,
  endDate: string
) => {
  const url = `/api/v1/OrderServices/Export?startDate=${startDate}&endDate=${endDate}`;
  const response: AxiosResponse = await apiService.get(url, {
    responseType: "blob",
  });
  return response;
};
