import i18n from "@/i18n";
import React from "react";

function ContentExportPending() {
  return (
    <span className="text-body-bold-2 sm:text-heading-bold-5 text-gray-700">
      {i18n.t("SELECT_PERIOD")}
    </span>
  );
}

export default ContentExportPending;
