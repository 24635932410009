import Layout from "@/components/Layout";
import { getScheduleReport } from "@/services/schedulesService";
import SessionService from "@/services/sessionService";
import { OrderService } from "@/typeDeclarations/Entities/OrderService";

import React, { useEffect, useState } from "react";
import ScheduleReportContent from "./ScheduleReportContent";

export default function ScheduleReport() {
  const [orderService, setOrderService] = useState<OrderService>();

  const fetchOrderService = () => {
    const selectedOrderService = SessionService.getSelectedOrderService();
    if (selectedOrderService) {
      setOrderService(selectedOrderService);
    }
  };

  const fetchScheduleReport = async (scheduleId: number) => {
    return await getScheduleReport(scheduleId);
  };

  useEffect(() => {
    fetchOrderService();
  }, []);

  return (
    <Layout.LateralMenu buttonPressed="requests">
      <ScheduleReportContent
        orderService={orderService}
        fetchScheduleReport={fetchScheduleReport}
      />
    </Layout.LateralMenu>
  );
}
