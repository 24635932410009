import React from "react";
import i18n from "@/i18n";
import ReplayIcon from "@mui/icons-material/Replay";
import DataEntry from "@/components/DataEntry";

interface ContentExportNotFoundProps {
  onRetry: () => void;
}

function ContentExportNotFound({ onRetry }: ContentExportNotFoundProps) {
  return (
    <>
      <span className="text-heading-regular-6 sm:text-heading-regular-6 text-gray-700">
        {i18n.t("ORDER_SERVICES_NOT_FOUND")}
      </span>
      <DataEntry.Button
        label={i18n.t("TRY_AGAIN")}
        color="secondary"
        onClick={onRetry}
        endIcon={<ReplayIcon />}
        customStyles={{ alignSelf: "flex-start", marginBottom: -2 }}
      />
    </>
  );
}

export default ContentExportNotFound;
