import { OrderService } from "@/typeDeclarations/Entities/OrderService";
import { OutsourcedWorker } from "@/typeDeclarations/Entities/OutsourcedWorker";
import { scheduleSnackbarMessage } from "@/typeDeclarations/Entities/ScheduleSnackbarMessage";
import { SessionStorageKeysEnum } from "@/utils/enums/SessionStorageKeysEnum";
import { UserProfilesEnum } from "@/utils/enums/UserProfilesEnum";
import Keycloak, { KeycloakResourceAccess } from "keycloak-js";

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export default class SessionService {
  public static isUserRoleValid = (keycloak?: Keycloak) => {
    const resourceAccess = keycloak?.tokenParsed?.resource_access;
    if (keycloak?.authenticated && resourceAccess) {
      const userRole = resourceAccess["backoffice-suppo7"].roles[0];
      return userRole === UserProfilesEnum.BACKOFFICE;
    }
    return false;
  };

  public static setSessionStorage(
    refreshToken?: string,
    accessToken?: string
  ): void {
    if (accessToken)
      sessionStorage.setItem(
        SessionStorageKeysEnum.ACCESS_TOKEN_SESSION,
        accessToken
      );

    if (refreshToken)
      sessionStorage.setItem(
        SessionStorageKeysEnum.REFRESH_TOKEN_SESSION,
        refreshToken
      );
  }

  public static cleanTokenInSessionStorage(): void {
    sessionStorage.removeItem(SessionStorageKeysEnum.ACCESS_TOKEN_SESSION);
    sessionStorage.removeItem(SessionStorageKeysEnum.REFRESH_TOKEN_SESSION);
  }

  public static getUserRole(
    resourceAccess: KeycloakResourceAccess | undefined,
    authenticated: boolean | undefined
  ): UserProfilesEnum | undefined {
    let role: UserProfilesEnum | undefined;

    if (
      authenticated &&
      resourceAccess &&
      (resourceAccess["backoffice-suppo7"] ||
        resourceAccess["plataforma-suppo7"])
    ) {
      const stringRoles =
        resourceAccess["plataforma-suppo7"]?.roles ??
        resourceAccess["backoffice-suppo7"]?.roles;

      stringRoles?.forEach((stringRole) => {
        if (stringRole === UserProfilesEnum.BACKOFFICE)
          role = UserProfilesEnum.BACKOFFICE;
        else if (stringRole === UserProfilesEnum.OUTSOURCED_WORKER)
          role = UserProfilesEnum.OUTSOURCED_WORKER;
      });
    } else role = UserProfilesEnum.OTHER;

    return role;
  }

  public static setOutsourcedWorkerSelected(
    outsourcedWorker: OutsourcedWorker
  ) {
    sessionStorage.setItem(
      SessionStorageKeysEnum.SELECTED_OUTSOURCED_WORKER,
      JSON.stringify(outsourcedWorker)
    );
  }

  public static getOutsourcedWorkerSelected() {
    const selectedOutsourcedWorker = sessionStorage.getItem(
      SessionStorageKeysEnum.SELECTED_OUTSOURCED_WORKER
    ) as string;

    return JSON.parse(selectedOutsourcedWorker) as OutsourcedWorker;
  }

  public static setSelectedOrderService(orderService: OrderService) {
    sessionStorage.setItem(
      SessionStorageKeysEnum.SELECTED_ORDER_SERVICE,
      JSON.stringify(orderService)
    );
  }

  public static getSelectedOrderService() {
    const selectedOrderService = sessionStorage.getItem(
      SessionStorageKeysEnum.SELECTED_ORDER_SERVICE
    ) as string;

    return JSON.parse(selectedOrderService) as OrderService;
  }

  public static setScheduleSnackbarMessage(
    message: scheduleSnackbarMessage | null
  ) {
    sessionStorage.setItem(
      SessionStorageKeysEnum.SELECTED_ORDER_SERVICE,
      JSON.stringify(message)
    );
  }

  public static getScheduleSnackbarMessage() {
    const message = sessionStorage.getItem(
      SessionStorageKeysEnum.SELECTED_ORDER_SERVICE
    ) as string;

    return JSON.parse(message) as scheduleSnackbarMessage;
  }
}
