import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { SxProps, Theme } from "@mui/material";

interface LoadingButtonProps {
  label: string;
  loading: boolean;
  onClick?: () => void;
  id: string;
  color?: "primary" | "secondary" | "warning" | "error";
  type?: "button" | "submit" | "reset";
  variant?: "text" | "contained" | "outlined";
  loadingPosition?: "start" | "center" | "end";
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  customStyles?: SxProps<Theme>;
  disabled?: boolean;
  disableRipple?: boolean;
}

const MaterialLoadingButton = ({
  label,
  onClick = () => null,
  color = "primary",
  type = "button",
  variant = "text",
  startIcon = null,
  endIcon = null,
  customStyles = {},
  disabled = false,
  disableRipple = false,
  loading,
  loadingPosition = "center",
  id,
}: LoadingButtonProps) => {
  return (
    <LoadingButton
      data-testid={id}
      loading={loading}
      variant={variant}
      type={type}
      onClick={onClick}
      color={color}
      sx={{ borderRadius: 3, padding: 1, ...customStyles }}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled}
      disableElevation
      disableFocusRipple={disableRipple}
      disableTouchRipple={disableRipple}
      loadingPosition={loadingPosition}
    >
      <span>{label}</span>
    </LoadingButton>
  );
};

export default MaterialLoadingButton;
