import MaskInput from "@/components/MaskInput";
import i18n from "@/i18n";
import { OutsourcedWorkerBankInfoDto } from "@/typeDeclarations/DTOs/OutsourcedWorkerBankInfoDto";
import { GetOutsourcedWorkerDto } from "@/typeDeclarations/Requests/GetOutsourcedWorker/GetOutsourcedWorkerDto";
import { useFormik } from "formik";
import * as yup from "yup";
import React, { useState } from "react";

import { isCpfValid } from "../../utils/cpfValidatorFunction";
import EditButton from "./formComponents/EditButton";
import SaveButton from "./formComponents/SaveButton";

interface OutsourcedWorkerBankInfoFormProps {
  outsourcedWorker: GetOutsourcedWorkerDto;
  onSubmit: (
    outsourcedWorkerBankInfoDto: OutsourcedWorkerBankInfoDto,
    email: string
  ) => {};
}

export default function OutsourcedWorkerBankInfoForm({
  onSubmit,
  outsourcedWorker,
}: OutsourcedWorkerBankInfoFormProps) {
  const OutsourcedWorkerBankInfoFormsProps = {
    account: outsourcedWorker.bankInfo ? outsourcedWorker.bankInfo.account : "",
    accountHolderDocument: outsourcedWorker.bankInfo
      ? outsourcedWorker.bankInfo.accountHolderDocument
      : "",
    agency: outsourcedWorker.bankInfo ? outsourcedWorker.bankInfo.agency : "",
    bankName: outsourcedWorker.bankInfo
      ? outsourcedWorker.bankInfo.bankName
      : "",
  };

  const schemaValidation = yup.object().shape({
    accountHolderDocument: yup
      .string()
      .required(i18n.t("FIELD_REQUIRED"))
      .test("is-cpf-valid", i18n.t("CPF_INVALID"), (cpf) => isCpfValid(cpf)),
    bankName: yup.string().required(i18n.t("FIELD_REQUIRED")),
    agency: yup
      .number()
      .typeError(i18n.t("ONLY_NUMBERS"))
      .required(i18n.t("FIELD_REQUIRED")),
    account: yup
      .number()
      .typeError(i18n.t("ONLY_NUMBERS"))
      .required(i18n.t("FIELD_REQUIRED")),
  });

  const handlerSubmit = async () => {
    const bankInfoDto: OutsourcedWorkerBankInfoDto = {
      bankCode: outsourcedWorker.bankInfo.bankCode,
      accountHolderName: outsourcedWorker.bankInfo.bankName,
      agency: formik.values.agency,
      bankName: formik.values.bankName,
      accountHolderDocument: formik.values.accountHolderDocument,
      account: formik.values.account,
    };
    onSubmit(bankInfoDto, outsourcedWorker.email);
  };

  const formik = useFormik({
    initialValues: OutsourcedWorkerBankInfoFormsProps,
    initialErrors: OutsourcedWorkerBankInfoFormsProps,
    validationSchema: schemaValidation,
    validateOnChange: true,
    onSubmit: () => {},
  });

  const [IsDisabledInput, setDisabledInput] = useState<boolean>(true);
  const [toggle, setToggle] = useState<string>("edit");

  const setAllFieldsTouched = () => {
    void formik.setFieldTouched("account", true);
    void formik.setFieldTouched("accountHolderDocument", true);
    void formik.setFieldTouched("agency", true);
    void formik.setFieldTouched("bankName", true);
  };

  const onClickSaveButton = () => {
    void formik.setFieldTouched("bankName", true);
    if (formik.isValid) {
      void handlerSubmit();
      setDisabledInput(true);
      setToggle("edit");
    }
    setAllFieldsTouched();
  };

  return (
    <form onSubmit={formik.handleSubmit} role="form">
      <div className="flex flex-col space-y-18">
        <div className="flex flex-col space-y-2">
          <div className="flex flex-row justify-between  md:mr-28 lg:mr-40">
            <label className="text-base text-body-bold-1">
              {i18n.t("BANK_DATA")}
            </label>
            {toggle === "edit" ? (
              <EditButton
                onClick={() => {
                  setDisabledInput(false);
                  setToggle("save");
                }}
              />
            ) : (
              <SaveButton onClick={onClickSaveButton} />
            )}
          </div>
        </div>
        <div className="flex flex-col md:flex-row flex-wrap w-[90%] smMax:w-full mt-6">
          <div className="flex flex-col w-full md:w-[167px] mr-4">
            <label htmlFor="accountHolderDocument" className="text-gray-500">
              CPF
            </label>
            <MaskInput
              id="accountHolderDocument"
              name="accountHolderDocument"
              placeholder="xxx.xxx.xxx-xx"
              handleChange={formik.handleChange}
              value={formik.values.accountHolderDocument}
              handleBlur={formik.handleBlur}
              maks={"999.999.999-99"}
              error={
                formik.errors.accountHolderDocument &&
                formik.touched.accountHolderDocument
                  ? formik.errors.accountHolderDocument
                  : ""
              }
              disabled={IsDisabledInput}
            />
          </div>
          <div className="flex flex-col grow mr-4">
            <label htmlFor="bankName" className="text-gray-500">
              {i18n.t("BANK")}
            </label>
            <MaskInput
              id="bankName"
              name="bankName"
              placeholder="Banco do Brasil"
              handleChange={formik.handleChange}
              value={formik.values.bankName}
              handleBlur={formik.handleBlur}
              error={
                formik.errors.bankName && formik.touched.bankName
                  ? formik.errors.bankName
                  : ""
              }
              disabled={IsDisabledInput}
            />
          </div>
          <div className="flex flex-col w-full md:w-[167px] mr-4">
            <label htmlFor="agency" className="text-gray-500">
              {i18n.t("AGENCY")}
            </label>
            <MaskInput
              id="agency"
              name="agency"
              placeholder="xxx.xxx-x"
              handleChange={formik.handleChange}
              value={formik.values.agency}
              handleBlur={formik.handleBlur}
              error={
                formik.errors.agency && formik.touched.agency
                  ? formik.errors.agency
                  : ""
              }
              disabled={IsDisabledInput}
            />
          </div>
          <div className="flex flex-col w-full md:w-[167px]">
            <label htmlFor="account" className="text-gray-500">
              {i18n.t("ACCOUNT")}
            </label>
            <MaskInput
              id="account"
              name="account"
              placeholder="xxx.xxx-x"
              handleChange={formik.handleChange}
              value={formik.values.account}
              handleBlur={formik.handleBlur}
              error={
                formik.errors.account && formik.touched.account
                  ? formik.errors.account
                  : ""
              }
              disabled={IsDisabledInput}
            />
          </div>
        </div>
      </div>
    </form>
  );
}
