import i18n from "@/i18n";
import { ScheduleReport } from "@/typeDeclarations/Entities/ScheduleReport";
import { ProblemUnsolvedReasons } from "@/utils/enums/ProblemUnsolvedReasons";
import { ScheduleNotHappenedReasons } from "@/utils/enums/ScheduleNotHappenedReasons";
import { UserAnswer } from "@/utils/enums/UserAnswer";
import React from "react";

interface ReportSectionProps {
  report: ScheduleReport | undefined;
  getScheduleDuration: () => string;
}

export default function ReportSection({
  report,
  getScheduleDuration,
}: ReportSectionProps) {
  const scheduleNotHappenedReason =
    report?.scheduleNotHappenedReason ===
    ScheduleNotHappenedReasons.CLIENT_WASNT_AT_HOME
      ? i18n.t("CLIENT_WASNT_AT_HOME")
      : report?.scheduleNotHappenedReason ===
        ScheduleNotHappenedReasons.TOO_FAR_AWAY
      ? i18n.t("TOO_FAR_AWAY")
      : report?.scheduleNotHappenedReason ===
        ScheduleNotHappenedReasons.WITHOUT_MATERIALS
      ? i18n.t("WITHOUT_MATERIALS")
      : report?.scheduleNotHappenedReason === ScheduleNotHappenedReasons.OTHER
      ? i18n.t("OTHER")
      : "--";

  const problemUnsolvedReason =
    report?.problemUnsolvedReason === ProblemUnsolvedReasons.DIFFERENT_PROBLEM
      ? i18n.t("DIFFERENT_PROBLEN")
      : report?.problemUnsolvedReason ===
        ProblemUnsolvedReasons.WITHOUT_MATERIALS
      ? i18n.t("WITHOUT_MATERIALS")
      : report?.problemUnsolvedReason === ProblemUnsolvedReasons.OTHER
      ? i18n.t("OTHER")
      : "--";

  return (
    <section className="bg-gray-100 px-4 py-4 rounded-2xl mt-4 mb-7 md:px-8">
      <div className="flex flex-col text-gray-700 md:flex-row">
        <div
          className={`${
            report?.scheduleHappened === UserAnswer.YES &&
            report?.problemSolved === UserAnswer.NO
              ? "md:w-1/3"
              : "md:w-1/2"
          } flex flex-col md:flex-row`}
        >
          <div className=" flex flex-col">
            <span className="text-caption-bold-1 md:text-body-bold-2">
              {i18n.t("SCHEDULE_HAPPENED")}
            </span>
            <span className="text-body-regular-2 md:text-body-regular-1 min-w-full">
              {report?.scheduleHappened === UserAnswer.YES
                ? i18n.t("YES")
                : report?.scheduleHappened === UserAnswer.NO
                ? i18n.t("NO")
                : "--"}
            </span>
            {report?.scheduleHappened === UserAnswer.YES && (
              <>
                <span className="text-caption-bold-1 md:text-body-bold-2 mt-3 min-w-1/2">
                  {i18n.t("SCHEDULE_DURATION")}
                </span>
                <span className="text-body-regular-2 md:text-body-regular-1 min-w-1/2">
                  {getScheduleDuration()}
                </span>
              </>
            )}
            {report?.scheduleHappened === UserAnswer.NO && (
              <>
                <span className="text-caption-bold-1 md:text-body-bold-2 mt-3 min-w-1/2">
                  {i18n.t("WHY_SCHEDULE_DIDNT_HAPPEN")}
                </span>
                <span className="text-body-regular-2 md:text-body-regular-1 min-w-1/2">
                  {scheduleNotHappenedReason}
                </span>
              </>
            )}
          </div>
        </div>
        {report?.scheduleHappened === UserAnswer.YES && (
          <div
            className={`${
              report?.scheduleHappened === UserAnswer.YES &&
              report?.problemSolved === UserAnswer.NO
                ? "md:w-1/3"
                : "md:w-1/2"
            } flex flex-col md:flex-row`}
          >
            <div className=" flex flex-col">
              <span className="text-caption-bold-1 md:text-body-bold-2">
                {i18n.t("PROBLEM_SOLVED")}
              </span>
              <span className="text-body-regular-2 md:text-body-regular-1 min-w-1/2">
                {report?.problemSolved === UserAnswer.YES
                  ? i18n.t("YES")
                  : report?.problemSolved === UserAnswer.NO
                  ? i18n.t("NO")
                  : "--"}
              </span>
              {report?.problemSolved === UserAnswer.NO && (
                <>
                  <span className="text-caption-bold-1 md:text-body-bold-2 mt-3">
                    {i18n.t("WHY_PROBLEM_WASNT_SOLVED")}
                  </span>
                  <span className="text-body-regular-2 md:text-body-regular-1 min-w-1/2">
                    {problemUnsolvedReason}
                  </span>
                </>
              )}
            </div>
          </div>
        )}
        {(report?.problemSolved === UserAnswer.NO ||
          report?.scheduleHappened === UserAnswer.NO) && (
          <div
            className={`${
              report?.problemSolved === UserAnswer.NO ? "md:w-1/3" : "md:w-1/2"
            } flex flex-col`}
          >
            <span className="text-caption-bold-1 md:text-body-bold-2">
              {i18n.t("DETAILS")}
            </span>
            {report?.problemSolved === UserAnswer.NO && (
              <span className="text-body-regular-2 md:text-body-regular-1 min-w-1/2">
                {report?.problemUnsolvedDetails?.length
                  ? report?.problemUnsolvedDetails
                  : "--"}
              </span>
            )}
            {report?.scheduleHappened === UserAnswer.NO && (
              <span className="text-body-regular-2 md:text-body-regular-1 min-w-1/2">
                {report?.scheduleNotHappenedDetails?.length
                  ? report?.scheduleNotHappenedDetails
                  : "--"}
              </span>
            )}
          </div>
        )}
      </div>
    </section>
  );
}
