export const calculateDiferenceInDaysBetweenTwoDates = (
  date1: Date,
  date2: Date
) => {
  const ONE_HOUR_IN_SECONDS = 3600;
  const ONE_SECOND_IN_MILLISECONDS = 1000;
  const ONE_DAY_IN_HOURS = 24;
  const differenceInMilliseconds = date2.getTime() - date1.getTime();

  const totalDays =
    differenceInMilliseconds /
    (ONE_SECOND_IN_MILLISECONDS * ONE_HOUR_IN_SECONDS * ONE_DAY_IN_HOURS);
  return totalDays;
};
