import React, { useState } from "react";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import DeleteOutsourcedWorkerText from "./components/DeleteOutsourcedWorkerText";
import DataEntry from "@/components/DataEntry";
import ConfirmationModal from "@/components/Layout/ConfirmationModal";
import Layout from "@/components/Layout";
import i18n from "@/i18n";

interface DeleteOutsourcedWorkerContentProps {
  deleteOutsourcedWorker: () => Promise<void>;
  logoutUser: () => void;
}

export default function DeleteOutsourcedWorkerContent({
  deleteOutsourcedWorker,
  logoutUser,
}: DeleteOutsourcedWorkerContentProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [snackbarErrorVisibility, setSnackbarErrorVisibility] =
    useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSubtitle, setSnackbarSubtitle] = useState<string>("");
  const [loading, setIsLoading] = useState(false);

  const renderSnackbarError = (
    snackbarMessage: string,
    snackbarSubtitle: string
  ) => {
    setSnackbarMessage(snackbarMessage);
    setSnackbarSubtitle(snackbarSubtitle);
    setSnackbarErrorVisibility(true);
  };

  const handleDelete = async () => {
    setIsModalOpen(false);
    setIsLoading(true);
    await deleteOutsourcedWorker();
  };

  const handleClickConfirmDelete = () => {
    handleDelete()
      .then(() => {
        window.location.href = "suppo7://?Logout=true";
        logoutUser();
      })
      .catch(() => {
        setIsModalOpen(false);
        setIsLoading(false);
        renderSnackbarError(
          i18n.t("ERROR_OCCURED"),
          i18n.t("ERROR_DELETE_OUTSOURCEDWORKER")
        );
      });
  };

  return (
    <main className="flex flex-col h-screen mdMax:ml-0">
      <header>
        <Layout.Alert
          severity="error"
          visibility={snackbarErrorVisibility}
          setVisibility={setSnackbarErrorVisibility}
          title={snackbarMessage}
          subtitle={snackbarSubtitle}
          icon={<HighlightOffRoundedIcon fontSize="inherit" />}
          style="top-24 right-6 sm:right-10 ml-6 sm:ml-0"
        />
      </header>
      <div className="flex justify-center items-center p-5 bg-purple-800 w-full h-[13%]">
        <h1 className="text-heading-semibold-4 text-white">
          Excluir sua conta
        </h1>
      </div>
      <div className="flex flex-col p-7 justify-between mt-5 h-96 mdMax:min-h-[520px] mt-0">
        <DeleteOutsourcedWorkerText />
        <div className="flex w-1/5 justify-center mdMax:w-full">
          <DataEntry.LoadingButton
            label={"Confirmar exclusão"}
            type="submit"
            variant="contained"
            id="deleteButton"
            loading={loading}
            loadingPosition="end"
            customStyles={{
              color: "#FFFFFF",
              width: "100%",
              borderRadius: "9px",
              fontWeight: 600,
              "&:disabled": {
                color: "#999999",
              },
            }}
            onClick={() => setIsModalOpen(true)}
          />
        </div>
      </div>
      <ConfirmationModal
        title={i18n.t("CONFIRM_DELETE_ACCOUNT")}
        subtitle=""
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleClickConfirmDelete}
      />
    </main>
  );
}
