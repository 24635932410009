import React from "react";
import Layout from "@/components/Layout";
import {
  patchOutsourcedWorkerStatus,
  postOutsourcedWorkerServices,
  patchOutsourcedWorkerBankInfo,
  getOutsourcedWorkerByEmail,
  putOutsourcedWorkerServices,
} from "@/services/outsourcedWorkerService";
import { useNavigate } from "react-router-dom";
import OutsourcedWorkersDetailsContent from "./OutsourcedWorkersDetailsContent";
import SessionService from "@/services/sessionService";

export default function OutsourcedWorkersDetails() {
  const navigate = useNavigate();

  const outsourcedWorker = SessionService.getOutsourcedWorkerSelected();

  return (
    <Layout.LateralMenu buttonPressed="outsourced">
      <OutsourcedWorkersDetailsContent
        outsourcedWorker={outsourcedWorker}
        postOutsourcedWorkerServices={postOutsourcedWorkerServices}
        updateOutsourcedWorkerServices={putOutsourcedWorkerServices}
        fetchOutsourcedWorkerStatus={patchOutsourcedWorkerStatus}
        editOutsourcedWorkerBankInfo={patchOutsourcedWorkerBankInfo}
        fetchOutsourcedWorker={getOutsourcedWorkerByEmail}
        navigate={navigate}
      />
    </Layout.LateralMenu>
  );
}
