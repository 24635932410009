import Layout from "@/components/Layout";
import React from "react";
import { ReactComponent as ConfirmScheduleImage } from "@/assets/images/confirmSchedule.svg";
import i18n from "@/i18n";
import DataEntry from "@/components/DataEntry";
import CloseIcon from "@mui/icons-material/Close";
import { Tooltip } from "@mui/material";

interface ConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  date: string;
  time: string;
  service: string;
  outsourcedWorker: string;
}

const ConfirmationModal = ({
  open,
  onClose,
  onConfirm,
  date,
  time,
  service,
  outsourcedWorker,
}: ConfirmationModalProps) => {
  return (
    <Layout.Modal open={open} handleClose={onClose}>
      <div className="flex flex-col">
        <div className="self-end">
          <DataEntry.Button
            label={i18n.t("CLOSE")}
            customStyles={{ color: "#5C00A3" }}
            onClick={onClose}
            endIcon={
              <CloseIcon className={`fill-purple-800 text-body-regular-2`} />
            }
          />
        </div>
        <div className="flex flex-col sm:flex-row p-2 sm:p-5">
          <ConfirmScheduleImage className="hidden sm:block" />
          <div className="flex flex-col sm:ml-8">
            <span className="text-body-bold-1 sm:text-heading-bold-6 text-gray-700 sm:mt-8 sm:w-[483px] text-center sm:text-left">
              {i18n.t("DO_YOU_REALLY_WISH_TO_SCHEDULE")}
            </span>
            <div className="flex flex-col sm:flex-row p-5 sm:w-[483px] bg-gray-100 rounded-3xl sm:mt-8">
              <div className="flex flex-col sm:w-[240px]">
                <div className="flex flex-col sm:flex-row">
                  <span className="text-body-semibold-2 text-gray-700">{`${i18n.t(
                    "DATE"
                  )}:`}</span>
                  <span className="text-body-regular-2 sm:ml-2 text-gray-700 truncate">
                    {date}
                  </span>
                </div>
                <div className="mt-5 flex flex-col sm:flex-row">
                  <span className="text-body-semibold-2 text-gray-700">{`${i18n.t(
                    "TIME"
                  )}:`}</span>
                  <span className="text-body-regular-2 sm:ml-2 text-gray-700 truncate">
                    {time}
                  </span>
                </div>
              </div>
              <div className="flex-1 flex flex-col mt-5 sm:mt-0 sm:ml-3 truncate">
                <div className="flex flex-col sm:flex-row">
                  <span className="text-body-semibold-2 text-gray-700">
                    {`${i18n.t("SERVICE")}:`}
                  </span>
                  <Tooltip title={service} placement="right-start">
                    <span className="text-body-regular-2 sm:ml-2 text-gray-700 truncate">
                      {service}
                    </span>
                  </Tooltip>
                </div>
                <div className="mt-5 flex flex-col sm:flex-row">
                  <span className="text-body-semibold-2 text-gray-700">
                    {`${i18n.t("WORKER")}:`}
                  </span>
                  <Tooltip title={outsourcedWorker} placement="right-start">
                    <span className="text-body-regular-2 sm:ml-2 text-gray-700 truncate">
                      {outsourcedWorker}
                    </span>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-center sm:justify-end mt-3 sm:mt-8">
              <DataEntry.Button
                label={i18n.t("YES_I_DO")}
                type="contained"
                onClick={onConfirm}
                customStyles={{ width: 164 }}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout.Modal>
  );
};

export default ConfirmationModal;
