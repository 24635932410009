import { OutsourcedWorker } from "../typeDeclarations/Entities/OutsourcedWorker";
import { AxiosResponse } from "axios";
import apiService from "./axiosInstances/interceptConfiguration";
import { OutsourcedWorkerStatusDto } from "@/typeDeclarations/DTOs/OutsourcedWorkerStatusDto";
import { OutsourcedServicesWorkerDto } from "@/typeDeclarations/DTOs/OutsourcedWorkerServicesDto";
import { OutsourcedWorkerBankInfoDto } from "@/typeDeclarations/DTOs/OutsourcedWorkerBankInfoDto";
import { GetOutsourcedWorkerDto } from "@/typeDeclarations/Requests/GetOutsourcedWorker/GetOutsourcedWorkerDto";
import { Dayjs } from "dayjs";

export const getOutsourcedWorkers = async (page: number, count: number) => {
  const response: AxiosResponse = await apiService.get(
    `/api/v1/outsourcedworkers?page=${page}&count=${count}`
  );
  return response.data as OutsourcedWorker[];
};
export const getOutsourcedWorkerByEmail = async (email: string) => {
  const response: AxiosResponse = await apiService.get(
    `/api/v1/outsourcedworkers/${email}`
  );
  return response.data as GetOutsourcedWorkerDto;
};

export const getCountOutsourcedWorkersPendingApproval = async () => {
  const response = await apiService.get(
    `/api/v1/outsourcedworkers/pendingapproval`
  );
  return response.data as number;
};

export const postOutsourcedWorkerServices = async (
  outsourcedServicesWorkerDto: OutsourcedServicesWorkerDto
) => {
  const response = await apiService.post(
    `/api/v1/outsourcedworkers/service`,
    outsourcedServicesWorkerDto
  );
  return response.data;
};

export const patchOutsourcedWorkerStatus = async (
  outsourcedWorkerStatusDto: OutsourcedWorkerStatusDto
) => {
  const response = await apiService.patch(
    `/api/v1/outsourcedworkers/status`,
    outsourcedWorkerStatusDto
  );
  return response.data;
};

export const patchOutsourcedWorkerBankInfo = async (
  outsourcedWorkerBankInfoDto: OutsourcedWorkerBankInfoDto,
  email: string
) => {
  const response = await apiService.patch(
    `/api/v1/outsourcedworkers/${email}/bankinfo`,
    outsourcedWorkerBankInfoDto
  );
  return response.data;
};

export const getOutsourcedWorkersAvailability = async (
  date: Dayjs,
  uf: string,
  skill: string
) => {
  const response = await apiService.get(
    `/api/v1/outsourcedworkers/${uf}/${skill}/${date.toISOString()}`
  );
  return response.data;
};

export const putOutsourcedWorkerServices = async (
  outsourcedServicesWorkerDto: OutsourcedServicesWorkerDto
) => {
  await apiService.put(
    `/api/v1/outsourcedworkers/service`,
    outsourcedServicesWorkerDto
  );
};

export const deleteOutsourcedWorker = async () => {
  await apiService.delete(`/api/v1/outsourcedworkers`);
};
