import { Service } from "@/typeDeclarations/DTOs/OutsourcedWorkerServicesDto";
import { OutsourcedWorkerServiceType } from "./enums/OutsourcedWorkerServiceType";

export const buildOutsourcedServices = (
  pickedTypeService: string,
  valueLa: string,
  valueCabista: string
) => {
  const services = new Array<Service>();
  switch (pickedTypeService) {
    case "LA": {
      if (valueLa) {
        const serviceLa = createOutsourcedServiceDto(
          OutsourcedWorkerServiceType.LA,
          valueLa
        );
        services.push(serviceLa);
      }
      break;
    }
    case "Cabista": {
      if (valueCabista) {
        const serviceCabista = createOutsourcedServiceDto(
          OutsourcedWorkerServiceType.CABISTA,
          valueCabista
        );
        services.push(serviceCabista);
      }
      break;
    }
    case "LACabista": {
      if (valueLa) {
        const serviceLa = createOutsourcedServiceDto(
          OutsourcedWorkerServiceType.LA,
          valueLa
        );
        services.push(serviceLa);
      }
      if (valueCabista) {
        const serviceCabista = createOutsourcedServiceDto(
          OutsourcedWorkerServiceType.CABISTA,
          valueCabista
        );
        services.push(serviceCabista);
      }
      break;
    }
  }

  return services;
};

const createOutsourcedServiceDto = (
  serviceType: OutsourcedWorkerServiceType,
  value: string
) => {
  const service: Service = {
    serviceType,
    value: parseFloat(value.replace("R$", "").replace(",", ".")).toFixed(2),
  };

  return service;
};
