import React from "react";
import OutsourcedWorkerAvailability from "@/typeDeclarations/Entities/OutsourcedWorkerAvailability";
import { ReactComponent as UserIcon } from "@/assets/icons/userIcon.svg";
import { ReactComponent as ChatIcon } from "@/assets/icons/chatIcon.svg";
import DataEntry from "@/components/DataEntry";
import i18n from "@/i18n";
import { getStateNameFromAbreviation } from "@/utils/ufFunctions";
import { Tooltip } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

interface OutsourcedWorkerAvailabilityCardProps {
  outsourcedWorker: OutsourcedWorkerAvailability;
  uf: string;
  toggleHour: (
    hour: string,
    outsourcedWorker: OutsourcedWorkerAvailability
  ) => void;
  selectedStartTime: string;
  selectedEndTime: string;
  disabled: boolean;
}

const OutsourcedWorkerAvailabilityCard = ({
  outsourcedWorker,
  uf,
  toggleHour,
  selectedStartTime,
  selectedEndTime,
  disabled,
}: OutsourcedWorkerAvailabilityCardProps) => {
  const isHoverDevice = useMediaQuery("(hover: none)");
  const isActiveHour = (hour: string) => {
    if (selectedEndTime) {
      return !disabled && hour >= selectedStartTime && hour <= selectedEndTime;
    } else {
      return !disabled && hour === selectedStartTime;
    }
  };

  return (
    <div
      className="sm:px-5 sm:py-3 items-center mb-8 shadow-1 rounded-2xl text-gray-700 flex flex-col sm:w-[992px]"
      id={outsourcedWorker.id.toString()}
      key={outsourcedWorker.id}
    >
      <div className="flex flex-col items-start sm:flex-row sm:border-b-[0.5px] pb-5 sm:pb-1 sm:mt-1 border-gray-300 w-full">
        <div className="ml-0.5 flex flex-row w-full sm:min-w-[45%] border-b-[0.5px] border-gray-300 sm:border-none">
          <div className="mt-4 sm:mt-0 mx-4 sm:mx-0">
            <UserIcon />
          </div>
          <span className="text-body-semibold-1 sm:ml-3 mb-2.5 mt-4 sm:mt-0">
            {outsourcedWorker.name}
          </span>
        </div>
        <div className="flex flex-row mt-5 sm:mt-0 mx-4 sm:mx-0 max-w-full sm:min-w-[55%]">
          <div className="sm:border-l-[0.5px] border-gray-300 mr-1 sm:pl-6">
            <ChatIcon
              className={`${
                outsourcedWorker.availabilityPreference
                  ? "fill-purple-700"
                  : "fill-gray-500"
              }`}
            />
          </div>
          <Tooltip
            title={outsourcedWorker.availabilityPreference}
            placement="top-start"
          >
            <p
              className={`line-clamp-2 sm:line-clamp-1 text-caption-bold-1 ${
                outsourcedWorker.availabilityPreference
                  ? "text-gray-700"
                  : "text-gray-500"
              } leading-4 sm:leading-loose`}
            >
              {outsourcedWorker?.availabilityPreference
                ? `"${outsourcedWorker?.availabilityPreference}"`
                : i18n.t("NO_AVAILABILITY_REGISTERED")}
            </p>
          </Tooltip>
        </div>
      </div>
      <div className="flex flex-col w-full px-4 sm:px-0 sm:flex-row sm:pt-3 sm:pb-1 justify-around sm:w-[992px] mx-4 sm:mx-0 mb-4 sm:mb-2 ">
        <div className="flex flex-col">
          <span className="text-body-bold-2 mt-3 sm:mt-0">
            {getStateNameFromAbreviation(uf) ?? ""}
          </span>
          <span className="text-body-regular-2 mt-2 sm:w-96">
            {`${i18n.t("CITIES")}: ${outsourcedWorker.cities} `}
          </span>
        </div>
        <div className="flex-3 flex flex-col  sm:pl-6 sm:border-l-[0.5px] border-gray-300">
          <span className="text-body-bold-2 mt-6 sm:mt-0">
            {i18n.t("TIMES")}
          </span>
          <div className="grid grid-cols-2 sm:grid-cols-7 gap-2 mt-3 sm:mr-0 sm-mt-0">
            {outsourcedWorker.availability.map((hour) => {
              const hoverStyle = isHoverDevice
                ? {
                    backgroundColor: isActiveHour(hour) ? "#E4C2FF" : "#FFFFFF",
                  }
                : {};
              return (
                <DataEntry.Button
                  label={hour.slice(0, 5)}
                  onClick={() => {
                    toggleHour(hour, outsourcedWorker);
                  }}
                  type="outlined"
                  key={`${outsourcedWorker.id}-${hour}`}
                  disabled={disabled}
                  customStyles={{
                    color: "#474747",
                    borderColor: "#B8B8B8",
                    fontWeight: 600,
                    borderRadius: 1,
                    padding: 0,
                    backgroundColor: isActiveHour(hour) ? "#E4C2FF" : "#FFFFFF",
                    "&:hover": hoverStyle,
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutsourcedWorkerAvailabilityCard;
