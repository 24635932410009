import React from "react";
import i18n from "@/i18n";

export default function DeleteOutsourcedWorkerText() {
  return (
    <main>
      <span className="text-body-regular-1 fill-current text-gray-700 mt-6 mb-4">
        {`${i18n.t("DELETE_ACCOUNT_TEXT_FIRST_PARAGRAPH")}`}
        <p className="mt-5">
          {`${i18n.t("DELETE_ACCOUNT_TEXT_SECOND_PARAGRAPH")}`}
          <a
            target={"_blank"}
            rel="noreferrer"
            className="cursor-pointer"
            style={{ textDecoration: "underline" }}
            href={`${process.env.REACT_APP_B2C_URL ?? ""}/terms-of-use`}
          >
            {`${i18n.t("DELETE_ACCOUNT_TEXT_TERM_OF_USE")}`}
          </a>
        </p>
        <p className="font-bold mt-5">
          {`${i18n.t("DELETE_ACCOUNT_TEXT_CONFIRM")}`}
        </p>
      </span>
    </main>
  );
}
