import React from "react";
import { ReactComponent as RefusalOutsourcedWorker } from "../../assets/images/refusal-outsourcedWorker.svg";
import DataEntry from "@/components/DataEntry";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import i18n from "@/i18n";
import CloseIcon from "@mui/icons-material/Close";

interface RefusalConfirmationDialogProps {
  open: boolean;
  handleAction: () => void;
  onCloseDialog: (value: boolean) => void;
}

export default function RefusalConfirmationDialog({
  open,
  handleAction,
  onCloseDialog,
}: RefusalConfirmationDialogProps) {
  const closeModal = () => {
    onCloseDialog(false);
  };

  return (
    <Dialog
      open={open}
      fullWidth
      PaperProps={{
        sx: {
          maxWidth: 940,
          maxHeight: 440,
          borderRadius: 8,
        },
      }}
      sx={{
        backdropFilter: "blur(5px)",
      }}
    >
      <DialogContent className="gap-11 pl-10 pr-10 overflow-hidden">
        <div className="flex justify-end mr-5 mt-2 smMax:mr-0 smMax:mt-0">
          <DataEntry.Button
            label={i18n.t("CLOSE")}
            onClick={closeModal}
            endIcon={
              <CloseIcon className={`fill-purple-800 text-body-regular-2`} />
            }
            disableRipple={true}
            customStyles={{ color: "#5C00A3" }}
          />
        </div>
        <div className="flex items-center justify-start mdMax:justify-center ">
          <RefusalOutsourcedWorker className="flex-initial w-1/3 mdMax:hidden" />
          <div className="flex flex-initial items-end w-3/5 flex-col gap-24 mdMax:gap-10 pt-3">
            <div className="self-end mdMax:self-center text-center">
              <label className="text-xlg font-bold overflow-auto text-gray-700 mdMax:text-base">
                {i18n.t("DO_YOU_REALLY_WANT_TO_REFUSE_PROVIDER")}
              </label>
            </div>
            <div className="self-end mdMax:self-center">
              <DataEntry.Button
                label={i18n.t("ACCEPT_ACTION")}
                onClick={handleAction}
                type="contained"
                color="primary"
                customStyles={{
                  borderColor: "#A429FF",
                  width: "164px",
                  height: "44px",
                }}
              />
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
