/* No-void foi desabilitado por termos uma promise que não 
retorna nada sendo chamada no clique de um botão */
/* eslint-disable no-void */
import React from "react";
import { ReactComponent as ForbiddenImage } from "@/assets/images/forbidden.svg";
import i18n from "@/i18n";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import Keycloak from "keycloak-js";
interface ForbiddenContentProps {
  keycloak: Keycloak;
}

export default function ForbiddenContent({ keycloak }: ForbiddenContentProps) {
  return (
    <div className="flex justify-center align-middle min-h-screen">
      <div className="flex flex-col justify-center align-middle">
        <ForbiddenImage className="self-center" />
        <span className="text-heading-bold-4 text-purple-800 text-center mt-8">
          {i18n.t("FORBIDDEN_FEEDBACK")}
        </span>
        <span className="text-heading-semibold-3 text-center mt-4">
          {i18n.t("FORBIDDEN_SUBTITLE")}
        </span>
        <Link to={"/"} className="self-center">
          <Button
            variant="contained"
            className="normal-case bg-purple-600 text-button-semibold-1 w-fit py-3 px-6 rounded-lg mt-8"
            onClick={() => void keycloak.logout()}
          >
            {i18n.t("GO_TO_START")}
          </Button>
        </Link>
      </div>
    </div>
  );
}
