export const formatPhone = (phone: string) => {
  const phoneTrimmed = phone.replace(/ /g, "");
  if (phone.length > 10)
    return (
      "(" +
      phoneTrimmed.slice(0, 2) +
      ") " +
      phoneTrimmed.slice(2, 7) +
      "-" +
      phoneTrimmed.slice(7)
    );
  return (
    "(" +
    phoneTrimmed.slice(0, 2) +
    ") " +
    phoneTrimmed.slice(2, 6) +
    "-" +
    phoneTrimmed.slice(6)
  );
};

export const formatDate = (date: Date) => {
  const dateWithoutTime = date.toString().split("T")[0];
  const splitDate = dateWithoutTime.split("-");
  const DEFAULT_LEN = 3;

  if (splitDate.length === DEFAULT_LEN) {
    const month = splitDate[1];
    const day = splitDate[2];
    const year = splitDate[0];

    return day + "/" + month.toString() + "/" + year;
  }

  return "--";
};

export const splitDateFromTime = (dateWithTime: string) => {
  const splitInput = dateWithTime.split(" ");
  return splitInput[0];
};

export const formatTime = (date: Date) => {
  const timeWithoutDate = date.toString().split("T")[1];
  const splitTime = timeWithoutDate.split(":");
  const DEFAULT_LEN = 3;

  if (splitTime.length === DEFAULT_LEN) {
    const hour = splitTime[0];
    const minute = splitTime[1];

    return hour + ":" + minute;
  }

  return "--";
};
