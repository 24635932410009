import React from "react";
import RadioButton from "@/components/RadioButton";
import i18n from "@/i18n";

interface ServiceTypeSectionProps {
  pickedTypeService: string;
  handleChange?: (e: React.ChangeEvent<any>) => void;
  disabled?: boolean;
}

const ServiceTypeSection = ({
  pickedTypeService,
  handleChange = () => {},
  disabled = false,
}: ServiceTypeSectionProps) => {
  return (
    <section className="space-y-6">
      <label className="text-base text-body-bold-1">{i18n.t("SKILLS")}</label>
      <p className="text-base">{i18n.t("SKILLS_OUTSOURCED_WORKER")}</p>
      <div className="flex flex-wrap md:space-x-20 mdMax:space-x-10">
        <RadioButton
          id="LA"
          label={i18n.t("LA")}
          value="LA"
          name="pickedTypeService"
          handleChange={handleChange}
          disabled={disabled}
          checked={pickedTypeService === "LA"}
        />
        <RadioButton
          id="Cabista"
          label={i18n.t("CABLE_MAN")}
          value="Cabista"
          name="pickedTypeService"
          handleChange={handleChange}
          disabled={disabled}
          checked={pickedTypeService === "Cabista"}
        />
        <RadioButton
          id="LACabista"
          label={i18n.t("LA_AND_CABLE_MAN")}
          value="LACabista"
          name="pickedTypeService"
          handleChange={handleChange}
          disabled={disabled}
          checked={pickedTypeService === "LACabista"}
        />
      </div>
    </section>
  );
};

export default ServiceTypeSection;
