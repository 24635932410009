import React from "react";
import i18n from "@/i18n";
import { ReactComponent as UploadSuccessImage } from "@/assets/images/fileSuccess.svg";
import DataEntry from "@/components/DataEntry";

interface ContentUploadSuccessProps {
  onClose: () => void;
}

const ContentUploadSuccess = ({ onClose }: ContentUploadSuccessProps) => {
  return (
    <div className="flex flex-col md:flex-row items-center">
      <UploadSuccessImage className="flex-1" />
      <div className="grow-1 flex flex-col mt-5 sm:mt-0 sm:ml-7 sm:ml-10 sm:w-48 ">
        <span className="text-body-regular-1 fill-current text-gray-700 text-center mb-2">
          {i18n.t("FILE_UPLOADED_SUCCESSFULLY")}
        </span>
        <DataEntry.Button
          label={i18n.t("FINISH")}
          type="contained"
          onClick={onClose}
          customStyles={{ backgroundColor: "#A429FF", marginTop: 3 }}
        />
      </div>
    </div>
  );
};

export default ContentUploadSuccess;
