import DataEntry from "@/components/DataEntry";
import i18n from "@/i18n";
import { OutsourcedServicesWorkerDto } from "@/typeDeclarations/DTOs/OutsourcedWorkerServicesDto";
import { buildOutsourcedServices } from "@/utils/outsourcedWorkerServiceFunctions";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import DocumentationSentSection from "./formComponents/DocumentationSentSection";
import ServiceTypeSection from "./formComponents/ServiceTypeSection";
import ServiceValuesSection from "./formComponents/ServiceValuesSection";
import TermAcceptanceSection from "./formComponents/TermAcceptanceSection";
import RefusalConfirmationDialog from "./RefusalConfirmationDialog";
import notifyBugsnag from "@/utils/helpers/Notification/notifyBugsnag";

interface OutsourcedPendingApprovalDetailsFormProps {
  onRefused: () => void;
  onSubmit: (outsourcedServicesWorkerDto: OutsourcedServicesWorkerDto) => {};
  isSubmitting: boolean;
}

export default function OutsourcedPendingApprovalDetailsForm({
  onSubmit,
  onRefused,
  isSubmitting,
}: OutsourcedPendingApprovalDetailsFormProps) {
  const [refusalConfirmationDialogOpen, setRefusalConfirmationDialogOpen] =
    useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const OutsourcedWorkerServicesFormsInitialValues = {
    inputLa: "",
    inputCabista: "",
    documentationSent: "",
    pickedTypeService: "",
    termsAcceptance: "",
  };

  const OutsourcedWorkerServicesFormsInitialError = {
    inputLa: "",
    inputCabista: "",
    documentationSent: "",
    pickedTypeService: "",
    termsAcceptance: "",
  };

  const schemaValidation = yup.object().shape({
    inputLa: yup.string().when("pickedTypeService", {
      is: (pickedTypeService: string) =>
        pickedTypeService === "LA" || pickedTypeService === "LACabista",
      then: yup.string().required(),
      otherwise: yup.string(),
    }),
    inputCabista: yup.string().when("pickedTypeService", {
      is: (pickedTypeService: string) =>
        pickedTypeService === "Cabista" || pickedTypeService === "LACabista",
      then: yup.string().required(),
      otherwise: yup.string(),
    }),
    documentationSent: yup.boolean().required().isTrue(),
    pickedTypeService: yup.string().required(),
    termsAcceptance: yup.boolean().required().isTrue(),
  });

  const handleOutsourcedDetailsSubmit = () => {
    const pickedServiceType = formik.values.pickedTypeService;
    const valueLa = formik.values.inputLa;
    const valueCabista = formik.values.inputCabista;
    const outsourcedServicesWorkerDto: OutsourcedServicesWorkerDto = {
      services: buildOutsourcedServices(
        pickedServiceType,
        valueLa,
        valueCabista
      ),
    };
    void onSubmit(outsourcedServicesWorkerDto);
  };

  const formik = useFormik({
    initialValues: OutsourcedWorkerServicesFormsInitialValues,
    initialErrors: OutsourcedWorkerServicesFormsInitialError,
    validationSchema: schemaValidation,
    validateOnChange: true,
    onSubmit: handleOutsourcedDetailsSubmit,
  });

  const handleChange = (e: React.ChangeEvent<any>) => {
    const pickedTypeService = e.target.value;
    switch (pickedTypeService) {
      case "LA":
        formik.setFieldValue("inputCabista", "").catch((err) => {
          notifyBugsnag(err);
        });
        break;
      case "Cabista":
        formik.setFieldValue("inputLa", "").catch((err) => {
          notifyBugsnag(err);
        });
        break;
    }
    formik.handleChange(e);
  };

  const onRefusedOutsourcedWorker = () => {
    setOpenRefusalDialog(false);
    onRefused();
  };

  const setOpenRefusalDialog = (value: boolean) => {
    setRefusalConfirmationDialogOpen(value);
  };

  const handleCheckboxOnChange = async (newValue: boolean) => {
    setIsChecked(newValue);
    await formik.setFieldValue("termsAcceptance", newValue);
  };

  return (
    <form onSubmit={formik.handleSubmit} className="space-y-16" role="form">
      <DocumentationSentSection
        documentationSent={formik.values.documentationSent}
        handleChange={formik.handleChange}
      />
      <ServiceTypeSection
        pickedTypeService={formik.values.pickedTypeService}
        handleChange={handleChange}
      />
      <ServiceValuesSection
        pickedTypeService={formik.values.pickedTypeService}
        valueCabista={formik.values.inputCabista}
        valueLa={formik.values.inputLa}
        handleChange={formik.handleChange}
      />
      <TermAcceptanceSection
        isChecked={isChecked}
        handleCheckboxOnChange={handleCheckboxOnChange}
      />
      <div className="text-button-semibold-1 flex gap-x-10 mdMax:justify-center md:w-4/5 justify-end">
        <DataEntry.LoadingButton
          id="aprovar"
          loading={isSubmitting}
          label={isSubmitting ? "" : i18n.t("APPROVE")}
          variant="contained"
          type="submit"
          customStyles={{
            fontSize: 14,
            fontWeight: "600",
            width: 166,
            height: 44,
            backgroundColor: "#29A35E",
            "&:hover": {
              backgroundColor: "#1D7242",
            },
            "&:disabled": {
              color: "#FFFFFF",
              backgroundColor: "#B8B8B8",
            },
          }}
          onClick={handleOutsourcedDetailsSubmit}
          disabled={!formik.isValid}
        />
        <DataEntry.Button
          label={i18n.t("REFUSAL")}
          id="reprovar"
          type="contained"
          onClick={() => setOpenRefusalDialog(true)}
          customStyles={{
            color: "#FFFFFF",
            fontSize: 14,
            fontWeight: "600",
            width: 164,
            height: 44,
            backgroundColor: "#E83C33",
            "&:hover": {
              backgroundColor: "#A51A12",
            },
          }}
        />
      </div>
      <RefusalConfirmationDialog
        handleAction={onRefusedOutsourcedWorker}
        onCloseDialog={setOpenRefusalDialog}
        open={refusalConfirmationDialogOpen}
      />
    </form>
  );
}
