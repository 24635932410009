import React from "react";
import InputMask from "react-input-mask";

interface MaskInputProps {
  id: string;
  value: string;
  name: string;
  maks?: string;
  placeholder: string;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any, Element>) => void;
  disabled?: boolean;
  error?: string;
}

export default function MaskInput({
  id,
  value,
  name,
  maks = "",
  handleChange,
  handleBlur,
  placeholder,
  disabled = false,
  error = "",
}: MaskInputProps) {
  return (
    <>
      <InputMask
        id={id}
        data-testid={id}
        name={name}
        onChange={handleChange}
        disabled={disabled}
        placeholder={placeholder}
        mask={maks}
        value={value}
        type="text"
        onBlur={handleBlur}
        style={{ color: "#474747" }}
        className={`border-solid  border-2 rounded-lg p-2 text-body-regular-2 focus:outline-none  ${
          error ? "border-red-500" : "border-gray-200"
        }`}
      />
      <span className="text-tooltip-2 text-red-700 m-2">{error}</span>
    </>
  );
}
