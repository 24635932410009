import React, { useState } from "react";
import DataEntry from "@/components/DataEntry";
import i18n from "@/i18n";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as ExportFileImage } from "@/assets/images/export-file.svg";
import moment from "moment";
import FileSaver from "file-saver";
import { AxiosError, AxiosResponse, HttpStatusCode } from "axios";
import { calculateDiferenceInDaysBetweenTwoDates } from "@/utils/calculateDifferenceInDaysBetweenTwoDates";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import ContentExportPending from "./ContentExportPending";
import ContentExportError from "./ContentExportError";
import ContentExportNotFound from "./ContentExportNotFound";
import ContentExportSuccess from "./ContentExportSuccess";
import ContentExportPartialSuccess from "./ContentExportPartialSuccess";
import { ClickAwayListener } from "@mui/material";
import notifyBugsnag from "@/utils/helpers/Notification/notifyBugsnag";

interface ExportModalProps {
  getOrderServicesInFile: (startDate: string, endDate: string) => Promise<any>;
  open: boolean;
  onClose: () => void;
  isMobileOpen: boolean;
}

type ExportStatusType =
  | "NOT_STARTED"
  | "SUCCESS"
  | "PARTIAL_SUCCESS"
  | "NOT_FOUND"
  | "INVALID_PERIOD"
  | "ERROR";

export function ExportModal({
  getOrderServicesInFile,
  open,
  onClose,
  isMobileOpen,
}: ExportModalProps) {
  const [isDownloading, setIsDownloading] = useState(false);
  const [dates, setDates] = useState({ startDate: "", endDate: "" });
  const [orderServiceFile, setOrderServiceFile] = useState<any>(null);
  const [exportStatus, setExportStatus] =
    useState<ExportStatusType>("NOT_STARTED");
  const MAX_DIFFERENCE_DAYS_ALLOW = 93;
  const numberOfMonths = isMobileOpen ? 1 : 2;
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const handleClose = () => {
    if (isVisible && !isDownloading) {
      setIsVisible(false);
      onClose();
      resetModal();
    } else setIsVisible(true);
  };

  const handleBlur = () => {
    const modal = document.getElementById("modal");
    if (modal && window.getComputedStyle(modal).visibility !== "hidden")
      handleClose();
  };

  const resetModal = () => {
    setExportStatus("NOT_STARTED");
  };

  const validateDates = (startDate: Date, endDate: Date): boolean => {
    const differenceInDays = calculateDiferenceInDaysBetweenTwoDates(
      startDate,
      endDate
    );
    return differenceInDays <= MAX_DIFFERENCE_DAYS_ALLOW;
  };

  const handleSubmit = async (
    startDate: moment.Moment | any,
    endDate: moment.Moment | any
  ) => {
    setIsDownloading(true);
    const areDatesValid = validateDates(startDate.toDate(), endDate.toDate());
    if (areDatesValid) {
      const startDateFormated = startDate?.format("YYYY-MM-DD");
      const endDateFormated = endDate?.format("YYYY-MM-DD");
      setDates({ startDate: startDateFormated, endDate: endDateFormated });
      setExportStatus("PARTIAL_SUCCESS");
    } else {
      setExportStatus("INVALID_PERIOD");
    }
    setIsDownloading(false);
  };

  const onGenerateSheet = () => {
    setIsDownloading(true);
    getOrderServicesInFile(dates.startDate, dates.endDate)
      .then((response: AxiosResponse) => {
        setOrderServiceFile(response.data);
        setExportStatus("SUCCESS");
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === HttpStatusCode.NotFound) {
          setExportStatus("NOT_FOUND");
        } else {
          setExportStatus("ERROR");
        }
        notifyBugsnag(error);
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  const onDownloadSheet = () => {
    const blob = new Blob([orderServiceFile], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    FileSaver.saveAs(blob, "Agendamentos.xlsx");
  };

  const renderContent = (exportStatus: ExportStatusType) => {
    switch (exportStatus) {
      case "NOT_STARTED":
      case "INVALID_PERIOD":
        return <ContentExportPending />;
      case "PARTIAL_SUCCESS":
        return (
          <ContentExportPartialSuccess onGenerateSheet={onGenerateSheet} />
        );
      case "SUCCESS":
        return <ContentExportSuccess onDownload={onDownloadSheet} />;
      case "NOT_FOUND":
        return <ContentExportNotFound onRetry={resetModal} />;
      case "ERROR":
        return <ContentExportError />;
    }
  };

  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backdropFilter: "blur(5px)",
      }}
      open={open}
    >
      <ClickAwayListener onClickAway={handleBlur}>
        <div className="absolute z-[2000] top-20">
          <div
            id="modal"
            className={`relative ${
              open ? "flex" : "hidden"
            } bg-white rounded-3xl`}
          >
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 4 }}
              open={isDownloading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <div className="w-[360px] sm:w-[880px] px-4 py-2">
              <div className="flex flex-col">
                <div className="flex grow justify-end mr-5 mt-5 mb-2 sm:mb-0">
                  <DataEntry.Button
                    label={i18n.t("CLOSE")}
                    customStyles={{
                      color: "#5C00A3",
                      fontSize: 14,
                    }}
                    onClick={handleClose}
                    endIcon={
                      <CloseIcon
                        className={`fill-purple-800 text-body-regular-2`}
                      />
                    }
                  />
                </div>
                <div className="flex flex-row rounded-3xl pb-4">
                  <div className="smMax:hidden">
                    <ExportFileImage />
                  </div>
                  <div className="flex flex-col sm:pl-10 gap-4 sm:pr-[100px]">
                    {renderContent(exportStatus)}
                    <div className="mt-2">
                      {exportStatus !== "SUCCESS" && (
                        <DataEntry.DateRangePicker
                          customContainerStyle={
                            exportStatus === "NOT_STARTED" ||
                            exportStatus === "NOT_FOUND" ||
                            exportStatus === "ERROR"
                              ? "-mt-2"
                              : "-mt-5"
                          }
                          numberOfMonths={numberOfMonths}
                          handleSubmit={handleSubmit}
                          onClear={resetModal}
                        />
                      )}
                      {exportStatus === "INVALID_PERIOD" && (
                        <span className="inline-block text-caption-regular-1 text-red-700 text-center mt-1">
                          {i18n.t("ERROR_INVALID_PERIOD_SELECTED")}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ClickAwayListener>
    </Backdrop>
  );
}
