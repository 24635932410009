import React from "react";
import { deleteOutsourcedWorker } from "@/services/outsourcedWorkerService";
import DeleteOutsourcedWorkerContent from "./DeleteOutsourcedWorkerContent";
import { useKeycloak } from "@react-keycloak/web";

export default function DeleteOutsourcedWorker() {
  const { keycloak } = useKeycloak();
  const logoutUser = () => {
    void keycloak.logout({
      redirectUri: process.env.REACT_APP_URL,
    });
  };
  return (
    <DeleteOutsourcedWorkerContent
      deleteOutsourcedWorker={deleteOutsourcedWorker}
      logoutUser={logoutUser}
    />
  );
}
