import i18n from "@/i18n";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import React, { useState } from "react";
import ConfirmationModal from "@/components/Layout/ConfirmationModal";

interface LogoutButtonProps {
  logoutUser: () => void;
}
const LogoutButton = ({ logoutUser }: LogoutButtonProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleLogoutConfirm = () => {
    logoutUser();
    setIsModalOpen(false);
  };

  return (
    <>
      <button
        className="grid grid-cols-2 gap-1"
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        <ExitToAppIcon sx={{ transform: "scaleX(-1)" }} />
        <>{i18n.t("LOGOUT")}</>
      </button>
      <ConfirmationModal
        title={i18n.t("DO_YOU_REALLY_WANT_TO_LOGOUT")}
        subtitle={i18n.t("LOGOUT_LABEL")}
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onConfirm={handleLogoutConfirm}
      />
    </>
  );
};

export default LogoutButton;
