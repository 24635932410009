import * as React from "react";
import MaterialButton from "@mui/material/Button";
import { SxProps, Theme } from "@mui/material";

interface ButtonProps {
  label: string;
  id?: string;
  onClick: () => void;
  color?: "primary" | "secondary" | "warning" | "error";
  type?: "text" | "contained" | "outlined";
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  customStyles?: SxProps<Theme>;
  disabled?: boolean;
  disableRipple?: boolean;
}

const Button = ({
  label,
  onClick,
  color = "primary",
  type = "text",
  startIcon = null,
  endIcon = null,
  customStyles = {},
  disabled = false,
  disableRipple = false,
  id = "",
}: ButtonProps) => {
  return (
    <MaterialButton
      data-testid={id}
      variant={type}
      onClick={onClick}
      color={color}
      sx={{ borderRadius: 3, padding: 1, ...customStyles }}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled}
      disableElevation
      disableFocusRipple={disableRipple}
      disableTouchRipple={disableRipple}
    >
      <span>{label}</span>
    </MaterialButton>
  );
};

export default Button;
