import React, { useEffect, useState } from "react";
import BackButton from "@/components/BackButton";
import i18n from "@/i18n";
import { OrderService } from "@/typeDeclarations/Entities/OrderService";
import { ScheduleReport } from "@/typeDeclarations/Entities/ScheduleReport";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ReportDocument from "./ReportDocument/ReportDocument";
import OrderServiceDetailsSection from "./ReportSections/OrderServiceDetailsSection";
import ReportSection from "./ReportSections/ReportSection";
import RequesterSection from "./ReportSections/RequesterSection";
import LoadingPage from "@/components/DataDisplay/LoadingPage";
import Layout from "@/components/Layout";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import DataEntry from "@/components/DataEntry";
import notifyBugsnag from "@/utils/helpers/Notification/notifyBugsnag";
interface ScheduleReportContentProps {
  orderService: OrderService | undefined;
  fetchScheduleReport: (scheduleId: number) => Promise<ScheduleReport>;
}

export default function ScheduleReportContent({
  fetchScheduleReport,
  orderService,
}: ScheduleReportContentProps) {
  const [report, setReport] = useState<ScheduleReport>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [snackbarErrorVisibility, setSnackbarErrorVisibility] =
    useState<boolean>(false);

  const getScheduleReport = async (scheduleId: number) => {
    setIsLoading(true);
    try {
      const scheduleReport = await fetchScheduleReport(scheduleId);
      if (scheduleReport) setReport(scheduleReport);
      setSnackbarErrorVisibility(false);
    } catch (error) {
      setSnackbarErrorVisibility(true);
      notifyBugsnag(error as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const getScheduleDuration = () => {
    if (report?.scheduleDurationStartTime && report?.scheduleDurationEndTime) {
      const start = new Date(
        report.scheduleDurationStartTime
      ).toLocaleTimeString("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      });
      const end = new Date(report.scheduleDurationEndTime).toLocaleTimeString(
        "pt-BR",
        { hour: "2-digit", minute: "2-digit" }
      );

      return start + " - " + end;
    }
    return "--";
  };

  useEffect(() => {
    if (orderService?.schedule?.id)
      void getScheduleReport(orderService.schedule.id);
  }, [orderService]);

  if (isLoading) return <LoadingPage />;

  return (
    <div className="p-10">
      <BackButton />
      <Layout.Alert
        severity="error"
        visibility={snackbarErrorVisibility}
        setVisibility={setSnackbarErrorVisibility}
        title={i18n.t("REPORT_NOT_FOUND")}
        subtitle={i18n.t("REPORT_NOT_FOUND_DETAILS")}
        icon={<HighlightOffRoundedIcon fontSize="inherit" />}
        style="top-24 sm:right-10"
      />
      <div className="px-5 py-10 my-8 shadow-1 rounded-2xl w-full text-gray-700 md:px-10">
        <div id="report" className="flex flex-col">
          <h1 className="text-heading-bold-5 md:text-heading-bold-4 text-purple-700 align-center text-center">
            {i18n.t("SCHEDULE_REPORT")}
          </h1>
          <button
            className="text-blue-100 text-caption-bold-1 md:text-body-bold-1 text-left my-6 ml-7"
            onClick={() =>
              window.open(process.env.REACT_APP_RSR_FORM_URL, "_blank")
            }
          >
            {i18n.t("RSR_FORM_BUTTON")}
          </button>
          <span className="text-body-bold-1 md:text-heading-bold-6 text-gray-700 ml-7">
            {i18n.t("DETAILS")}
          </span>
          <OrderServiceDetailsSection orderService={orderService} />
          <div className="mt-5">
            <span className="text-body-bold-1 md:text-heading-bold-6 text-gray-700 ml-7">
              {i18n.t("REQUESTER")}
            </span>
            <RequesterSection orderService={orderService} />
          </div>
          <span className="text-body-bold-1 md:text-heading-bold-6 text-gray-700 ml-7">
            {i18n.t("REPORT")}
          </span>
          <ReportSection
            report={report}
            getScheduleDuration={getScheduleDuration}
          />
        </div>
        {orderService && report && (
          <div className="flex justify-center md:justify-end mt-5">
            <PDFDownloadLink
              document={
                <ReportDocument
                  orderService={orderService}
                  report={report}
                  getScheduleDuration={getScheduleDuration}
                />
              }
              fileName={`RelatórioAtendimento${orderService.id}.pdf`}
            >
              <DataEntry.Button
                label={i18n.t("SHARE")}
                type="contained"
                customStyles={{
                  fontSize: 14,
                  fontWeight: "600",
                  width: 164,
                  height: 44,
                }}
                onClick={() => null}
              />
            </PDFDownloadLink>
          </div>
        )}
      </div>
    </div>
  );
}
