import React from "react";
import { ConfigProvider, DatePicker as AntDatePicker } from "antd";
import { Dayjs } from "dayjs";
import ptBR from "antd/locale/pt_BR";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CancelIcon from "@mui/icons-material/Cancel";
import i18n from "@/i18n";

interface DatePickerProps {
  id: string;
  selectedDate: Dayjs | null;
  handleDateChange: (value: Dayjs | null) => void;
  className?: string;
  disabledDatesSelector?: (date: Dayjs) => boolean;
}

ConfigProvider.config({
  theme: {
    primaryColor: "#5C00A3",
  },
});

const DatePicker = ({
  id,
  selectedDate,
  handleDateChange,
  className = "",
  disabledDatesSelector = () => false,
}: DatePickerProps) => {
  return (
    <ConfigProvider
      locale={ptBR}
      theme={{
        components: {
          DatePicker: {
            colorPrimary: "#5C00A3",
            colorText: "#474747",
            colorTextHeading: "#474747",
            fontSize: 14,
            fontFamily: "Montserrat, sans-serif",
          },
        },
      }}
    >
      <AntDatePicker
        id={id}
        format="DD/MM/YYYY"
        size="large"
        value={selectedDate}
        onChange={handleDateChange}
        placeholder={i18n.t("SELECT_DATE")}
        disabledDate={disabledDatesSelector}
        suffixIcon={<ExpandMoreIcon fontSize="medium" />}
        clearIcon={<CancelIcon fontSize="small" />}
        className={className}
      />
    </ConfigProvider>
  );
};

export default DatePicker;
