import DataEntry from "@/components/DataEntry";
import React from "react";
import i18n from "../../i18n";
import SearchIcon from "@mui/icons-material/Search";

interface FilterCardProps {
  visibility: boolean;
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  filter: string;
  onSubmit: (
    pagination: number,
    filter: string,
    clearFilter?: boolean
  ) => Promise<void>;
  isChecked: boolean;
  setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function FilterCard({
  visibility,
  setVisibility,
  setFilter,
  filter,
  onSubmit,
  isChecked,
  setIsChecked,
}: FilterCardProps) {
  const DEFAULT_PAGE = 1;
  const checkboxValue = isChecked || filter.length > 0;
  setIsChecked(checkboxValue);

  const handleCheckboxOnChange = async (newValue: boolean) => {
    setIsChecked(newValue);
    if (!newValue) {
      setFilter("");
      setVisibility(false);
      await onSubmit(DEFAULT_PAGE, "", true);
    }
  };

  const handleOnSubmit = async () => {
    await onSubmit(DEFAULT_PAGE, filter);
    setVisibility(false);
    setIsChecked(isChecked);
  };

  return (
    <div
      className={`absolute z-10 top-10 px-6 py-4 bg-white rounded-lg max-w-xs shadow-1 self-end ${
        visibility ? "" : "hidden"
      }`}
    >
      <div className="flex flex-row items-center space-x-2 mb-2">
        <DataEntry.Checkbox
          value={checkboxValue}
          onChange={() => {
            void handleCheckboxOnChange(!checkboxValue);
          }}
          checked={checkboxValue}
        />
        <span className={"text-body-bold-2 text-gray-700"}>
          {i18n.t("BA_NUMBER")}
        </span>
      </div>
      <form
        onSubmit={() => {
          void handleOnSubmit();
        }}
        className={`flex flex-row relative ${checkboxValue ? "" : "hidden"}`}
      >
        <input
          type="text"
          value={filter}
          onChange={(event) => setFilter(event.target.value)}
          name="name"
          className="border-solid border-gray-200 border-2 rounded-lg p-2 text-body-regular-2 focus:outline-none"
          placeholder={i18n.t("TYPE_NUMBER")}
        />
        <button
          className="self-center absolute z-10 right-0 mr-2"
          disabled={!filter?.length}
          type="submit"
        >
          <SearchIcon
            className={`${
              filter?.length ? "text-purple-700" : "text-gray-400"
            }`}
          />
        </button>
      </form>
    </div>
  );
}
