import React from "react";
import Layout from "@/components/Layout";
import ScheduleRequestsContent from "./ScheduleRequestsContent";
import {
  getOrderServices,
  uploadOrderServiceFile,
  getOrderServicesInFile,
} from "@/services/orderServiceService";
import { useNavigate } from "react-router-dom";
import SessionService from "@/services/sessionService";

export default function ScheduleRequestsScreen() {
  const navigate = useNavigate();
  const MOBILE_WIDTH = 600;
  const isMobileOpen = window.innerWidth <= MOBILE_WIDTH;

  const fetchOrderServices = async (
    ba: string | undefined,
    page: number,
    count: number
  ) => {
    return await getOrderServices(ba, page, count);
  };

  const getScheduleSnackbarMessage = () => {
    const snackbarMesage = SessionService.getScheduleSnackbarMessage();
    SessionService.setScheduleSnackbarMessage(null);
    return snackbarMesage;
  };

  return (
    <Layout.LateralMenu buttonPressed="requests">
      <ScheduleRequestsContent
        fetchOrderServices={fetchOrderServices}
        uploadOrderServiceFile={uploadOrderServiceFile}
        navigate={navigate}
        getScheduleSnackbarMessage={getScheduleSnackbarMessage}
        getOrderServicesInFile={getOrderServicesInFile}
        isMobileOpen={isMobileOpen}
      />
    </Layout.LateralMenu>
  );
}
