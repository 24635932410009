import { Pagination as PaginationMui, PaginationItem } from "@mui/material";
import React from "react";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
interface PaginationProps {
  totalPages: number;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}
export default function Pagination({
  totalPages,
  setPage,
  page,
}: PaginationProps) {
  return (
    <PaginationMui
      count={totalPages}
      className="self-center text-body-regular-2 md:text-body-regular-1"
      shape="rounded"
      onChange={(_, page) => setPage(page)}
      page={page}
      renderItem={(item) => (
        <PaginationItem
          components={{
            previous: KeyboardArrowLeftRoundedIcon,
            next: KeyboardArrowRightRoundedIcon,
          }}
          {...item}
          sx={{
            "&.Mui-selected": {
              backgroundColor: "#5C00A3",
              color: "white",
              fontFamily: "Montserrat",
              fontWeight: 700,
            },
            "&": {
              fontFamily: "Montserrat",
              color: "#666666",
            },
            "&:hover": {
              color: "#666666",
            },
            ".MuiPaginationItem-icon": {
              color: "#5C00A3",
              fontSize: 24,
            },
          }}
        />
      )}
    />
  );
}
