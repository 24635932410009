import React from "react";

interface InputProps {
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  label?: string;
  className?: string;
}

const TextInput = ({
  value,
  onChange,
  placeholder,
  label = "",
  className = "",
}: InputProps) => {
  return (
    <div className="flex flex-col">
      {label && (
        <span className="text-body-semibold-1 fill-current text-gray-600 mt-6 mb-4">
          {label}
        </span>
      )}
      <textarea
        value={value}
        onChange={(event) => onChange(event.target.value)}
        className={`border-solid border-gray-300 border-2 rounded-lg p-2 text-body-regular-2 focus:outline-none ${className}`}
        placeholder={placeholder}
      />
    </div>
  );
};

export default TextInput;
