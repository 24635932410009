import { OrderService } from "@/typeDeclarations/Entities/OrderService";
import React, { useState } from "react";
import i18n from "@/i18n";
import OrderServiceStatusCard from "./OrderServiceStatusCard";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import { OrderServiceStatus } from "@/utils/enums/OrderServiceStatus";
import OrderServiceDetailsSection from "../ScheduleReport/ReportSections/OrderServiceDetailsSection";
import RequesterSection from "../ScheduleReport/ReportSections/RequesterSection";

interface RequestCardProps {
  orderService: OrderService;
  navigate: (route: string) => void;
}

export default function RequestCard({
  orderService,
  navigate,
}: RequestCardProps) {
  const [expandCard, setExpandCard] = useState<boolean>(false);
  const orderServiceStatusValue = parseInt(
    OrderServiceStatus[orderService.status]
  );

  const handleScheduleButtonClick = () => {
    navigate(`/orderService/schedule`);
  };

  const handleReportButtonClick = () => {
    navigate("/orderService/report");
  };

  return (
    <div
      className="px-5 py-7 items-center mb-8 shadow-1 rounded-2xl w-full text-gray-700 md:px-10"
      id={orderService.id.toString()}
    >
      <div className="flex flex-col-reverse justify-between md:flex-row">
        <span className="text-body-bold-1 mt-4 md:mt-0 md:text-heading-bold-6">
          {orderService.skills +
            " - " +
            orderService.activity +
            " - " +
            orderService.uf}
        </span>
        <div className="flex justify-between flex-row">
          <OrderServiceStatusCard status={orderServiceStatusValue} />
          <button onClick={() => setExpandCard(!expandCard)} className="ml-4">
            {expandCard ? (
              <ExpandLessRoundedIcon className="text-purple-800" />
            ) : (
              <ExpandMoreRoundedIcon className="text-purple-800" />
            )}
          </button>
        </div>
      </div>
      {(orderServiceStatusValue === OrderServiceStatus.PENDING_SCHEDULE ||
        orderServiceStatusValue === OrderServiceStatus.PENDING_RESCHEDULE) && (
        <button
          className="text-blue-100 text-body-bold-2 md:text-body-bold-1"
          onClick={handleScheduleButtonClick}
        >
          {orderServiceStatusValue === OrderServiceStatus.PENDING_SCHEDULE
            ? i18n.t("SCHEDULE_BUTTON")
            : i18n.t("RESCHEDULE_BUTTON")}
        </button>
      )}
      {(orderServiceStatusValue === OrderServiceStatus.COMPLETED ||
        orderServiceStatusValue ===
          OrderServiceStatus.COMPLETED_UNPRODUCTIVE) && (
        <button
          className="text-blue-100 text-body-bold-2 md:text-body-bold-1"
          onClick={handleReportButtonClick}
        >
          {i18n.t("SCHEDULE_REPORT_BUTTON")}
        </button>
      )}
      <OrderServiceDetailsSection orderService={orderService} />
      {expandCard && (
        <div className="mt-5">
          <span className="text-body-bold-1 md:text-heading-bold-6 text-gray-700">
            {i18n.t("REQUESTER")}
          </span>
          <RequesterSection orderService={orderService} />
          {!!orderService.complaint && (
            <>
              <span className="text-body-bold-1 md:text-heading-bold-6 text-gray-700">
                {i18n.t("COMPLAINT")}
              </span>
              <section className="bg-gray-100 px-8 py-4 rounded-2xl">
                <span>{orderService.complaint}</span>
              </section>
            </>
          )}
        </div>
      )}
    </div>
  );
}
