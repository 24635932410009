import React from "react";

const FileIcon = ({ text }: { text: string }) => (
  <svg width={25} height={26} xmlns="http://www.w3.org/2000/svg" fill="none">
    <title>{"Layer 1"}</title>
    <path
      d="M6.734 14.925c.83 0 1.435.179 1.815.536.381.355.572.844.572 1.468 0 .281-.043.551-.128.809a1.773 1.773 0 0 1-.417.68c-.19.2-.446.358-.765.476-.32.114-.713.17-1.182.17h-.584v2.286H4.682v-6.425h2.052zm-.07 1.116h-.62v1.908h.449c.255 0 .476-.034.663-.101a.885.885 0 0 0 .435-.317.93.93 0 0 0 .154-.554c0-.316-.088-.55-.263-.703-.176-.155-.449-.233-.818-.233zm9 2.035c0 .724-.139 1.329-.417 1.815a2.626 2.626 0 0 1-1.2 1.094c-.524.244-1.156.365-1.894.365h-1.82v-6.425h2.018c.673 0 1.258.12 1.753.36.495.238.879.591 1.151 1.06.273.466.41 1.043.41 1.731zm-1.415.035c0-.474-.07-.864-.21-1.169a1.389 1.389 0 0 0-.616-.68c-.27-.147-.604-.22-1.002-.22h-.725v4.183h.584c.665 0 1.16-.177 1.481-.532.325-.354.488-.882.488-1.582zm4.087 3.24h-1.34v-6.426h3.682v1.116h-2.342v1.657h2.18v1.112h-2.18v2.54z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m14.706 1.3 8.823 7.8v12.87c0 1.508-1.382 2.73-3.088 2.73H4.56c-1.706 0-3.088-1.222-3.088-2.73V4.03c0-1.508 1.382-2.73 3.088-2.73h10.147z"
      fill="#999"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.53 9.1h-5.58c-1.706 0-3.244-1.36-3.244-2.868V1.3l8.823 7.8z"
      fill="#292929"
    />
    <text
      fontWeight="bold"
      xmlSpace="preserve"
      fontSize={6}
      y={17.917}
      x={6.852}
      strokeWidth={0}
      stroke="#000"
      fill="#fff"
      style={{
        fontFamily: "sans-serif",
      }}
    >
      {text.slice(0, 3).toUpperCase()}
    </text>
  </svg>
);

export default FileIcon;
