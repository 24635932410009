import i18n from "@/i18n";
import { OrderService } from "@/typeDeclarations/Entities/OrderService";
import {
  formatDate,
  formatPhone,
  formatTime,
  splitDateFromTime,
} from "@/utils/stringFormatFunctions";
import React from "react";

interface OrderServiceDetailsSectionProps {
  orderService: OrderService | undefined;
}
export default function OrderServiceDetailsSection({
  orderService,
}: OrderServiceDetailsSectionProps) {
  return (
    <section className="bg-gray-100 px-4 py-4 rounded-2xl mt-4 md:px-8">
      <div className="flex flex-col text-gray-700 md:flex-row">
        <div className="md:w-1/2 mb-1">
          <span className="text-caption-bold-1 md:text-body-bold-2">
            {i18n.t("BA")}
          </span>
          <span className="text-body-regular-2 md:text-body-regular-1 min-w-1/2">
            {" " + (orderService?.ba ?? "--")}
          </span>
        </div>
        <div className="md:w-1/2 mb-1">
          <span className="text-caption-bold-1 md:text-body-bold-2">
            {i18n.t("REQUEST_NUMBER")}
          </span>
          <span className="text-body-regular-2 md:text-body-regular-1">
            {" " + (orderService?.id?.toString() ?? "--")}
          </span>
        </div>
      </div>
      <div className="flex flex-col md:flex-row">
        <div className="md:w-1/2 mb-1">
          <span className="text-caption-bold-1 md:text-body-bold-2">
            {i18n.t("TERMINAL")}
          </span>
          <span className="text-body-regular-2 md:text-body-regular-1">
            {" " +
              (orderService?.terminal
                ? formatPhone(orderService.terminal)
                : "--")}
          </span>
        </div>
        <div className="md:w-1/2 mb-1">
          <span className="text-caption-bold-1 md:text-body-bold-2">
            {i18n.t("WORKER") + ": "}
          </span>
          <span className="text-body-regular-2 md:text-body-regular-1">
            {orderService?.schedule?.outsourcedWorker?.name ?? "--"}
          </span>
        </div>
      </div>
      <div className="flex flex-col md:flex-row">
        <div className="md:w-1/2 mb-1">
          <span className="text-caption-bold-1 md:text-body-bold-2">
            {i18n.t("SERVICE") + ": "}
          </span>
          <span className="text-body-regular-2 md:text-body-regular-1">
            {orderService?.service ?? "--"}
          </span>
        </div>
        <div className="md:w-1/2 mb-1">
          <span className="text-caption-bold-1 md:text-body-bold-2">
            {i18n.t("SERVICE_DATE")}
          </span>
          <span className="text-body-regular-2 md:text-body-regular-1">
            {" " +
              `${
                orderService?.schedule?.startDate
                  ? formatDate(orderService.schedule.startDate)
                  : "--"
              }`}
          </span>
        </div>
      </div>
      <div className="flex flex-col md:flex-row">
        <div className="md:w-1/2 mb-1">
          <span className="text-caption-bold-1 md:text-body-bold-2">
            {i18n.t("ORDER_SERVICE_OPENING_DATE")}
          </span>
          <span className="text-body-regular-2 md:text-body-regular-1">
            {" " +
              `${
                orderService?.dateOpeningOs
                  ? splitDateFromTime(orderService.dateOpeningOs)
                  : "--"
              }`}
          </span>
        </div>
        <div className="md:w-1/2 mb-1">
          <span className="text-caption-bold-1 md:text-body-bold-2">
            {i18n.t("SERVICE_TIME")}
          </span>
          <span className="text-body-regular-2 md:text-body-regular-1">
            {" " +
              `${
                orderService?.schedule?.startDate
                  ? formatTime(orderService.schedule.startDate)
                  : "--"
              }`}
          </span>
        </div>
      </div>
    </section>
  );
}
