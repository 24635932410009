import React from "react";
import OutsourcedWorkerListingContent from "./OutsourcedWorkerListingContent";
import {
  getCountOutsourcedWorkersPendingApproval,
  getOutsourcedWorkers,
} from "@/services/outsourcedWorkerService";
import Layout from "@/components/Layout";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

export default function OutsourcedWorkersListing() {
  const countPendingApproval = useQuery<number>(
    ["COUNT_PENDING_APPROVAL"],
    getCountOutsourcedWorkersPendingApproval
  );
  const navigate = useNavigate();

  const fetchOutsourcedWorkers = async (page: number, count: number) => {
    return await getOutsourcedWorkers(page, count);
  };

  return (
    <Layout.LateralMenu buttonPressed="outsourced">
      <OutsourcedWorkerListingContent
        getOutsourcedWorkers={fetchOutsourcedWorkers}
        countPendingApproval={countPendingApproval}
        navigate={navigate}
      />
    </Layout.LateralMenu>
  );
}
