import React from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import App from "./App";
import SessionService from "./services/sessionService";
import Keycloak from "keycloak-js";
import { ThemeProvider } from "@mui/material";
import { customTheme } from "@/styles/theme/Theme";

const AppContainer = (): JSX.Element => {
  const client = new Keycloak({
    url: process.env.REACT_APP_KEYCLOAK_AUTH_URL as string,
    realm: process.env.REACT_APP_KEYCLOAK_REALM as string,
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string,
  });

  return (
    <ReactKeycloakProvider
      authClient={client}
      initOptions={{
        pkceMethod: "S256",
        onLoad: "login-required",
        checkLoginIframe: false,
        silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
      }}
      onTokens={(tokens) => {
        SessionService.setSessionStorage(tokens.refreshToken, tokens.token);
      }}
      autoRefreshToken
    >
      <ThemeProvider theme={customTheme}>
        <App />
      </ThemeProvider>
    </ReactKeycloakProvider>
  );
};

export default AppContainer;
