import React, { useState } from "react";
import Modal from "./../Modal/index";
import i18n from "@/i18n";
import { ReactComponent as QuestionMark } from "@/assets/icons/questionMark.svg";
import { Fab } from "@mui/material";

interface ConfirmationModalProps {
  title: string;
  subtitle: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const ConfirmationModal = ({
  title,
  subtitle,
  isOpen,
  onClose,
  onConfirm,
}: ConfirmationModalProps) => {
  return (
    <Modal
      open={isOpen}
      handleClose={onClose}
      className="p-0 overflow-visible w-96"
    >
      <QuestionMark className="self-center -mt-11" />
      <div className="flex flex-col gap-6 smMax:px-1 m-0 px-10 pb-5 pt-2">
        <span className="text-lg font-semibold fill-current text-gray-800 text-center">
          {title}
        </span>
        <span className="text-base fill-current whitespace-pre-wrap text-gray-500 smMax:whitespace-normal text-center">
          {subtitle}
        </span>
      </div>
      <div className="grid grid-cols-2 gap-0">
        <button
          className="text-purple-800 text-md font-semibold border-t-2 px-3 hover:bg-gray-200 active:bg-gray-300 rounded-bl-3xl"
          onClick={onClose}
        >
          {i18n.t("NO")}
        </button>
        <button
          className="text-purple-800 text-md font-semibold border-t-2 px-3 border-l-2 hover:bg-gray-200 py-5 rounded-br-3xl"
          onClick={onConfirm}
        >
          {i18n.t("YES")}
        </button>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
