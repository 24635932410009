import i18n from "@/i18n";
import { ReactComponent as UploadWarningImage } from "@/assets/images/fileWarning.svg";
import React from "react";
import DataEntry from "@/components/DataEntry";
import ReplayIcon from "@mui/icons-material/Replay";
import FileIcon from "@/assets/icons/FileIcon";

interface ContentUploadTypeErrorProps {
  fileName: string;
  fileExtension: string;
  onRetry: () => void;
}

const ContentUploadTypeError = ({
  fileName,
  fileExtension,
  onRetry,
}: ContentUploadTypeErrorProps) => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-col md:flex-row items-center">
        <UploadWarningImage />
        <div className="flex flex-col mt-5 sm:mt-0 sm:ml-10 sm:w-48">
          <div className="flex flex-col">
            <span className="text-body-regular-1 fill-current text-gray-700 text-center">
              {i18n.t("INVALID_FILE_FORMAT")}
            </span>
            <DataEntry.Button
              label={i18n.t("TRY_AGAIN")}
              color="secondary"
              onClick={onRetry}
              endIcon={<ReplayIcon />}
              customStyles={{ marginTop: 3 }}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row border-solid border border-red-700 rounded-md p-2 mt-6">
        <FileIcon text={fileExtension} />
        <span className="inline-block text-body-regular-1 text-black text-center mt-1 ml-3 align-items: center">
          {fileName}
        </span>
      </div>
      <span className="inline-block text-caption-regular-1 text-red-700 text-center mt-1">
        {i18n.t("FILE_FORMAT_IS_NOT_SUPPORTED")}
      </span>
    </div>
  );
};

export default ContentUploadTypeError;
