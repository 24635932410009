import React from 'react';

interface BulletIconProps {
    insideColor: string;
    outsideColor: string;
}
export const BulletIcon = ({
    insideColor,
    outsideColor
}: BulletIconProps) => {
    return(
        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.71875 5.76843C0.71875 2.58261 3.30136 0 6.48718 0C9.673 0 12.2556 2.58261 12.2556 5.76843V6.23157C12.2556 9.41739 9.673 12 6.48718 12C3.30136 12 0.71875 9.41739 0.71875 6.23157V5.76843Z" fill={insideColor}/>
            <path d="M2.6416 5.84562C2.6416 3.72174 4.36334 2 6.48722 2C8.6111 2 10.3328 3.72174 10.3328 5.84562V6.15438C10.3328 8.27826 8.6111 10 6.48722 10C4.36334 10 2.6416 8.27826 2.6416 6.15438V5.84562Z" fill={outsideColor}/>
        </svg>
    );
}