import React, { useEffect, useState } from "react";
import { Snackbar } from "@mui/material";
import i18n from "@/i18n";
import BackButton from "@/components/BackButton";
import DataEntry from "@/components/DataEntry";
import dayjs, { Dayjs } from "dayjs";
import OutsourcedWorkerAvailability from "@/typeDeclarations/Entities/OutsourcedWorkerAvailability";
import OutsourcedWorkerAvailabilityCard from "./OutsourcedWorkerAvailabilityCard";
import LoadingPage from "@/components/DataDisplay/LoadingPage";
import _ from "lodash";
import { PostScheduleDto } from "@/typeDeclarations/Requests/PostScheduleDto";
import ConfirmationModal from "./ConfirmationModal";
import { OrderService } from "@/typeDeclarations/Entities/OrderService";
import { scheduleSnackbarMessage } from "@/typeDeclarations/Entities/ScheduleSnackbarMessage";
import NoOutsourcedWorkersWarning from "./NoOutsourcedWorkersWarning";
import dayjsUtcPlugin from "dayjs/plugin/utc";
import notifyBugsnag from "@/utils/helpers/Notification/notifyBugsnag";

dayjs.extend(dayjsUtcPlugin);

interface ScheduleContentProps {
  fetchOrderService: () => OrderService;
  fetchOutsourcedWorkers: (
    date: Dayjs,
    uf: string,
    serviceType: string
  ) => Promise<OutsourcedWorkerAvailability[]>;
  createSchedule: (schedule: PostScheduleDto) => Promise<void>;
  navigateToOrderServiceList: () => void;
  setScheduleSnackbarMessage: (message: scheduleSnackbarMessage) => void;
}

const ScheduleContent = ({
  fetchOrderService,
  fetchOutsourcedWorkers,
  createSchedule,
  navigateToOrderServiceList,
  setScheduleSnackbarMessage,
}: ScheduleContentProps) => {
  const [orderService, setOrderService] = useState<OrderService>();
  const [snackbarVisibility, setSnackbarVisibility] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [additionalInfo, setAdditionalInfo] = useState<string>("");
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [selectedOutsourcedWorker, setSelectedOutsourcedWorker] =
    useState<OutsourcedWorkerAvailability | null>(null);
  const [selectedStartTime, setSelectedStartTime] = useState<string>("");
  const [selectedEndTime, setSelectedEndTime] = useState<string>("");
  const [outsourcedWorkers, setOutsourcedWorkers] = useState<
    OutsourcedWorkerAvailability[]
  >([]);
  const [showCompletionModal, setShowCompletionModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const os = fetchOrderService();
    setOrderService(os);
  }, []);

  useEffect(() => {
    if (selectedDate && orderService) {
      setIsLoading(true);
      fetchOutsourcedWorkers(selectedDate, orderService.uf, orderService.skills)
        .then((res) => {
          setOutsourcedWorkers(res);
        })
        .catch((err) => {
          setSnackbarMessage(i18n.t("ERROR_FETCH_WORKERS"));
          setSnackbarVisibility(true);
          notifyBugsnag(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [selectedDate]);

  const toggleHour = (
    hour: string,
    outsourcedWorker: OutsourcedWorkerAvailability
  ) => {
    if (!selectedOutsourcedWorker) {
      setSelectedOutsourcedWorker(outsourcedWorker);
    }
    if (!selectedStartTime) {
      setSelectedStartTime(hour);
    } else if (!selectedEndTime && hour > selectedStartTime) {
      setSelectedEndTime(hour);
    } else if (!selectedEndTime && hour < selectedStartTime) {
      setSelectedEndTime(selectedStartTime);
      setSelectedStartTime(hour);
    } else if (hour === selectedStartTime && !selectedEndTime) {
      setSelectedStartTime("");
      setSelectedOutsourcedWorker(null);
    } else if (hour === selectedStartTime && !!selectedEndTime) {
      setSelectedStartTime(selectedEndTime);
      setSelectedEndTime("");
    } else if (hour === selectedEndTime) {
      setSelectedEndTime("");
    }
  };

  const onSubmit = () => {
    if (
      orderService &&
      selectedOutsourcedWorker &&
      selectedDate &&
      selectedStartTime
    ) {
      const startDateToSet = new Date(
        selectedDate
          .subtract(selectedDate.get("minute"), "minute")
          .subtract(selectedDate.get("second"), "second")
          .subtract(selectedDate.get("millisecond"), "millisecond")
          .set("hour", +selectedStartTime.split(":")[0])
          .utc(true)
          .format()
      );

      const endDateToSet = selectedEndTime
        ? new Date(
            selectedDate
              .subtract(selectedDate.get("minute"), "minute")
              .subtract(selectedDate.get("second"), "second")
              .subtract(selectedDate.get("millisecond"), "millisecond")
              .set("hour", +selectedEndTime.split(":")[0])
              .utc(true)
              .format()
          )
        : null;

      const request: PostScheduleDto = {
        userId: selectedOutsourcedWorker.id,
        orderServiceId: orderService.id,
        startDate: startDateToSet,
        endDate: endDateToSet,
        description: additionalInfo,
      };
      setIsLoading(true);
      createSchedule(request)
        .then(() => {
          setScheduleSnackbarMessage({
            title: i18n.t("REQUEST_CREATED"),
            message: i18n.t("WE_HAVE_SENT_YOUR_REQUEST"),
          });
          navigateToOrderServiceList();
        })
        .catch((error) => {
          setSnackbarMessage(i18n.t("ERROR_CREATE_SCHEDULE"));
          setSnackbarVisibility(true);
          notifyBugsnag(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const addOneHourToStartingTime = () => {
    const statingTimeAsNumber = +selectedStartTime.split(":")[0];
    return `${(statingTimeAsNumber + 1).toLocaleString("pt-BR", {
      minimumIntegerDigits: 2,
    })}:00`;
  };

  if (isLoading || !orderService) return <LoadingPage />;

  const noOutsourcedWorkers =
    !outsourcedWorkers || outsourcedWorkers.length === 0;
  return (
    <div className="sm:px-8">
      <div className="flex flex-col items-start">
        <BackButton />
        <span className="text-heading-semibold-4 fill-current text-gray-800 mt-6">
          {i18n.t("SCHEDULE")}
        </span>
        <DataEntry.TextInput
          onChange={setAdditionalInfo}
          placeholder={i18n.t("EXAMPLE_ADDITIONAL_DETAILS")}
          value={additionalInfo}
          label={i18n.t("IF_NECESSARY_ADD_DETAILS_TO_THE_REQUEST")}
          className="h-24 sm:w-[993px]"
        />
        <span className="text-heading-bold-6 fill-current text-gray-700 sm:w-[993px] mt-8 sm:truncate">
          {orderService?.address}
        </span>
        <span className="text-body-semibold-1 fill-current text-gray-600 mt-8 mb-2">
          {i18n.t("SELECT_DATE_TIME_AND_WORKER")}
        </span>
        <DataEntry.DatePicker
          id="DatePicker"
          handleDateChange={(date) => {
            setSelectedOutsourcedWorker(null);
            setSelectedStartTime("");
            setSelectedEndTime("");
            setSelectedDate(date);
          }}
          selectedDate={selectedDate}
          disabledDatesSelector={(date: Dayjs) =>
            date && date.startOf("date") < dayjs().startOf("date")
          }
          className="font-sans w-full sm:w-[280px] mb-6 focus-within:border-red-600 hover:border-red-600"
        />
        <div className="flex flex-col mt-5">
          {!!outsourcedWorkers &&
            outsourcedWorkers.map((outsourcedWorker) => {
              return (
                <OutsourcedWorkerAvailabilityCard
                  disabled={
                    !!selectedOutsourcedWorker &&
                    selectedOutsourcedWorker.id !== outsourcedWorker.id
                  }
                  outsourcedWorker={outsourcedWorker}
                  uf={orderService.uf}
                  toggleHour={toggleHour}
                  selectedStartTime={selectedStartTime}
                  selectedEndTime={selectedEndTime}
                  key={outsourcedWorker.id}
                />
              );
            })}
          {selectedDate && noOutsourcedWorkers && (
            <NoOutsourcedWorkersWarning />
          )}
        </div>
        <div className="flex flex-row justify-end w-full sm:w-[993px]">
          <DataEntry.Button
            label={i18n.t("FINISH")}
            type="contained"
            disabled={selectedStartTime === ""}
            onClick={() => {
              setShowCompletionModal(true);
            }}
            customStyles={{ width: 164 }}
          />
        </div>
      </div>
      <ConfirmationModal
        onClose={() => setShowCompletionModal(false)}
        onConfirm={onSubmit}
        open={showCompletionModal}
        date={selectedDate?.format("DD/MM/YYYY") ?? ""}
        outsourcedWorker={selectedOutsourcedWorker?.name ?? ""}
        service={orderService.activity}
        time={i18n.t("TIMESPAN", {
          startTime: selectedStartTime.slice(0, 5),
          endTime: !selectedEndTime
            ? addOneHourToStartingTime()
            : selectedEndTime.slice(0, 5),
        })}
      />
      <Snackbar
        open={snackbarVisibility}
        autoHideDuration={6000}
        onClose={() => setSnackbarVisibility(false)}
        message={snackbarMessage}
      />
    </div>
  );
};

export default ScheduleContent;
