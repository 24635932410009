import Layout from "@/components/Layout";
import i18n from "@/i18n";
import React, { useState } from "react";
import DataEntry from "@/components/DataEntry";
import CloseIcon from "@mui/icons-material/Close";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ContentPendingUpload from "./ContentPendingUpload";
import ContentUploadSuccess from "./ContentUploadSuccess";
import ContentUploadTypeError from "./ContentUploadTypeError";
import ContentUploadError from "./ContentUploadError";
import ContentUploadPartialSuccess from "./ContentUploadPartialSuccess";
import notifyBugsnag from "@/utils/helpers/Notification/notifyBugsnag";

const SPREADSHEET_FORMAT =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

interface UploadModalProps {
  open: boolean;
  onClose: () => void;
  onSelectFile: (file: File) => Promise<any>;
  refreshList: () => void;
}

type UploadStatusType =
  | "NOT_STARTED"
  | "SUCCESS"
  | "PARTIAL_SUCCESS"
  | "ERROR"
  | "TYPE_ERROR";

const UploadModal = ({
  open,
  onClose,
  onSelectFile,
  refreshList,
}: UploadModalProps) => {
  const [fileName, setFileName] = useState("");
  const [fileExtension, setFileExtension] = useState("");
  const [uploadErrors, setUploadErrors] = useState<any>(null);
  const [uploadStatus, setUploadStatus] =
    useState<UploadStatusType>("NOT_STARTED");
  const [isUploading, setIsUploading] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target?.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      setFileName(selectedFile.name);
      const fileExtension = selectedFile.name.split(".").at(-1) ?? "";
      setFileExtension(fileExtension);
      if (selectedFile.type !== SPREADSHEET_FORMAT) {
        setUploadStatus("TYPE_ERROR");
      } else {
        setIsUploading(true);
        onSelectFile(selectedFile)
          .then((res: any) => {
            if (res.status === 200) {
              setUploadErrors(res.data);
              setUploadStatus("PARTIAL_SUCCESS");
            } else {
              setUploadStatus("SUCCESS");
            }
          })
          .catch((error) => {
            setUploadStatus("ERROR");
            notifyBugsnag(error);
          })
          .finally(() => {
            setIsUploading(false);
          });
      }
    }
  };

  const resetState = () => {
    setFileName("");
    setFileExtension("");
    setUploadErrors("");
    setUploadStatus("NOT_STARTED");
  };

  const handleClose = () => {
    if (!isUploading) {
      resetState();
      onClose();
    }
  };

  const handleFinish = () => {
    refreshList();
    handleClose();
  };

  const renderContent = (status: UploadStatusType) => {
    if (status === "NOT_STARTED")
      return <ContentPendingUpload changeHandler={handleChange} />;
    if (status === "SUCCESS")
      return <ContentUploadSuccess onClose={handleFinish} />;
    if (status === "TYPE_ERROR")
      return (
        <ContentUploadTypeError
          fileExtension={fileExtension}
          fileName={fileName}
          onRetry={resetState}
        />
      );
    if (status === "PARTIAL_SUCCESS")
      return (
        <ContentUploadPartialSuccess
          fileExtension={fileExtension}
          fileName={fileName}
          uploadErrors={uploadErrors}
        />
      );
    if (status === "ERROR")
      return (
        <ContentUploadError
          fileExtension={fileExtension}
          fileName={fileName}
          onRetry={resetState}
        />
      );
  };

  return (
    <Layout.Modal open={open} handleClose={handleClose}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isUploading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="smMax:flex sm:hidden grow justify-end mr-5 mt-2 ">
        <DataEntry.Button
          label={i18n.t("CLOSE")}
          customStyles={{
            color: "#5C00A3",
            fontSize: 14,
          }}
          onClick={handleClose}
          endIcon={
            <CloseIcon className={`fill-purple-800 text-body-regular-2`} />
          }
        />
      </div>
      <div className="flex flex-col w-[360px] sm:w-[880px] px-4 py-2">
        <div className="flex grow justify-between items-center mb-5">
          <span className="text-heading-bold-6 fill-current text-gray-800">
            {i18n.t("UPLOAD_SPREADSHEET")}
          </span>
          <div className="smMax:hidden sm:flex">
            <DataEntry.Button
              label={i18n.t("CLOSE")}
              customStyles={{ color: "#5C00A3" }}
              onClick={handleClose}
              endIcon={
                <CloseIcon className={`fill-purple-800 text-body-regular-2`} />
              }
            />
          </div>
        </div>
        <div className="flex flex-col bg-gray-100 rounded-3xl px-5 py-6 sm:px-10 sm:py-12 items-center justify-center h-96 sm:h-80">
          {renderContent(uploadStatus)}
        </div>
      </div>
    </Layout.Modal>
  );
};

export default UploadModal;
