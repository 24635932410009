import Layout from "@/components/Layout";
import { getOutsourcedWorkersAvailability } from "@/services/outsourcedWorkerService";
import { postSchedule } from "@/services/scheduleService";
import sessionService from "@/services/sessionService";
import OutsourcedWorkerAvailability from "@/typeDeclarations/Entities/OutsourcedWorkerAvailability";
import { Dayjs } from "dayjs";
import React from "react";
import { useNavigate } from "react-router-dom";
import ScheduleContent from "./ScheduleContent";

const Schedule = () => {
  const navigate = useNavigate();

  const fetchOrderService = () => {
    const selectedOrderService = sessionService.getSelectedOrderService();
    return selectedOrderService;
  };

  const fetchOutsourcedWorkersAvailability = async (
    date: Dayjs,
    uf: string,
    serviceType: string
  ) => {
    const res = await getOutsourcedWorkersAvailability(date, uf, serviceType);
    return res as OutsourcedWorkerAvailability[];
  };

  return (
    <Layout.LateralMenu buttonPressed="requests">
      <ScheduleContent
        fetchOrderService={fetchOrderService}
        fetchOutsourcedWorkers={fetchOutsourcedWorkersAvailability}
        createSchedule={postSchedule}
        navigateToOrderServiceList={() => navigate("/orderServices")}
        setScheduleSnackbarMessage={sessionService.setScheduleSnackbarMessage}
      />
    </Layout.LateralMenu>
  );
};

export default Schedule;
