import React from "react";
import i18n from "../../i18n";
import { ReactComponent as EmptyFile } from "../../assets/images/empty-file-02.svg";
export default function EmptyScheduleList() {
  return (
    <div className="flex flex-col items-center mt-12">
      <div className="mb-6">
        <EmptyFile />
      </div>
      <span className="text-body-semibold-3 text-gray-600 max-w-sm text-center">
        {i18n.t("NO_SCHEDULES_REQUESTS")}
      </span>
      <span className="text-body-regular-2 text-gray-600 max-w-xs text-center mt-2">
        {i18n.t("SCHEDULE_INFO")}
      </span>
    </div>
  );
}
