import React from "react";
import Checkbox from "./Checkbox";
import Button from "./Button";
import TextInput from "./TextInput";
import DatePicker from "./DatePicker";
import DateRangePicker from "./DateRangePicker";
import MaterialLoadingButton from "./LoadingButton";

const DataEntry = ({ children }: { children: JSX.Element }): JSX.Element => (
  <>{children}</>
);

DataEntry.Button = Button;
DataEntry.Checkbox = Checkbox;
DataEntry.DatePicker = DatePicker;
DataEntry.DateRangePicker = DateRangePicker;
DataEntry.TextInput = TextInput;
DataEntry.LoadingButton = MaterialLoadingButton;

export default DataEntry;
