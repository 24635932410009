import DataEntry from "@/components/DataEntry";
import i18n from "@/i18n";
import React from "react";

interface TermAcceptanceSectionProps {
  isChecked: boolean;
  handleCheckboxOnChange?: (newValue: boolean) => Promise<void>;
  disabled?: boolean;
}

const TermAcceptanceSection = ({
  isChecked,
  handleCheckboxOnChange = async () => {
    await Promise.resolve();
  },
  disabled = false,
}: TermAcceptanceSectionProps) => {
  return (
    <div className="flex space-x-3 w-[90%] smMax:w-full items-center ">
      <DataEntry.Checkbox
        id="termsAcceptance"
        name="termsAcceptance"
        value={isChecked}
        onChange={() => {
          void handleCheckboxOnChange(!isChecked);
        }}
        checked={isChecked}
        disabled={disabled}
      />
      <p className="text-sm">{i18n.t("TERMS_ACCEPTANCE")}</p>
    </div>
  );
};

export default TermAcceptanceSection;
