import React, { ReactElement } from "react";
import LateralMenuContent from "./lateralMenuContent";
import { useKeycloak } from "@react-keycloak/web";

interface LateralMenuProps {
  children: ReactElement;
  buttonPressed: "outsourced" | "requests";
}

export default function LateralMenu({
  children,
  buttonPressed,
}: LateralMenuProps) {
  const { keycloak } = useKeycloak();
  const logoutUser = () => {
    void keycloak.logout({
      redirectUri: process.env.REACT_APP_URL,
    });
  };
  return (
    <LateralMenuContent buttonPressed={buttonPressed} logoutUser={logoutUser}>
      {children}
    </LateralMenuContent>
  );
}
