import React, { useState, useEffect } from "react";
import { OutsourcedWorker } from "../../typeDeclarations/Entities/OutsourcedWorker";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { OutsourcedWorkerStatus } from "../../utils/enums/OutsourcedWorkerStatus";
import { BulletIcon } from "../../assets/icons/bulletIcon";
import i18n from "../../i18n";
import { NavigateFunction } from "react-router-dom";
import SessionService from "@/services/sessionService";

interface OutsourcedWorkerCardProps {
  outsourcedWorker: OutsourcedWorker;
  navigate: NavigateFunction;
}
interface OutsourcedVisualAttributes {
  title?: string;
  iconFirstColor?: string;
  iconSecondColor?: string;
  textColor?: string;
}
export default function OutsourcedWorkerCard({
  outsourcedWorker,
  navigate,
}: OutsourcedWorkerCardProps) {
  const [visualAttributes, setVisualAttributes] =
    useState<OutsourcedVisualAttributes>({});

  const setOutsourcedVisualAttributes = () => {
    switch (outsourcedWorker.status) {
      case OutsourcedWorkerStatus.PENDING_APPROVAL:
        setVisualAttributes({
          title: i18n.t("PENDING"),
          iconFirstColor: "#FFF3C2",
          iconSecondColor: "#E0B400",
          textColor: "text-yellow-700",
        });
        break;
      case OutsourcedWorkerStatus.ACTIVE:
        setVisualAttributes({
          title: i18n.t("ACTIVE"),
          iconFirstColor: "#7CDEA7",
          iconSecondColor: "#1D7242",
          textColor: "text-green-800",
        });
        break;
      case OutsourcedWorkerStatus.REFUSED:
        setVisualAttributes({
          title: i18n.t("REFUSED"),
          iconFirstColor: "#F7B9B6",
          iconSecondColor: "#CA1F16",
          textColor: "text-red-700",
        });
        break;
      default:
        setVisualAttributes({});
    }
  };

  useEffect(() => {
    setOutsourcedVisualAttributes();
  }, [outsourcedWorker]);

  const navigateToOutsourcedWorkerDetails = () => {
    SessionService.setOutsourcedWorkerSelected(outsourcedWorker);
    navigate("/outsourcedWorkerDetails");
  };

  const outsourcedWorkerNameText = outsourcedWorker.address
    ? `${outsourcedWorker.name} - ${outsourcedWorker.address.state}`
    : `${outsourcedWorker.name}`;

  return (
    <button
      className="px-4 py-3 flex items-center mb-8 shadow-1 rounded-lg w-full"
      onClick={navigateToOutsourcedWorkerDetails}
      type="button"
      id={outsourcedWorker.id.toString()}
      key={outsourcedWorker.id.toString()}
    >
      <div className="flex flex-row justify-between items-center w-full">
        <div className="flex flex-col">
          <span className="body-semibold-1 text-left break-words">
            {outsourcedWorkerNameText}
          </span>
          <div className="flex flex-row items-baseline mt-2">
            <BulletIcon
              insideColor={visualAttributes.iconFirstColor ?? ""}
              outsideColor={visualAttributes.iconSecondColor ?? ""}
            />
            <span
              className={`text-body-regular-2 text-left ml-2 break-words ${
                visualAttributes.textColor ?? ""
              }`}
            >
              {visualAttributes.title}
            </span>
          </div>
        </div>
        <ArrowForwardIosRoundedIcon className="text-gray-500 text-heading-regular-6" />
      </div>
    </button>
  );
}
