import React from "react";
import { ReactComponent as NoOutsourcedWorkersImage } from "@/assets/images/noOutsourcedWorkersImages.svg";
import i18n from "@/i18n";

const NoOutsourcedWorkersWarning = () => {
  return (
    <div className="flex flex-col items-center sm:w-[993px]">
      <NoOutsourcedWorkersImage className="hidden sm:block" />
      <span className="text-body-semibold-3 fill-current text-gray-800 sm:w-[368px] text-center">
        {i18n.t("THERE_ARE_NO_WORKERS_IN_REGION")}
      </span>
      <span className="text-body-regular-2 fill-current text-gray-800 sm:w-[324px] text-center mt-4 mb-12">
        {i18n.t("WHEN_AVAILABLE_WE_WILL_UPDATE_THE_LISTING")}
      </span>
    </div>
  );
};

export default NoOutsourcedWorkersWarning;
