const language = {
  ACCEPT_ACTION: "Sim, quero!",
  ACCOUNT: "Conta",
  ACTIVE: "Ativo",
  ACTIVITY: "Atividade:",
  ADDRESS: "Endereço:",
  ADMINISTRATION: "Administração",
  AGENCY: "Agência",
  ALPHABETICAL_ORDER: "Ordem alfabética",
  APPROVE: "Aprovar",
  APPROVED: "Aprovado",
  APPLY: "Aplicar",
  ATTENTION_PENDING_APPROVAL: {
    one: "ATENÇÃO: Você tem {{count}} terceirizado aguardando aprovação.",
    other: "ATENÇÃO: Você tem {{count}} terceirizados aguardando aprovação.",
  },
  AWAITING_ACCEPTANCE: "Aguardando aceite",
  BA: "Ordem de serviço - BA:",
  BA_NUMBER: "Número BA",
  BANK: "Banco",
  BANK_DATA: "Dados bancários",
  BANK_DATA_CHANGED: "Dados bancários alterados",
  CABLE_MAN: "Cabista",
  CELLPHONE: "Celular:",
  CHANGE_SAVED_SUCCESSFULLY: "Alteração salva com sucesso",
  CITIES: "Cidades",
  CLEAR: "Limpar",
  CLIENT_WASNT_AT_HOME: "O cliente não estava em casa",
  CLOSE: "Fechar",
  COMPLAINT: "Reclamação",
  COMPLETED: "Concluído",
  COMPLETED_UNPRODUCTIVE: "Concluído improdutivo",
  CONFIRM_DELETE_ACCOUNT: "Deseja confirmar a exclusão de sua conta?",
  CPF_INVALID: "Cpf inválido",
  DATA_WAS_NOT_CHANGED: "Dados não alterados",
  DATE: "Data",
  DATE_FORMAT_PLACEHOLDER: "XX/XX/XXXX",
  DELETE: "EXCLUIR",
  DELETE_ORDER_SERVICES_CONFIRMED:
    "Deseja realmente excluir essas solicitações?",
  DELETE_ORDER_SERVICES_LABEL:
    "Ao confirmar você estará excluindo permanentemente e de TODAS as páginas, solicitações que se encontram com o status PENDENTE DE AGENDAMENTO.",
  DELETE_ORDER_SERVICES_WARNING:
    "ATENÇÃO: Essas solicitações não poderão ser importadas novamente, uma vez que o sistema às reconhecerá como deletadas. Essa ação NÃO poderá ser desfeita.",
  DELETE_ACCOUNT_TEXT_CONFIRM:
    "Tem certeza de que deseja prosseguir com a exclusão?",
  DELETE_ACCOUNT_TEXT_FIRST_PARAGRAPH:
    "ATENÇÃO: A exclusão da sua conta é permanente e não pode ser desfeita. Isso significa que todos os seus dados, informações e histórico serão apagados permanentemente do nosso sistema, exceto aqueles que poderão ser requeridos à Telemont para auditorias, questões fiscais e jurídicas.",
  DELETE_ACCOUNT_TEXT_SECOND_PARAGRAPH: "Para saber mais, acesse o ",
  DELETE_ACCOUNT_TEXT_TERM_OF_USE: "termo de uso e política de privacidade.",
  DETAILS: "Detalhes",
  DID_YOU_KNOW_REQUESTS_ARE_ORDERED:
    "Você sabia que as solicitações são ordenadas por status?",
  DIFFERENT_PROBLEM: "O problema era diferente do descrito",
  DOCUMENTATION_VERIFIED:
    "A documentação enviada já foi verificada e está de acordo com as necessidades da plataforma?",
  DOCUMENTS: "Documentos",
  DOWNLOAD_SHEET: "Baixar planilha",
  DO_YOU_REALLY_WANT_RO_DISAPPROVE: "Deseja realmente reprovar",
  DOWNLOAD_SPREADSHEET: "Baixar planilha",
  DO_YOU_REALLY_WANT_TO_REFUSE_PROVIDER:
    "Deseja realmente reprovar esse terceirizado?",
  DO_YOU_REALLY_WANT_TO_LOGOUT: "Deseja realmente sair da sua conta?",
  DO_YOU_REALLY_WISH_TO_CHANGE_VALUES:
    "Deseja realmente alterar o valor pago a esse terceirizado?",
  DO_YOU_REALLY_WISH_TO_SCHEDULE: "Deseja realmente realizar esse agendamento?",
  EDIT_DATA: "Editar dados",
  END_DATE: "Data Final",
  ERROR_APPROVE_WORKERS_SERVICES: "Não foi possível aprovar o terceirizado.",
  ERROR_CHANGE_BANK_INFO: "Não foi possivel alterar os dados bancários",
  ERROR_CREATE_SCHEDULE:
    "Não foi possivel agendar o atendimento. Por favor, tente novamente.",
  ERROR_DELETE_OUTSOURCEDWORKER:
    "Verifique se há algum atendimento pendente e tente novamente mais tarde.",
  ERROR_EXPORT_ORDER_SERVICES:
    "Desculpe, não foi possível exportar as solicitações de atendimento. Tente novamente mais tarde.",
  ERROR_FETCH_DELETE_ORDER_SERVICES:
    "Ocorreu um erro ao excluir as solicitações pendentes de agendamento. Tente novamente.",
  ERROR_FETCH_PENDING_WORKERS:
    "Não foi possível obter quantos terceirizados estão com o cadastro pendente de aprovação.",
  ERROR_FETCH_WORKERS: "Não foi possível obter os terceirizados de serviços.",
  ERROR_FETCH_REQUESTS:
    "Ocorreu um erro ao carregar as solicitações. Tente novamente.",
  ERROR_FETCH_OUTSOURCEDWORKER:
    "Não foi possível obter os dados do terceirizado de serviços",
  ERROR_INVALID_PERIOD_SELECTED:
    "O limite máximo é um período de três meses. Tente novamente.",
  ERROR_OCCURED: "Ops, ocorreu um erro",
  ERROR_ON_LOAD: "Não foi possível carregar.",
  ERROR_REFUSED_WORKERS_SERVICES: "Não foi possível reprovar o terceirizado.",
  ERROR_UPDATE_OUTSOURCED_WORKER_SERVICE_VALUES:
    "Não foi possível salvar as alterações. Verifique os valores e tente novamente.",
  EXAMPLE_ADDITIONAL_DETAILS:
    "Exemplo: Dados não encontrados na planilha do Click como; EQN, DSLAM, ARMÁRIO, CONT PRIMÁRIA, CONT SECUNDÁRIA",
  EXPLANATION_OF_ORDER_SERVICE_DISPLAY:
    "Primeiro você visualiza todas as solicitações com o status <b>reagendar</b>, em seguida <b>pendentes de agendamento</b>, <b>aguardando aceite</b>, <b>pendente de execução</b>, <b>concluído</b> e <b>concluído improdutivo</b>",
  EXPORT: "EXPORTAR",
  FIELD_REQUIRED: "Este campo é obrigatório",
  FILE_FORMAT_IS_NOT_SUPPORTED:
    "O formato do arquivo não é suportado. Tente novamente.",
  FILE_NOT_UPLOADED_DOWNLOAD_SPREADSHEET:
    "Arquivo não importado, faça o download e verifique os erros.",
  FILE_NOT_UPLOADED_TRY_AGAIN:
    "Arquivo não importado. Por favor, verifique a planilha e tente novamente.",
  FILE_UPLOADED_SUCCESSFULLY: "Arquivo importado com sucesso",
  FILTER: "Filtrar",
  FINISH: "Concluir",
  FORBIDDEN_FEEDBACK: "Oops! Você não tem permissão",
  FORBIDDEN_SUBTITLE: "Parece que você não tem permissão para entrar aqui.",
  FORM_SKILLS_AND_DOCUMENTATION:
    " Acessar formulário com teste e documentação >",
  GENERATE_SHEET: "Gerar planilha",
  GO_BACK: "Voltar",
  GO_TO_START: "Voltar para o início",
  IF_NECESSARY_ADD_DETAILS_TO_THE_REQUEST:
    "Se necessário, detalhe um pouco mais sobre sua solicitação com informaçoēs importantes para os técnicos",
  IN_THIS_CATEGORY_THE_VALUE_IS_BY_DAY:
    "Obs: Nessa modalidade o valor vinculado é pago por diária.",
  IN_THIS_CATEGORY_THE_VALUE_IS_BY_SERVICE:
    "Obs: Nessa modalidade o valor vinculado é pago por atendimento.",
  INVALID_FILE_FORMAT: "Arquivo importado em formato inválido",
  IMPORT: "IMPORTAR",
  LA: "LA",
  LA_AND_CABLE_MAN: "LA e Cabista",
  LOGOUT: "Sair",
  LOGOUT_LABEL: "Ao retornar será necessário logar novamente.",
  NAME: "Nome:",
  NO: "Não",
  NO_AVAILABILITY_REGISTERED:
    "Esse terceirizado não possui preferências cadastradas no momento",
  NO_OUTSOURCED_WORKERS_WAITING_APPROVAL:
    "Ainda não há terceirizados aguardando aprovação",
  NO_RESULT_FOUND: "Nenhum resultado encontrado",
  NO_SCHEDULES_REQUESTS: "Ainda não há nenhuma solicitação",
  ONLY_NUMBERS: "Apenas números",
  OTHER: "Outro(s)",
  OUTSOURCED_WORKERS: "Terceirizados",
  ORDER_SERVICE_OPENING_DATE: "Data abertura OS:",
  ORDER_SERVICES_FILE_GENERATED_SUCCESS: "Arquivo gerado com sucesso!",
  ORDER_SERVICES_NOT_FOUND:
    "Nenhum resultado encontrado. Verifique as datas e tente novamente.",
  PENDING: "Pendente de aprovação",
  PENDING_APPROVAL: "Aprovação pendente",
  PENDING_EXECUTION: "Pendente de execução",
  PENDING_SCHEDULE: "Pendente de agendamento",
  PERIOD_SELECTED: "Período selecionado",
  PRICE: "Preço",
  PRICE_OUTSOURCED_WORKER:
    "Após a negociação com o profissional, vincule um preço aos serviços que serão prestados:",
  PRICES_NOT_SAVED_BECAUSE_NO_CHANGE:
    "Os dados não foram alterados devido ao valor informado ser o mesmo que o anterior.",
  PROBLEM_SOLVED: "O problema foi resolvido?",
  RECIDIVISM: "Reincidência:",
  REFUSAL: "Reprovar",
  REFUSED: "Reprovado",
  REMEMBER_THE_SYSTEM_HAS_NO_HISTORY:
    "Lembre-se: o sistema não possui histórico, portanto é importante exportar a planilha antes de realizar essa alteração, pois o novo valor irá se aplicar a todos os atendimentos em aberto.",
  REPORT: "Relatório",
  REQUEST_CREATED: "Solicitação concluída",
  REQUEST_NUMBER: "Nº Solicitação:",
  REQUESTS: "Solicitações",
  REQUESTER: "Solicitante",
  RESCHEDULE_BUTTON: "Reagendar >",
  SAVE: "Salvar",
  SCHEDULE: "Agendar",
  SCHEDULE_BUTTON: "Agendar >",
  SCHEDULE_DURATION: "Quanto tempo durou o atendimento?",
  SCHEDULE_HAPPENED: "O atendimento aconteceu?",
  SCHEDULE_INFO: "Após a importação, as solicitações serão listadas aqui",
  SCHEDULE_REPORT: "Relatório de atendimento",
  SCHEDULE_REPORT_BUTTON: "Relatório de atendimento >",
  SCHEDULE_REQUESTS: "Solicitações de agendamento",
  SELECT_DATE: "Selecione uma data",
  SELECT_DATE_TIME_AND_WORKER: "Selecione data, horário e terceirizado.",
  SELECT_FILE: "Selecionar arquivo",
  SELECT_PERIOD: "Selecione o período que deseja exportar a planilha",
  SERVICE: "Serviço",
  SERVICE_DATE: "Data serviço:",
  SERVICE_TIME: "Hora serviço:",
  SHARE: "Compartilhar",
  SKILLS: "Habilidades",
  SKILLS_OUTSOURCED_WORKER:
    "De acordo com o teste de habilidades o profissional poderá atuar em qual serviço?",
  SOLICITATION_INFO: "Após o cadastro, as solicitações serão listadas aqui ",
  SORT: "Ordenar",
  START_ACTIVE_STATUS: "Início: Status Ativo",
  START_DATE: "Data Inicial",
  START_PENDING_STATUS: "Início: Status Pendente",
  START_REFUSED_STATUS: "Início: Status Reprovado",
  STOCK_AND_RSR: "Estoque e RSR",
  SUCCESS_DELETE_ORDER_SERVICES_SUB_TITLE:
    "Todas as solicitações com status pendente de agendamento foram excluídas com sucesso!",
  SUCCESS_DELETE_ORDER_SERVICES_TITLE: "Solicitações excluídas",
  SUCCESSFULLY_CHANGED: "Alterado com sucesso",
  REPORT_NOT_FOUND: "Relatório não encontrado",
  REPORT_NOT_FOUND_DETAILS:
    "Desculpe, não foi possível obter os dados do relatório de atendimento. Tente novamente mais tarde.",
  RESCHEDULE: "Reagendar",
  RSR_FORM_BUTTON: "Acessar forms materiais e RSR >",
  TERMINAL: "Terminal:",
  TELEPHONE: "Telefone:",
  TERMS_ACCEPTANCE:
    "Estou ciente que ao aprovar o terceirizado devo ter verificado todas as informações necessárias anteriormente, pois após liberação, ele poderá acessar a plataforma e receber demandas.",
  THE_PRICE_WAS_ALTERED: "O preço referente a esse terceirizado foi alterado.",
  THERE_ARE_NO_WORKERS_IN_REGION:
    "Ainda não há terceirizados cadastrados nessa região",
  THIS_OUTSOURCED: "essse terceirizado?",
  TIME: "Horário",
  TIMES: "Horários",
  TIMESPAN: "{{startTime}} às {{endTime}}",
  TOO_FAR_AWAY: "Era muito longe",
  TRY_AGAIN: "Tentar novamente",
  TYPE_NUMBER: "Digite o Nº",
  UNPRODUCTIVE_COMPLETED: "Concluído improdutivo",
  UPLOAD_FAILURE: "Falhar ao importar arquivo",
  UPLOAD_SPREADSHEET: "Importar Planilha",
  VERIFY_TYPING: "Verifique se a digitação está correta. Tente novamente.",
  WE_HAVE_SENT_YOUR_REQUEST:
    "Já enviamos sua solicitação ao terceirizado. Acompanhe o status!",
  WHEN_AVAILABLE_WE_WILL_UPDATE_THE_LISTING:
    "Quando houver disponibilidade a listagem será atualizada",
  WITHOUT_MATERIALS: "Eu não tinha materiais ou ferramentas",
  WHY_PROBLEM_WASNT_SOLVED: "Porque o problema não foi resolvido?",
  WHY_SCHEDULE_DIDNT_HAPPEN: "Porque o atendimento não aconteceu?",
  WORKER: "Terceirizado",
  YES: "Sim",
  YES_I_DO: "Sim, quero!",
};

export default language;
