import React from "react";
import i18n from "../../i18n";
import { ReactComponent as EmptyFile } from "../../assets/images/empty-file.svg";
export default function EmptyListComponent() {
  return (
    <div className="flex flex-col items-center mt-12">
      <EmptyFile />
      <span className="text-body-semibold-3 text-gray-600 max-w-sm text-center">
        {i18n.t("NO_OUTSOURCED_WORKERS_WAITING_APPROVAL")}
      </span>
      <span className="text-body-regular-2 text-gray-600 max-w-xs text-center mt-2">
        {i18n.t("SOLICITATION_INFO")}
      </span>
    </div>
  );
}
