import { createTheme, PaletteColorOptions } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface CustomPalette {
    primary: PaletteColorOptions;
    secondary: PaletteColorOptions;
    warning: PaletteColorOptions;
    error: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    primary: true;
    secondary: true;
    warning: true;
    error: true;
  }
}

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: string) =>
  augmentColor({ color: { main: mainColor } });

const customTheme = createTheme({
  palette: {
    primary: createColor("#A429FF"),
    secondary: createColor("#2794F9"),
    warning: createColor("#FFDE5C"),
    error: createColor("#A51A12"),
  },
  typography: {
    button: {
      textTransform: "none",
      fontFamily: "Montserrat, sans-serif",
      fontWeight: 600,
    },
  },
});

export { customTheme };
