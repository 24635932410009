import { StyleSheet, Font } from "@react-pdf/renderer";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-Y3tcoqK5.ttf",
      fontWeight: "normal",
    },
    {
      src: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-Y3tcoqK5.ttf",
      fontWeight: "semiBold",
    },
    {
      src: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-Y3tcoqK5.ttf",
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    padding: 40,
  },
  headTitle: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 20,
    color: "#7F00E0",
    alignSelf: "center",
    textAlign: "center",
    marginBottom: 20,
  },
  subtitle: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    color: "#474747",
    fontSize: 14,
    marginLeft: 28,
  },
  container: {
    backgroundColor: "#FAFAFA",
    borderRadius: 20,
    paddingHorizontal: 28,
    paddingVertical: 18,
    marginTop: 10,
    marginBottom: 32,
  },
  itemTitle: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    color: "#474747",
    fontSize: 10,
  },
  itemInfo: {
    fontFamily: "Montserrat",
    fontWeight: "normal",
    color: "#474747",
    fontSize: 10,
  },
  sectionView: {
    display: "flex",
    flexDirection: "row",
  },
  textCol: {
    fontFamily: "Montserrat",
    fontWeight: "normal",
    color: "#474747",
    fontSize: 10,
    minWidth: "50%",
  },
  halfWidth: {
    width: "50%",
    paddingRight: 20,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    marginTop: 5,
  },
  threeItemsRow: {
    display: "flex",
    width: "33%",
    paddingRight: 10,
    flexDirection: "row",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  threeItemsCol: {
    display: "flex",
    width: "33%",
    flexDirection: "column",
  },
  itemTitleWithMargin: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    color: "#474747",
    fontSize: 10,
    marginTop: 5,
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#FAFAFA",
    borderRadius: 20,
    paddingHorizontal: 28,
    paddingVertical: 18,
    marginTop: 10,
    marginBottom: 32,
  },
  twoItemsCol: {
    display: "flex",
    width: "50%",
    flexDirection: "column",
  },
  twoItemsRow: {
    display: "flex",
    width: "50%",
    paddingRight: 10,
    flexDirection: "row",
  },
});

export default styles;
