import React from "react";
import { NumericFormat } from "react-number-format";

interface MaskDecimalInputProps {
  id: string;
  value: string;
  name: string;
  placeholder: string;
  handleChange: (e: React.ChangeEvent<any>) => void;
  disabled?: boolean;
  className?: string;
}

export default function MaskDecimalInput({
  id,
  value,
  name,
  handleChange,
  placeholder,
  disabled = false,
  className = "",
}: MaskDecimalInputProps) {
  return (
    <div className="w-72">
      <NumericFormat
        type="text"
        thousandSeparator="."
        allowNegative={false}
        decimalSeparator=","
        maxLength={10}
        prefix="R$ "
        decimalScale={2}
        className={`w-36 h-8 rounded-lg border border-gray-300 text-center placeholder-gray-300 text-sm focus:outline-none ${className}`}
        id={id}
        data-testid={id}
        name={name}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        placeholder={placeholder}
        fixedDecimalScale
      />
    </div>
  );
}
