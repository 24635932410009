import i18n from "@/i18n";
import { OrderServiceStatus } from "@/utils/enums/OrderServiceStatus";
import React, { useEffect, useState } from "react";

interface OrderServiceStatusCardProps {
  status: OrderServiceStatus;
}
export default function OrderServiceStatusCard({
  status,
}: OrderServiceStatusCardProps) {
  const [title, setTitle] = useState("");
  const [color, setColor] = useState("");

  const setCardProperties = () => {
    switch (status) {
      case OrderServiceStatus.COMPLETED:
        setTitle(i18n.t("COMPLETED"));
        setColor("bg-green-700");
        break;
      case OrderServiceStatus.COMPLETED_UNPRODUCTIVE:
        setTitle(i18n.t("COMPLETED_UNPRODUCTIVE"));
        setColor("bg-lemon-800");
        break;
      case OrderServiceStatus.PENDING_EXECUTION:
        setTitle(i18n.t("PENDING_EXECUTION"));
        setColor("bg-blue-100");
        break;
      case OrderServiceStatus.PENDING_ACCEPTANCE:
        setTitle(i18n.t("AWAITING_ACCEPTANCE"));
        setColor("bg-yellow-700");
        break;
      case OrderServiceStatus.PENDING_RESCHEDULE:
        setTitle(i18n.t("RESCHEDULE"));
        setColor("bg-purple-700");
        break;
      case OrderServiceStatus.PENDING_SCHEDULE:
        setTitle(i18n.t("PENDING_SCHEDULE"));
        setColor("bg-red-700");
        break;
    }
  };

  useEffect(() => {
    setCardProperties();
  }, [status]);

  return (
    <div className={`px-2 py-1 md:p-2 rounded-3xl ${color}`}>
      <span className="text-white text-caption-bold-1 md:text-body-semibold-1">
        {title}
      </span>
    </div>
  );
}
