import { OutsourcedServicesWorkerDto } from "@/typeDeclarations/DTOs/OutsourcedWorkerServicesDto";
import { GetOutsourcedWorkerDto } from "@/typeDeclarations/Requests/GetOutsourcedWorker/GetOutsourcedWorkerDto";
import { OutsourcedWorkerServiceType } from "@/utils/enums/OutsourcedWorkerServiceType";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import TermAcceptanceSection from "./formComponents/TermAcceptanceSection";
import DocumentationSentSection from "./formComponents/DocumentationSentSection";
import ServiceTypeSection from "./formComponents/ServiceTypeSection";
import ServiceValuesSection from "./formComponents/ServiceValuesSection";
import { buildOutsourcedServices } from "@/utils/outsourcedWorkerServiceFunctions";
import { OutsourcedWorkerStatus } from "@/utils/enums/OutsourcedWorkerStatus";
import UpdateValuesConfirmationDialog from "./UpdateValuesConfirmationDialog";
import i18n from "@/i18n";
import notifyBugsnag from "@/utils/helpers/Notification/notifyBugsnag";

interface OutsourcedDetailsFormProps {
  outsourcedWorker: GetOutsourcedWorkerDto | undefined;
  outsourcedWorkerStatus: OutsourcedWorkerStatus;
  outsourcedWorkerEmail: string;
  updateOutsourcedWorkerServices: (
    outsourcedServicesWorkerDto: OutsourcedServicesWorkerDto
  ) => Promise<void>;
  renderSnackbarSuccess: (
    snackbarMessage: string,
    snackbarSubtitle: string
  ) => void;
  renderSnackbarInfo: (
    snackbarMessage: string,
    snackbarSubtitle: string
  ) => void;
  renderSnackbarError: (
    snackbarMessage: string,
    snackbarSubtitle: string
  ) => void;
}

export default function OutsourcedDetailsForm({
  outsourcedWorker,
  outsourcedWorkerStatus,
  outsourcedWorkerEmail,
  updateOutsourcedWorkerServices,
  renderSnackbarSuccess,
  renderSnackbarInfo,
  renderSnackbarError,
}: OutsourcedDetailsFormProps) {
  const outsourcedWorkerLaValue = outsourcedWorker?.services?.find(
    (service) => service.typeService === OutsourcedWorkerServiceType.LA
  )?.paymentValue;
  const outsourcedWorkerCabistaValue = outsourcedWorker?.services?.find(
    (service) => service.typeService === OutsourcedWorkerServiceType.CABISTA
  )?.paymentValue;
  const [isEditingValues, setIsEditingValues] = useState<boolean>(false);
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const [currentPriceLa, setCurrentPriceLa] = useState<string | undefined>(
    String(outsourcedWorkerLaValue?.toFixed(2)).replace(".", ",")
  );
  const [currentPriceCabista, setCurrentPriceCabista] = useState<
    string | undefined
  >(String(outsourcedWorkerCabistaValue?.toFixed(2)).replace(".", ","));
  const shouldShowEditButton =
    outsourcedWorkerStatus === OutsourcedWorkerStatus.ACTIVE;

  const getPickedTypeService = (isCabista: boolean, isLa: boolean) => {
    if (isCabista && isLa) {
      return "LACabista";
    } else if (isCabista) {
      return "Cabista";
    } else if (isLa) {
      return "LA";
    }
    return "";
  };

  const OutsourcedWorkerServicesFormsInitialValues = {
    inputLa: String(outsourcedWorkerLaValue?.toFixed(2)).replace(".", ","),
    inputCabista: String(outsourcedWorkerCabistaValue?.toFixed(2)).replace(
      ".",
      ","
    ),
    pickedTypeService: getPickedTypeService(
      !!outsourcedWorkerCabistaValue,
      !!outsourcedWorkerLaValue
    ),
  };

  const OutsourcedWorkerServicesFormsInitialError = {
    inputLa: "",
    inputCabista: "",
    pickedTypeService: "",
  };

  const schemaValidation = yup.object().shape({
    inputLa: yup.string().when("pickedTypeService", {
      is: (pickedTypeService: string) =>
        pickedTypeService === "LA" || pickedTypeService === "LACabista",
      then: yup.string().required(i18n.t("FIELD_REQUIRED")),
      otherwise: yup.string(),
    }),
    inputCabista: yup.string().when("pickedTypeService", {
      is: (pickedTypeService: string) =>
        pickedTypeService === "Cabista" || pickedTypeService === "LACabista",
      then: yup.string().required(i18n.t("FIELD_REQUIRED")),
      otherwise: yup.string(),
    }),
    pickedTypeService: yup.string().required(),
  });

  const formik = useFormik({
    initialValues: OutsourcedWorkerServicesFormsInitialValues,
    initialErrors: OutsourcedWorkerServicesFormsInitialError,
    validationSchema: schemaValidation,
    validateOnChange: true,
    onSubmit: () => {},
  });

  const onSaveValues = () => {
    const pickedServiceType = formik.values.pickedTypeService;
    const valueLa = formik.values.inputLa;
    const valueCabista = formik.values.inputCabista;
    if (valueLa === currentPriceLa && valueCabista === currentPriceCabista) {
      renderSnackbarInfo(
        i18n.t("DATA_WAS_NOT_CHANGED"),
        i18n.t("PRICES_NOT_SAVED_BECAUSE_NO_CHANGE")
      );
    } else {
      const newServices = buildOutsourcedServices(
        pickedServiceType,
        valueLa,
        valueCabista
      );
      if (newServices.length) {
        const newValues = {
          email: outsourcedWorkerEmail,
          services: newServices,
        };
        updateOutsourcedWorkerServices(newValues)
          .then(() => {
            setCurrentPriceLa(valueLa);
            setCurrentPriceCabista(valueCabista);
            renderSnackbarSuccess(
              i18n.t("CHANGE_SAVED_SUCCESSFULLY"),
              i18n.t("THE_PRICE_WAS_ALTERED")
            );
          })
          .catch((err) => {
            renderSnackbarError(
              i18n.t("ERROR_OCCURED"),
              i18n.t("ERROR_UPDATE_OUTSOURCED_WORKER_SERVICE_VALUES")
            );
            notifyBugsnag(err);
          });
      }
    }
    setIsEditingValues(false);
    setDialogIsOpen(false);
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit} className="space-y-16" role="form">
        <DocumentationSentSection documentationSent="true" disabled />
        <ServiceTypeSection
          pickedTypeService={getPickedTypeService(
            !!outsourcedWorkerCabistaValue,
            !!outsourcedWorkerLaValue
          )}
          disabled
        />
        <ServiceValuesSection
          pickedTypeService={getPickedTypeService(
            !!outsourcedWorkerCabistaValue,
            !!outsourcedWorkerLaValue
          )}
          valueCabista={formik.values.inputCabista}
          errorCabista={formik.errors.inputCabista}
          valueLa={formik.values.inputLa}
          errorLa={formik.errors.inputLa}
          handleChange={formik.handleChange}
          disabled={!isEditingValues}
          shouldShowEditButton={shouldShowEditButton}
          isEditingValues={isEditingValues}
          onClickEdit={() => setIsEditingValues(true)}
          onClickSave={() => {
            if (formik.isValid) {
              setDialogIsOpen(true);
            }
          }}
        />
        <TermAcceptanceSection isChecked disabled />
      </form>
      <UpdateValuesConfirmationDialog
        handleAction={onSaveValues}
        open={dialogIsOpen}
        onCloseDialog={() => {
          setDialogIsOpen(false);
        }}
      />
    </div>
  );
}
