import i18n from "@/i18n";
import { OrderService } from "@/typeDeclarations/Entities/OrderService";
import { formatPhone } from "@/utils/stringFormatFunctions";
import React from "react";

interface RequesterSectionProps {
  orderService: OrderService | undefined;
}

export default function RequesterSection({
  orderService,
}: RequesterSectionProps) {
  return (
    <section className="bg-gray-100 px-4 md:px-8 py-4 mt-4 rounded-2xl mb-5">
      <div className="flex flex-col md:flex-row">
        <div className="md:w-1/2 flex flex-col">
          <div className="flex flex-col md:flex-row mb-1">
            <span className="text-caption-bold-1 mr-1 md:text-body-bold-2">
              {i18n.t("NAME")}
            </span>
            <span className="text-body-regular-2 md:text-body-regular-1">
              {orderService?.clientName ?? "--"}
            </span>
          </div>
          <div className="flex flex-col md:flex-row mb-1">
            <span className="text-caption-bold-1 mr-1 md:text-body-bold-2">
              {i18n.t("CELLPHONE")}
            </span>
            <span className="text-body-regular-2 md:text-body-regular-1">
              {orderService?.contactPhone1
                ? formatPhone(orderService.contactPhone1)
                : "--"}
            </span>
          </div>
          <div className="flex flex-col md:flex-row mb-1">
            <span className="text-caption-bold-1 mr-1 md:text-body-bold-2">
              {i18n.t("TELEPHONE")}
            </span>
            <span className="text-body-regular-2 md:text-body-regular-1">
              {orderService?.contactPhone2
                ? formatPhone(orderService.contactPhone2)
                : "--"}
            </span>
          </div>
        </div>
        <div className="md:w-1/2 flex flex-col">
          <div className="flex flex-col md:flex-row mb-1">
            <span className="text-caption-bold-1 mr-1 md:text-body-bold-2">
              {i18n.t("RECIDIVISM")}
            </span>
            <span className="text-body-regular-2 md:text-body-regular-1">
              {`${
                orderService?.recidivism
                  ? orderService.recidivism.toString()
                  : "--"
              }`}
            </span>
          </div>
          <div className="flex flex-col md:flex-row mb-1">
            <span className="text-caption-bold-1 mr-1 md:text-body-bold-2 text-ellipsis">
              {i18n.t("ADDRESS")}
            </span>
            <span className="text-body-regular-2 md:text-body-regular-1">
              {orderService?.address ?? "--"}
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}
