import React, { useCallback, useEffect, useState } from "react";
import i18n from "@/i18n";
import { OutsourcedWorker } from "@/typeDeclarations/Entities/OutsourcedWorker";
import OutsourcedWorkerCard from "./OutsourcedWorkerCard";
import FlatList from "flatlist-react";
import EmptyListComponent from "./EmptyListComponent";
import { Snackbar } from "@mui/material";
import { UseQueryResult } from "@tanstack/react-query";
import Layout from "@/components/Layout";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import DataDisplay from "@/components/DataDisplay";
import { NavigateFunction } from "react-router-dom";
import notifyBugsnag from "@/utils/helpers/Notification/notifyBugsnag";

interface OutsourcedWorkerListingContentProps {
  getOutsourcedWorkers: (
    page: number,
    count: number
  ) => Promise<OutsourcedWorker[]>;
  countPendingApproval: UseQueryResult<number, unknown>;
  navigate: NavigateFunction;
}
export default function OutsourcedWorkerListingContent({
  getOutsourcedWorkers,
  countPendingApproval,
  navigate,
}: OutsourcedWorkerListingContentProps) {
  const [isAttentionCardVisible, setIsAttentionCardVisible] =
    useState<boolean>(false);
  const [outsourcedWorkers, setOutsourcedWorkers] = useState<
    OutsourcedWorker[]
  >([]);
  const [hasMoreItems, setHasMoreItems] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [snackbarVisibility, setSnackbarVisibility] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalPendingApproval, setTotalPendingApproval] = useState(0);
  const COUNT_DEFAULT = 50;

  const renderOutsourcedCards = (item: OutsourcedWorker) => {
    return <OutsourcedWorkerCard outsourcedWorker={item} navigate={navigate} />;
  };

  const getListItems = useCallback(async () => {
    try {
      setIsLoading(true);
      const workers = await getOutsourcedWorkers(page, COUNT_DEFAULT);
      setHasMoreItems(workers.length > 0);
      setOutsourcedWorkers([...outsourcedWorkers, ...workers]);
      setPage(page + 1);
    } catch (error) {
      setSnackbarMessage(i18n.t("ERROR_FETCH_WORKERS"));
      setSnackbarVisibility(true);
      notifyBugsnag(error as Error);
    } finally {
      setIsLoading(false);
    }
  }, [page]);

  const showPendingApprovalAmountSnackbar = () => {
    if (countPendingApproval.status === "error") {
      setSnackbarMessage(i18n.t("ERROR_FETCH_PENDING_WORKERS"));
      setSnackbarVisibility(true);
      notifyBugsnag(new Error(i18n.t("ERROR_FETCH_PENDING_WORKERS")));
    }
    const pendingApproval = countPendingApproval.data as number;
    setIsAttentionCardVisible(pendingApproval > 0);
    setTotalPendingApproval(pendingApproval);
  };

  useEffect(() => {
    showPendingApprovalAmountSnackbar();
  }, [countPendingApproval]);

  useEffect(() => {
    void getListItems();
  }, []);

  if (isLoading) {
    return <DataDisplay.LoadingPage />;
  }

  return (
    <div className="sm:px-10">
      <div className="flex flex-col mb-16">
        <Layout.Alert
          severity="warning"
          visibility={isAttentionCardVisible}
          setVisibility={setIsAttentionCardVisible}
          title={i18n.t("PENDING_APPROVAL")}
          subtitle={i18n
            .t("ATTENTION_PENDING_APPROVAL", {
              count: totalPendingApproval,
            })
            .toString()}
          icon={
            <WarningAmberRoundedIcon className="text-yellow-800 text-heading-regular-6" />
          }
          style="ml-6 sm:ml-0"
        />
        <span className="text-heading-semibold-4 fill-current text-gray-800 mt-6 font-bold">
          {i18n.t("OUTSOURCED_WORKERS")}
        </span>
      </div>
      <FlatList
        list={outsourcedWorkers}
        renderItem={renderOutsourcedCards}
        renderWhenEmpty={EmptyListComponent}
        hasMoreItems={hasMoreItems}
        loadMoreItems={() => {
          void getListItems();
        }}
      />
      <Snackbar
        open={snackbarVisibility}
        autoHideDuration={6000}
        onClose={() => setSnackbarVisibility(false)}
        message={snackbarMessage}
      />
    </div>
  );
}
