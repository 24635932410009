import React, { ReactNode } from "react";
import LoadingPage from "./LoadingPage";

const DataDisplay = ({ children }: { children: ReactNode }): JSX.Element => (
  <>{children}</>
);

DataDisplay.LoadingPage = LoadingPage;

export default DataDisplay;
