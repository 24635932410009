import React from "react";
import { CircularProgress } from "@mui/material";

const LoadingPage = (): JSX.Element => {
  return (
    <div className="min-w-fit w-full min-h-screen gradient-primary-1 flex items-center p-4 justify-center">
      <div>
        <CircularProgress color="primary" />
      </div>
    </div>
  );
};

export default LoadingPage;
