import { Alert as AlertMui, AlertTitle } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import React from "react";
import DOMPurify from "dompurify";

interface AlertProps {
  title: string;
  subtitle: string;
  visibility: boolean;
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  severity: "error" | "success" | "warning" | "info";
  icon: React.ReactNode;
  style?: string;
}

/**
 * Snackbar de alerta que recebe uma string HTML e injeta no span.
 * NÃO utilize com strings definidas pelo usuário.
 * SEMPRE QUE POSSIVEL utilize o alert normal.
 * Injetar HTML diretamente no DOM é uma operação de alto risco e só deve ser realizada com HTML conhecido e controlado.
 * @param title titulo do snackbar
 * @param subtitle conteudo do snackbar, é aqui que deve ser passado o HTML
 * @param visibility visibilidade do snackbar
 * @param setVisibility função para controle de visibilidade da snackbar
 * @param severity nivel de importancia do snackbar, defina a cor padrão do card
 * @param icon imagem a ser exibida
 * @param style estilização do card. OPCIONAL
 * @returns
 */
const AlertHtmlContent = ({
  title,
  subtitle,
  visibility,
  setVisibility,
  severity,
  icon,
  style = "",
}: AlertProps) => {
  const sanitizedSubtitle = () => ({
    __html: DOMPurify.sanitize(subtitle),
  });

  const getAlertColor = () => {
    switch (severity) {
      case "error":
        return "bg-red-200";
      case "success":
        return "bg-green-200";
      case "warning":
        return "bg-yellow-200";
      case "info":
        return "bg-purple-200";
    }
  };

  const alertVisibility = visibility ? "" : "hidden";

  return (
    <AlertMui
      severity={severity}
      onClose={() => setVisibility(false)}
      className={`max-w-sm fixed z-50 self-end rounded-lg text-body-regular-2 text-gray-700 mb-5 ${alertVisibility} ${getAlertColor()} ${style}`}
      icon={icon}
      sx={{ fontFamily: "Montserrat" }}
      action={
        <button className="h-0 mr-1" onClick={() => setVisibility(false)}>
          <CloseRoundedIcon className="text-body-regular-2 text-gray-600" />
        </button>
      }
    >
      <AlertTitle
        className="text-body-semibold-1 text-gray-700 mb-0"
        sx={{ fontFamily: "Montserrat" }}
      >
        {title}
      </AlertTitle>
      <span dangerouslySetInnerHTML={sanitizedSubtitle()} />
    </AlertMui>
  );
};

export default AlertHtmlContent;
