import React from "react";
import i18n from "@/i18n";
import RadioButton from "@/components/RadioButton";

interface DocumentationSentSectionProps {
  documentationSent: string;
  handleChange?: (e: React.ChangeEvent<any>) => void;
  disabled?: boolean;
}

const DocumentationSentSection = ({
  documentationSent,
  handleChange = () => {},
  disabled = false,
}: DocumentationSentSectionProps) => {
  return (
    <section className="space-y-6">
      <label className="text-base text-body-bold-1">
        {i18n.t("DOCUMENTS")}
      </label>
      <p className="text-base">{i18n.t("DOCUMENTATION_VERIFIED")}</p>
      <div role="group" className="flex space-x-20">
        <RadioButton
          id="documentationSentYes"
          label={i18n.t("YES")}
          value="true"
          name="documentationSent"
          handleChange={handleChange}
          checked={documentationSent === "true"}
          disabled={disabled}
        />
        <RadioButton
          id="documentationSentNo"
          label={i18n.t("NO")}
          value="false"
          name="documentationSent"
          handleChange={handleChange}
          checked={documentationSent === "false"}
          disabled={disabled}
        />
      </div>
    </section>
  );
};

export default DocumentationSentSection;
