import { I18n } from "i18n-js";
import { initReactI18next } from "react-i18next";
import ptbr from "./pt-BR";

const localeLanguage = navigator.language;
const i18n = new I18n({
  "pt-BR": ptbr,
});

i18n.translations = {
  "pt-BR": ptbr,
  en: ptbr,
};
i18n.defaultLocale = "pt-BR";
i18n.locale = localeLanguage;

i18n.enableFallback = true;

export default i18n;
