import React from "react";
import Alert from "./Alert";
import LateralMenu from "./LateralMenu";
import Pagination from "./Pagination";
import Modal from "./Modal";

const Layout = ({ children }: { children: JSX.Element }): JSX.Element => (
  <>{children}</>
);

Layout.LateralMenu = LateralMenu;
Layout.Pagination = Pagination;
Layout.Alert = Alert;
Layout.Modal = Modal;

export default Layout;
