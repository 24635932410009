import React from "react";
import i18n from "@/i18n";
import { OrderService } from "@/typeDeclarations/Entities/OrderService";
import {
  formatDate,
  formatPhone,
  formatTime,
  splitDateFromTime,
} from "@/utils/stringFormatFunctions";
import { Document, Page, Text, View } from "@react-pdf/renderer";
import styles from "./styles";
import { ScheduleReport } from "@/typeDeclarations/Entities/ScheduleReport";
import { ProblemUnsolvedReasons } from "@/utils/enums/ProblemUnsolvedReasons";
import { UserAnswer } from "@/utils/enums/UserAnswer";
import { ScheduleNotHappenedReasons } from "@/utils/enums/ScheduleNotHappenedReasons";

interface ReportDocumentProps {
  orderService: OrderService;
  report: ScheduleReport;
  getScheduleDuration: () => string;
}

export default function ReportDocument({
  orderService,
  report,
  getScheduleDuration,
}: ReportDocumentProps) {
  const scheduleNotHappenedReason =
    report?.scheduleNotHappenedReason ===
    ScheduleNotHappenedReasons.CLIENT_WASNT_AT_HOME
      ? i18n.t("CLIENT_WASNT_AT_HOME")
      : report?.scheduleNotHappenedReason ===
        ScheduleNotHappenedReasons.TOO_FAR_AWAY
      ? i18n.t("TOO_FAR_AWAY")
      : report?.scheduleNotHappenedReason ===
        ScheduleNotHappenedReasons.WITHOUT_MATERIALS
      ? i18n.t("WITHOUT_MATERIALS")
      : report?.scheduleNotHappenedReason === ScheduleNotHappenedReasons.OTHER
      ? i18n.t("OTHER")
      : "--";

  const problemUnsolvedReason =
    report?.problemUnsolvedReason === ProblemUnsolvedReasons.DIFFERENT_PROBLEM
      ? i18n.t("DIFFERENT_PROBLEN")
      : report?.problemUnsolvedReason ===
        ProblemUnsolvedReasons.WITHOUT_MATERIALS
      ? i18n.t("WITHOUT_MATERIALS")
      : report?.problemUnsolvedReason === ProblemUnsolvedReasons.OTHER
      ? i18n.t("OTHER")
      : "--";

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.headTitle}>{i18n.t("SCHEDULE_REPORT")}</Text>
        <Text style={styles.subtitle}>{i18n.t("DETAILS")}</Text>
        <View style={styles.container}>
          <View style={styles.sectionView}>
            <View style={styles.halfWidth}>
              <Text style={styles.itemTitle}>{i18n.t("BA")}</Text>
              <Text style={styles.textCol}>{" " + orderService.ba}</Text>
            </View>
            <View style={styles.halfWidth}>
              <Text style={styles.itemTitle}>{i18n.t("REQUEST_NUMBER")}</Text>
              <Text style={styles.textCol}>{orderService.id.toString()}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.halfWidth}>
              <Text style={styles.itemTitle}>{i18n.t("TERMINAL")}</Text>
              <Text style={styles.textCol}>
                {formatPhone(orderService.terminal)}
              </Text>
            </View>
            <View style={styles.halfWidth}>
              <Text style={styles.itemTitle}>{i18n.t("WORKER") + ": "}</Text>
              <Text style={styles.textCol}>
                {orderService?.schedule?.outsourcedWorker?.name ?? "--"}
              </Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.halfWidth}>
              <Text style={styles.itemTitle}>{i18n.t("ACTIVITY")}</Text>
              <Text style={styles.textCol}>{orderService.activity}</Text>
            </View>
            <View style={styles.halfWidth}>
              <Text style={styles.itemTitle}>{i18n.t("SERVICE") + ": "}</Text>
              <Text style={styles.textCol}>{orderService.service}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.halfWidth}>
              <Text style={styles.itemTitle}>{i18n.t("SERVICE_DATE")}</Text>
              <Text style={styles.textCol}>
                {orderService.schedule?.startDate
                  ? formatDate(orderService.schedule.startDate)
                  : "--"}
                `
              </Text>
            </View>
            <View style={styles.halfWidth}>
              <Text style={styles.itemTitle}>
                {i18n.t("ORDER_SERVICE_OPENING_DATE")}
              </Text>
              <Text style={styles.textCol}>
                {orderService.dateOpeningOs
                  ? splitDateFromTime(orderService.dateOpeningOs)
                  : "--"}
                `
              </Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.halfWidth}>
              <Text style={styles.itemTitle}>{i18n.t("SERVICE_TIME")}</Text>
              <Text style={styles.textCol}>
                {orderService.schedule?.startDate
                  ? formatTime(orderService.schedule.startDate)
                  : "--"}
              </Text>
            </View>
          </View>
        </View>
        <Text style={styles.subtitle}>{i18n.t("REQUESTER")}</Text>
        <View style={styles.rowContainer}>
          <View style={styles.column}>
            <View style={styles.halfWidth}>
              <Text style={styles.itemTitle}>{i18n.t("NAME")}</Text>
              <Text style={styles.textCol}>{orderService.clientName}</Text>
              <Text style={styles.itemTitleWithMargin}>
                {i18n.t("CELLPHONE")}
              </Text>
              <Text style={styles.textCol}>
                {formatPhone(orderService.contactPhone1)}
              </Text>
              <Text style={styles.itemTitleWithMargin}>
                {i18n.t("TELEPHONE")}
              </Text>
              <Text style={styles.textCol}>
                {formatPhone(orderService.contactPhone2)}
              </Text>
            </View>
          </View>
          <View style={styles.column}>
            <View style={styles.halfWidth}>
              <Text style={styles.itemTitle}>{i18n.t("RECIDIVISM")}</Text>
              <Text style={styles.textCol}>
                {orderService.recidivism
                  ? orderService.recidivism.toString()
                  : "--"}
              </Text>
              <Text style={styles.itemTitleWithMargin}>
                {i18n.t("ADDRESS")}
              </Text>
              <Text style={styles.textCol}>{orderService.address}</Text>
            </View>
          </View>
        </View>
        <Text style={styles.subtitle}>{i18n.t("REPORT")}</Text>
        <View style={styles.container}>
          <View style={styles.row}>
            <View
              style={
                report?.scheduleHappened === UserAnswer.YES &&
                report?.problemSolved === UserAnswer.NO
                  ? styles.threeItemsRow
                  : styles.twoItemsCol
              }
            >
              <View style={styles.column}>
                <Text style={styles.itemTitle}>
                  {i18n.t("SCHEDULE_HAPPENED")}
                </Text>
                <Text style={styles.textCol}>
                  {report?.scheduleHappened === UserAnswer.YES
                    ? i18n.t("YES")
                    : report?.scheduleHappened === UserAnswer.NO
                    ? i18n.t("NO")
                    : "--"}
                </Text>
                {report?.scheduleHappened === UserAnswer.YES && (
                  <>
                    <Text style={styles.itemTitleWithMargin}>
                      {i18n.t("SCHEDULE_DURATION")}
                    </Text>
                    <Text style={styles.textCol}>{getScheduleDuration()}</Text>
                  </>
                )}
                {report?.scheduleHappened === UserAnswer.NO && (
                  <>
                    <Text style={styles.itemTitleWithMargin}>
                      {i18n.t("WHY_SCHEDULE_DIDNT_HAPPEN")}
                    </Text>
                    <Text style={styles.textCol}>
                      {scheduleNotHappenedReason}
                    </Text>
                  </>
                )}
              </View>
            </View>
            {report?.scheduleHappened === UserAnswer.YES && (
              <View
                style={
                  report?.scheduleHappened === UserAnswer.YES &&
                  report?.problemSolved === UserAnswer.NO
                    ? styles.threeItemsRow
                    : styles.twoItemsRow
                }
              >
                <View style={styles.column}>
                  <Text style={styles.itemTitle}>
                    {i18n.t("PROBLEM_SOLVED")}
                  </Text>
                  <Text style={styles.textCol}>
                    {report?.problemSolved === UserAnswer.YES
                      ? i18n.t("YES")
                      : report?.problemSolved === UserAnswer.NO
                      ? i18n.t("NO")
                      : "--"}
                  </Text>
                  {report?.problemSolved === UserAnswer.NO && (
                    <>
                      <Text style={styles.itemTitleWithMargin}>
                        {i18n.t("WHY_PROBLEM_WASNT_SOLVED")}
                      </Text>
                      <Text style={styles.textCol}>
                        {problemUnsolvedReason}
                      </Text>
                    </>
                  )}
                </View>
              </View>
            )}
            {(report?.problemSolved === UserAnswer.NO ||
              report?.scheduleHappened === UserAnswer.NO) && (
              <View
                style={
                  report?.problemSolved === UserAnswer.NO
                    ? styles.threeItemsCol
                    : styles.twoItemsCol
                }
              >
                <Text style={styles.itemTitle}>{i18n.t("DETAILS")}</Text>
                {report?.problemSolved === UserAnswer.NO && (
                  <Text style={styles.textCol}>
                    {report?.problemUnsolvedDetails?.length
                      ? report?.problemUnsolvedDetails
                      : "--"}
                  </Text>
                )}
                {report?.scheduleHappened === UserAnswer.NO && (
                  <Text style={styles.textCol}>
                    {report?.scheduleNotHappenedDetails?.length
                      ? report?.scheduleNotHappenedDetails
                      : "--"}
                  </Text>
                )}
              </View>
            )}
          </View>
        </View>
      </Page>
    </Document>
  );
}
