import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { ThemeProvider } from "@material-tailwind/react";
import AppContainer from "./AppContainer";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ReactGA from "react-ga4";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

if (process.env.REACT_APP_BUGSNAG_KEY && process.env.NODE_ENV === "production")
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_KEY,
    plugins: [new BugsnagPluginReact()],
  });

if (
  process.env.REACT_APP_GA_MEASUREMENT_ID &&
  process.env.NODE_ENV === "production"
)
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const ErrorBoundary = Bugsnag.getPlugin("react")?.createErrorBoundary(React);

const renderContent = () => (
  <QueryClientProvider client={queryClient}>
    <ThemeProvider>
      <AppContainer />
    </ThemeProvider>
  </QueryClientProvider>
);

root.render(
  ErrorBoundary ? (
    <ErrorBoundary>{renderContent()}</ErrorBoundary>
  ) : (
    renderContent()
  )
);
