import { SessionStorageKeysEnum } from "@/utils/enums/SessionStorageKeysEnum";
import { InternalAxiosRequestConfig } from "axios";
import apiService from "./interceptConfiguration";

export const setRequestInterceptor = () => {
  apiService.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    const accessToken = sessionStorage.getItem(
      SessionStorageKeysEnum.ACCESS_TOKEN_SESSION
    );
    const newConfig = { ...config };
    if (accessToken && newConfig.headers) {
      newConfig.headers.Authorization = `Bearer ${accessToken}`;
    }
    return newConfig;
  });
};
