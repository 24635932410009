/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import i18n from "@/i18n";

export default function BackButton() {
  const navigate = useNavigate();
  return (
    <IconButton
      disableFocusRipple
      disableTouchRipple
      color="inherit"
      className="border-none bg-white"
      onClick={() => navigate(-1)}
    >
      <ArrowBackIosNewRoundedIcon
        className="text-purple-700 mr-2 bg-white"
        color="inherit"
        fontSize="small"
      />
      <span className="text-purple-700 text-body-semibold-1">
        {i18n.t("GO_BACK")}
      </span>
    </IconButton>
  );
}
