import React from "react";
import i18n from "@/i18n";
import SaveIcon from "@mui/icons-material/Save";

interface SaveButtonProps {
  onClick: () => void;
}

const SaveButton = ({ onClick }: SaveButtonProps) => {
  return (
    <button
      className="cursor-pointer text-blue-100"
      onClick={onClick}
      role={"btn_save"}
    >
      <>
        <SaveIcon className="mr-2" fontSize="inherit" />
        {i18n.t("SAVE")}
      </>
    </button>
  );
};

export default SaveButton;
