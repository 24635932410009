import MaskDecimalInput from "@/components/MaskDecimalInput";
import i18n from "@/i18n";
import React from "react";
import EditButton from "./EditButton";
import SaveButton from "./SaveButton";

interface ServiceValuesSectionProps {
  pickedTypeService: string;
  valueLa: string;
  errorLa?: string | undefined;
  valueCabista: string;
  errorCabista?: string | undefined;
  handleChange?: (e: React.ChangeEvent<any>) => void;
  disabled?: boolean;
  shouldShowEditButton?: boolean;
  isEditingValues?: boolean;
  onClickSave?: () => void;
  onClickEdit?: () => void;
}

const ServiceValuesSection = ({
  pickedTypeService,
  valueLa,
  errorLa = undefined,
  valueCabista,
  errorCabista = undefined,
  handleChange = () => {},
  disabled = false,
  shouldShowEditButton = false,
  isEditingValues = false,
  onClickSave = () => {},
  onClickEdit = () => {},
}: ServiceValuesSectionProps) => {
  return (
    <section className="space-y-6">
      <div className={`flex flex-row justify-between md:mr-28 lg:mr-40`}>
        <label className="text-base text-body-bold-1">{i18n.t("PRICE")}</label>
        {shouldShowEditButton && (
          <div className={`flex flex-row-reverse md:mr-28 lg:mr-40`}>
            {isEditingValues ? (
              <SaveButton onClick={onClickSave} />
            ) : (
              <EditButton onClick={onClickEdit} />
            )}
          </div>
        )}
      </div>
      <p className="text-base">{i18n.t("PRICE_OUTSOURCED_WORKER")}</p>
      <div className="flex flex-wrap lg-max:space-y-4">
        {(pickedTypeService === "LA" || pickedTypeService === "LACabista") && (
          <div className="space-y-2 sm:w-96">
            <label className="text-sm">
              <>
                {i18n.t("LA")}
                <label className="ml-1 text-base text-red-600">*</label>
              </>
            </label>
            <MaskDecimalInput
              placeholder="R$ XX,XX"
              name="inputLa"
              id="inputLa"
              handleChange={handleChange}
              value={valueLa}
              disabled={disabled}
              className={errorLa ? "border-red-700" : ""}
            />
            {errorLa && (
              <div>
                <p className="text-tooltip-2 text-red-700 mt-3">{errorLa}</p>
              </div>
            )}
            <div>
              <p className="text-tooltip-2 text-red-700 mt-3">
                {i18n.t("IN_THIS_CATEGORY_THE_VALUE_IS_BY_SERVICE")}
              </p>
            </div>
          </div>
        )}
        {(pickedTypeService === "Cabista" ||
          pickedTypeService === "LACabista") && (
          <div className="space-y-2 sm:w-96">
            <label className="text-sm">
              <>
                {i18n.t("CABLE_MAN")}
                <label className="ml-1 text-base text-red-600">*</label>
              </>
            </label>
            <MaskDecimalInput
              placeholder="R$ XX,XX"
              name="inputCabista"
              id="inputCabista"
              handleChange={handleChange}
              value={valueCabista}
              disabled={disabled}
              className={errorCabista ? "border-red-700" : ""}
            />
            {errorCabista && (
              <div>
                <p className="text-tooltip-2 text-red-700 mt-3">
                  {errorCabista}
                </p>
              </div>
            )}
            <div>
              <p className="text-tooltip-2 text-red-700 mt-3">
                {i18n.t("IN_THIS_CATEGORY_THE_VALUE_IS_BY_DAY")}
              </p>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default ServiceValuesSection;
