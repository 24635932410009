import { Checkbox as CheckboxMui } from "@mui/material";
import React from "react";

interface CheckboxProps {
  onChange: React.Dispatch<React.SetStateAction<boolean>>;
  value: boolean;
  checked?: boolean;
  id?: string;
  name?: string;
  disabled?: boolean;
}

export default function Checkbox({
  id,
  name,
  disabled = false,
  onChange,
  value,
  checked = false,
}: CheckboxProps) {
  return (
    <CheckboxMui
      id={id}
      name={name}
      value={value}
      checked={checked}
      onChange={(_, checked) => onChange(checked)}
      disabled={disabled}
      sx={{
        color: "#999999",
        "&.Mui-checked": {
          color: "#7F00E0",
        },
        "&.Mui-disabled": {
          color: "#999999",
        },
        padding: 0,
      }}
    />
  );
}
