import BackButton from "@/components/BackButton";
import i18n from "@/i18n";
import { OutsourcedServicesWorkerDto } from "@/typeDeclarations/DTOs/OutsourcedWorkerServicesDto";
import React, { useEffect, useState } from "react";
import { OutsourcedWorkerStatusDto } from "@/typeDeclarations/DTOs/OutsourcedWorkerStatusDto";
import OutsourcedDetailsForm from "./OutsourcedDetailsForm";
import { OutsourcedWorkerStatus } from "@/utils/enums/OutsourcedWorkerStatus";
import { NavigateFunction } from "react-router-dom";
import Layout from "@/components/Layout";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { OutsourcedWorkerBankInfoDto } from "@/typeDeclarations/DTOs/OutsourcedWorkerBankInfoDto";
import { GetOutsourcedWorkerDto } from "@/typeDeclarations/Requests/GetOutsourcedWorker/GetOutsourcedWorkerDto";
import DoneIcon from "@mui/icons-material/Done";
import { ReactComponent as InfoIcon } from "@/assets/icons/exclamation-circle.svg";
import { OutsourcedWorker } from "@/typeDeclarations/Entities/OutsourcedWorker";
import LoadingPage from "@/components/DataDisplay/LoadingPage";
import OutsourcedWorkerBankInfoForm from "./OutsourcedWorkerBankInfoForm";
import OutsourcedPendingApprovalDetailsForm from "./OutsourcedPendingApprovalDetailsForm";
import notifyBugsnag from "@/utils/helpers/Notification/notifyBugsnag";

interface OutsourcedWorkersDetailsContentProps {
  outsourcedWorker: OutsourcedWorker;
  postOutsourcedWorkerServices: (
    outsourcedServicesWorkerDto: OutsourcedServicesWorkerDto
  ) => Promise<void>;
  updateOutsourcedWorkerServices: (
    outsourcedServicesWorkerDto: OutsourcedServicesWorkerDto
  ) => Promise<void>;
  fetchOutsourcedWorkerStatus: (
    outsourcedWorkerStatusDto: OutsourcedWorkerStatusDto
  ) => Promise<void>;
  editOutsourcedWorkerBankInfo: (
    outsourcedWorkerBankInfoDto: OutsourcedWorkerBankInfoDto,
    email: string
  ) => Promise<void>;
  fetchOutsourcedWorker: (email: string) => Promise<GetOutsourcedWorkerDto>;
  navigate: NavigateFunction;
}

export default function OutsourcedWorkersDetailsContent({
  outsourcedWorker,
  postOutsourcedWorkerServices,
  updateOutsourcedWorkerServices,
  fetchOutsourcedWorkerStatus,
  editOutsourcedWorkerBankInfo,
  fetchOutsourcedWorker,
  navigate,
}: OutsourcedWorkersDetailsContentProps) {
  const [snackbarErrorVisibility, setSnackbarErrorVisibility] =
    useState<boolean>(false);
  const [snackbarSuccessVisibility, setSnackbarSuccessVisibility] =
    useState<boolean>(false);
  const [snackbarInfoVisibility, setSnackbarInfoVisibility] =
    useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSubtitle, setSnackbarSubtitle] = useState<string>("");
  const [outsourcedWorkerDetails, setOutsourcedWorkersDetails] =
    useState<GetOutsourcedWorkerDto>();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const renderSnackbarError = (
    snackbarMessage: string,
    snackbarSubtitle: string
  ) => {
    setSnackbarMessage(snackbarMessage);
    setSnackbarSubtitle(snackbarSubtitle);
    setSnackbarErrorVisibility(true);
  };

  const renderSnackbarSuccess = (
    snackbarMessage: string,
    snackbarSubtitle: string
  ) => {
    setSnackbarMessage(snackbarMessage);
    setSnackbarSubtitle(snackbarSubtitle);
    setSnackbarSuccessVisibility(true);
  };

  const renderSnackbarInfo = (
    snackbarMessage: string,
    snackbarSubtitle: string
  ) => {
    setSnackbarMessage(snackbarMessage);
    setSnackbarSubtitle(snackbarSubtitle);
    setSnackbarInfoVisibility(true);
  };

  useEffect(() => {
    if (outsourcedWorker.status === OutsourcedWorkerStatus.PENDING_APPROVAL) {
      return;
    }
    if (outsourcedWorker.id == null) {
      navigate("/outsourcedWorkers");
    }
    setIsLoading(true);
    fetchOutsourcedWorker(outsourcedWorker.email)
      .then((res) => {
        setOutsourcedWorkersDetails(res);
      })
      .catch((err) => {
        renderSnackbarError(
          i18n.t("ERROR_OCCURED"),
          i18n.t("ERROR_FETCH_OUTSOURCEDWORKER")
        );
        notifyBugsnag(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleRefused = () => {
    const refusedDto: OutsourcedWorkerStatusDto = {
      email: outsourcedWorker.email,
      status: OutsourcedWorkerStatus.REFUSED,
    };

    updateStatusOutsourcedWorker(refusedDto)
      .then(() => {
        navigate("/outsourcedWorkers");
      })
      .catch((err) => {
        renderSnackbarError(
          i18n.t("ERROR_OCCURED"),
          i18n.t("ERROR_REFUSED_WORKERS_SERVICES")
        );
        notifyBugsnag(err);
      });
  };

  const handleOutsourcedDetailsSubmit = async (
    outsourcedServicesWorkerDto: OutsourcedServicesWorkerDto
  ): Promise<void> => {
    try {
      setIsSubmitting(true);
      outsourcedServicesWorkerDto.email = outsourcedWorker.email;

      const approveDto: OutsourcedWorkerStatusDto = {
        email: outsourcedWorker.email,
        status: OutsourcedWorkerStatus.ACTIVE,
      };

      await updateStatusOutsourcedWorker(approveDto);
      await postOutsourcedWorkerServices(outsourcedServicesWorkerDto);
      navigate("/outsourcedWorkers");
    } catch (error) {
      renderSnackbarError(
        i18n.t("ERROR_OCCURED"),
        i18n.t("ERROR_APPROVE_WORKERS_SERVICES")
      );
      notifyBugsnag(error as Error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOutsourcedBankInfoSubmit = async (
    outsourcedWorkerBankInfoDto: OutsourcedWorkerBankInfoDto,
    email: string
  ): Promise<void> => {
    updateBankInfoOutsourcedWorker(outsourcedWorkerBankInfoDto, email)
      .then(() => {
        setSnackbarMessage(i18n.t("SUCCESSFULLY_CHANGED"));
        setSnackbarSubtitle(i18n.t("BANK_DATA_CHANGED"));
        setSnackbarSuccessVisibility(true);
      })
      .catch((err) => {
        renderSnackbarError(
          i18n.t("ERROR_OCCURED"),
          i18n.t("ERROR_CHANGE_BANK_INFO")
        );
        notifyBugsnag(err);
      });
  };

  const updateStatusOutsourcedWorker = async (
    statusDto: OutsourcedWorkerStatusDto
  ) => {
    await fetchOutsourcedWorkerStatus(statusDto);
  };

  const updateBankInfoOutsourcedWorker = async (
    bankInfoDto: OutsourcedWorkerBankInfoDto,
    email: string
  ) => {
    await editOutsourcedWorkerBankInfo(bankInfoDto, email);
  };

  if (isLoading) return <LoadingPage />;

  const outsourcedWorkerNameText = outsourcedWorker.address
    ? `${outsourcedWorker.name} - ${outsourcedWorker.address.state}`
    : `${outsourcedWorker.name}`;

  return (
    <>
      <main className="ml-8 mdMax:ml-0">
        <header className="mb-9 flex flew-row justify-between">
          <BackButton />
          <Layout.Alert
            severity="error"
            visibility={snackbarErrorVisibility}
            setVisibility={setSnackbarErrorVisibility}
            title={snackbarMessage}
            subtitle={snackbarSubtitle}
            icon={<HighlightOffRoundedIcon fontSize="inherit" />}
            style="top-24 right-6 sm:right-10 ml-6 sm:ml-0"
          />
          <Layout.Alert
            severity="success"
            visibility={snackbarSuccessVisibility}
            setVisibility={setSnackbarSuccessVisibility}
            title={snackbarMessage}
            subtitle={snackbarSubtitle}
            icon={<DoneIcon fontSize="inherit" />}
            style="top-24 right-6 sm:right-10 ml-6 sm:ml-0"
          />
          <Layout.Alert
            severity="info"
            visibility={snackbarInfoVisibility}
            setVisibility={setSnackbarInfoVisibility}
            title={snackbarMessage}
            subtitle={snackbarSubtitle}
            icon={<InfoIcon fontSize="inherit" />}
            style="top-24 right-6 sm:right-10 ml-6 sm:ml-0"
          />
        </header>
        <div className="space-y-8 mb-6">
          <p className="text-lg font-bold">{outsourcedWorkerNameText}</p>
          <div>
            <a
              target={"_blank"}
              rel="noreferrer"
              className="text-blue-100 text-body-semibold-2"
              href="https://docs.google.com/forms/d/1X0yyq4wrvL7_CivkJPBjtiV1OHKPRjtKDavokBAaPDY/edit"
            >
              {i18n.t("FORM_SKILLS_AND_DOCUMENTATION")}
            </a>
          </div>
        </div>
        <div>
          {outsourcedWorker?.status ===
          OutsourcedWorkerStatus.PENDING_APPROVAL ? (
            <OutsourcedPendingApprovalDetailsForm
              onRefused={handleRefused}
              onSubmit={handleOutsourcedDetailsSubmit}
              isSubmitting={isSubmitting}
            />
          ) : (
            <OutsourcedDetailsForm
              outsourcedWorker={outsourcedWorkerDetails}
              outsourcedWorkerStatus={outsourcedWorker.status}
              outsourcedWorkerEmail={outsourcedWorker.email}
              updateOutsourcedWorkerServices={updateOutsourcedWorkerServices}
              renderSnackbarSuccess={renderSnackbarSuccess}
              renderSnackbarInfo={renderSnackbarInfo}
              renderSnackbarError={renderSnackbarError}
            />
          )}
          <div className="mt-16">
            {outsourcedWorker?.status === OutsourcedWorkerStatus.ACTIVE &&
              !!outsourcedWorkerDetails && (
                <OutsourcedWorkerBankInfoForm
                  onSubmit={handleOutsourcedBankInfoSubmit}
                  outsourcedWorker={{
                    ...outsourcedWorkerDetails,
                    email: outsourcedWorker.email,
                  }}
                />
              )}
          </div>
        </div>
      </main>
    </>
  );
}
