import React, { useEffect } from "react";
import DataDisplay from "@/components/DataDisplay";
import { setRequestInterceptor } from "../services/axiosInstances/axiosInstance";
import { useKeycloak } from "@react-keycloak/web";
import { Routes, Route, Navigate } from "react-router-dom";
import OutsourcedWorkersListing from "../screens/OutsourcedWorkersListing";
import Forbidden from "../screens/Forbidden";
import SessionService from "@/services/sessionService";
import { UserProfilesEnum } from "@/utils/enums/UserProfilesEnum";
import ScheduleScreen from "@/screens/Schedule";
import ScheduleRequestsScreen from "@/screens/ScheduleRequests";
import OutsourcedWorkersDetails from "@/screens/OutsourcedWorkersDetails";
import ScheduleReport from "@/screens/ScheduleReport";
import DeleteOutsourcedWorker from "@/screens/DeleteOutsourcedWorker";
import { AUTHENTICATION_FAILURE_TOPIC } from "@/utils/constants";
import { subscribe, unsubscribe } from "pubsub-js";

export default function NavigationRoutes() {
  const { initialized, keycloak } = useKeycloak();

  let logoutSubscriptionToken: string;

  const logoutUser = () => {
    void keycloak.logout({
      redirectUri: process.env.REACT_APP_URL,
    });
  };

  useEffect(() => {
    logoutSubscriptionToken = subscribe(
      AUTHENTICATION_FAILURE_TOPIC,
      logoutUser
    );
    return () => {
      unsubscribe(logoutSubscriptionToken);
    };
  }, []);

  if (!initialized) return <DataDisplay.LoadingPage />;

  if (!keycloak.authenticated) {
    void keycloak.login();
  }

  const loggedUserRole = SessionService.getUserRole(
    keycloak?.tokenParsed?.resource_access,
    keycloak?.authenticated
  );

  if (keycloak.authenticated) setRequestInterceptor();

  if (loggedUserRole === UserProfilesEnum.OUTSOURCED_WORKER) {
    return (
      <Routes>
        <Route
          path="/deleteOutsourcedWorker"
          element={<DeleteOutsourcedWorker />}
        />
        <Route
          path="*"
          element={<Navigate to="/deleteOutsourcedWorker" replace />}
        />
      </Routes>
    );
  }

  if (loggedUserRole !== UserProfilesEnum.BACKOFFICE) {
    return (
      <Routes>
        <Route path="/forbidden" element={<Forbidden />} />
        <Route path="*" element={<Navigate to="/forbidden" replace />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/orderService" element={<ScheduleRequestsScreen />} />
      <Route path="/orderService/schedule" element={<ScheduleScreen />} />
      <Route path="/outsourcedWorkers" element={<OutsourcedWorkersListing />} />
      <Route path="/orderService/report" element={<ScheduleReport />} />
      <Route
        path="/outsourcedWorkerDetails"
        element={<OutsourcedWorkersDetails />}
      />
      <Route path="*" element={<Navigate to="/orderService" replace />} />
    </Routes>
  );
}
