import { Alert as AlertMui, AlertTitle } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import React from "react";

interface AlertProps {
  title: string;
  subtitle: string;
  visibility: boolean;
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  severity: "error" | "success" | "warning" | "info";
  icon: React.ReactNode;
  style?: string;
}
export default function Alert({
  title,
  subtitle,
  visibility,
  setVisibility,
  severity,
  icon,
  style = "",
}: AlertProps) {
  const getAlertColor = () => {
    switch (severity) {
      case "error":
        return "bg-red-200";
      case "success":
        return "bg-green-200";
      case "warning":
        return "bg-yellow-200";
      case "info":
        return "bg-blue-200";
    }
  };

  const alertVisibility = visibility ? "" : "hidden";

  return (
    <AlertMui
      severity={severity}
      className={`max-w-sm fixed z-50 self-end rounded-lg text-body-regular-2 text-gray-700 mb-5 ${alertVisibility} ${getAlertColor()} ${style}`}
      icon={icon}
      sx={{ fontFamily: "Montserrat" }}
      action={
        <button className="h-0 mr-1" onClick={() => setVisibility(false)}>
          <CloseRoundedIcon className="text-body-regular-2 text-gray-600" />
        </button>
      }
    >
      <AlertTitle
        className="text-body-semibold-1 text-gray-700 mb-0"
        sx={{ fontFamily: "Montserrat" }}
      >
        {title}
      </AlertTitle>
      {subtitle}
    </AlertMui>
  );
}
