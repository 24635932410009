import i18n from "@/i18n";
import React from "react";

function ContentExportError() {
  return (
    <span className="text-heading-regular-6 sm:text-heading-regular-6 text-gray-700">
      {i18n.t("ERROR_EXPORT_ORDER_SERVICES")}
    </span>
  );
}

export default ContentExportError;
