import React, { useState } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./react_dates_overrides.css";
import { DateRangePicker as RangePicker, FocusedInputShape } from "react-dates";
import i18n from "@/i18n";
import { ReactComponent as DividerIcon } from "@/assets/icons/dividerIcon.svg";
import { ReactComponent as NextIcon } from "@/assets/icons/nextIcon.svg";
import { ReactComponent as PrevIcon } from "@/assets/icons/prevIcon.svg";
import DataEntry from "@/components/DataEntry";
import moment from "moment";
import "moment/locale/pt-br";

interface DateRangePickerProps {
  onClear: () => void;
  handleSubmit: (
    startDate: moment.Moment | null,
    endDate: moment.Moment | null
  ) => Promise<void>;
  numberOfMonths?: number;
  customContainerStyle?: string;
}

interface Dates {
  startDate: moment.Moment | any;
  endDate: moment.Moment | any;
}

const datesInitialValues: Dates = {
  startDate: null,
  endDate: null,
};

const DateRangePicker = ({
  numberOfMonths = 2,
  handleSubmit,
  onClear,
  customContainerStyle = "",
}: DateRangePickerProps) => {
  const [dates, setDates] = useState<Dates>(datesInitialValues);
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    null
  );
  const [isButtonsEnabled, setButtonsEnabled] = useState<boolean>(false);
  const enablePastDates = () => false;

  moment.updateLocale("pt-br", {
    weekdaysMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
  });

  const handleApply = () => {
    setFocusedInput(null);
    handleSubmit(dates.startDate, dates.endDate).catch(() => {});
  };

  const handleClear = () => {
    setDates(datesInitialValues);
    setFocusedInput(null);
    setButtonsEnabled(false);
    onClear();
  };

  const onDatesChange = (dates: any) => {
    setDates(dates);
    const startDate = moment(dates.startDate);
    const endDate = moment(dates.endDate);

    const areDatesValid = startDate.isValid() && endDate.isValid();
    setButtonsEnabled(areDatesValid);
  };

  return (
    <div className={customContainerStyle}>
      <div className="flex flex-row justify-between pr-7 pl-3 pb-1">
        <p className="text-gray-700 text-tooltip-2">{i18n.t("START_DATE")}</p>
        <p className="text-gray-700 text-tooltip-2">{i18n.t("END_DATE")}</p>
      </div>
      <RangePicker
        customArrowIcon={<DividerIcon />}
        navNext={
          <div className="absolute top-7 right-7">
            <div className="h-5 w-5 block">
              <NextIcon
                className={`fill-black opacity-40 text-body-regular-2`}
              />
            </div>
          </div>
        }
        navPrev={
          <div className="absolute top-7 left-7">
            <div className="h-5 w-5 block">
              <PrevIcon
                className={`fill-black opacity-40 text-body-regular-2`}
              />
            </div>
          </div>
        }
        hideKeyboardShortcutsPanel
        numberOfMonths={numberOfMonths}
        minimumNights={0}
        keepOpenOnDateSelect
        firstDayOfWeek={1}
        displayFormat="DD/MM/YYYY"
        isOutsideRange={enablePastDates}
        startDatePlaceholderText={i18n.t("DATE_FORMAT_PLACEHOLDER")}
        endDatePlaceholderText={i18n.t("DATE_FORMAT_PLACEHOLDER")}
        startDate={dates.startDate}
        startDateId="start-date"
        endDate={dates.endDate}
        endDateId="end-date"
        onDatesChange={onDatesChange}
        focusedInput={focusedInput}
        onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
        renderCalendarInfo={() => (
          <div className="flex gap-6 justify-end rounded-[30px] bg-transparent mr-4 pb-4">
            <DataEntry.Button
              label={i18n.t("CLEAR")}
              customStyles={{
                color: "#A429FF",
                fontWeight: 600,
                "&:disabled": {
                  color: "#999999",
                },
              }}
              onClick={handleClear}
              disabled={!isButtonsEnabled}
            />
            <DataEntry.Button
              type="contained"
              label={i18n.t("APPLY")}
              customStyles={{
                color: "#FFFFFF",
                width: 124,
                fontWeight: 600,
                "&:disabled": {
                  color: "#FFFFFF",
                  backgroundColor: "#B8B8B8",
                },
              }}
              onClick={handleApply}
              disabled={!isButtonsEnabled}
            />
          </div>
        )}
      />
    </div>
  );
};

export default DateRangePicker;
